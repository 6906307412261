export const theme = {
    colors: {
        primary: {
            light: '#E1F1FF',
            hover: '#458bcd',
            main: '#337AB7',
            dark: '#337AB7',
        },
        greys: {
            white: '#ffffff',
            light: '#E5E5E5',
            muted: '#6c757d',
            medium: '#212529',
            dark: '#1f1f1f',
            black: '#000000',
        },
        success: {
            main: '#65CCB4',
            light: '#d0f6eb',
        },
        error: {
            main: '#ca3232',
            light: '#ffe0e0',
            dark: '#84291f',
        },
        warning: {
            main: '#FF5700',
            light: '#f18a3e',
        }, 
    },

    sizes: {
        large: '20px',
        medium: '14px',
        main: '14px',
        small: '12px',
    },
    
};
