import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import {Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import styles from '../inputFields/styles.js';
import CustomIconButton from '../inputFields/CustomIconButton';
import "animate.css/animate.css";
import './styles/TagDecimalFields.css';

const TagDecimalFielsEditable = (props) => {
  const minNum = props.minNum;
  const maxNum = props.maxNum;
  const [numValue, setNumValue] = useState(props.value || 0);
  const [tipText, setTipText] = useState(props.prompt);
  const [warningMsg, setWarningMsg] = useState('');  
 
  useEffect(() => {
      //console.log("**********TagDecimalFielsEditable**********" + props.value);      

      if (minNum !== undefined || maxNum !== undefined) {
        if (minNum !== undefined && maxNum !== undefined) {
          setTipText( props.prompt?`${props.prompt} Number must be greater then ${minNum}, and less then ${maxNum}.`:
          `Number must be greater then ${minNum}, and less then ${maxNum}.`);
          setNumValue(Math.round(props.value) < Math.round(minNum)? minNum: Math.round(props.value) > Math.round(maxNum)? maxNum: props.value);
        } else if (minNum !== undefined) {
          setTipText( props.prompt?`${props.prompt} Number must be greater then ${minNum}.`:`Number must be greater then ${minNum}.`);
          setNumValue(Math.round(props.value) < Math.round(minNum)? minNum: props.value);
        } else if (maxNum !== undefined) {
          setTipText(props.prompt? `${props.prompt} Number must be less then ${maxNum}.` :`Number must be less then ${maxNum}.`)
          setNumValue(Math.round(props.value) > Math.round(maxNum)? maxNum: props.value);
        }
      }
      else{
        setNumValue(props.value);
      }
    },[props.value]);  
    
  useEffect(() => {
      //console.log("**********TagDecimalFielsEditable**********" + numValue);
      try{
        let num = parseFloat(numValue);
        if(num>maxNum) setWarningMsg(`Number must be less then ${maxNum}`);
        else if(num<minNum) setWarningMsg(`Number must be greater then ${minNum}`);
        else setWarningMsg('');
      }
      catch(ex){};
  },[numValue]);

  useEffect(() => {
    if (props.required) {
      if (numValue === "" || numValue === 0 || numValue === null || numValue === undefined) {
        props.onAlert.addAlert(props.label);
      } else {
        props.onAlert.removeAlert(props.label);
      }
    } 
  }, [numValue, props.required]);

  
  const handleChange = (event) => {
    //console.log("TagDecimal onChange-" + event.target.value);
    try{
      let num = parseFloat(event.target.value).toFixed(2);  
      if (Math.round(num) > Math.round(maxNum)) 
        setNumValue(maxNum.toString());
      else
        setNumValue(event.target.value);
    }
    catch(ex){
      console.log("TagDecimal onChange exception", ex.toString());
      setNumValue(event.target.value);
    }
    
     
  };

  const handleBlur = (event) => {    
    let num = parseFloat(event.target.value===''?'0':event.target.value).toFixed(2);
    try{
      //setNumValue(num.toString());
      if (Math.round(num) >= Math.round(minNum) && Math.round(num) <= Math.round(maxNum)) {     
        setNumValue(num.toString());
        props.onChange({
          name: props.param,
          value: num,
        });
      }   
      else if(Math.round(num) < Math.round(minNum)){
        setNumValue(minNum.toString());
        props.onChange({
          name: props.param,
         value: minNum,
        });
      } 
      else if(Math.round(num) > Math.round(maxNum)){
        setNumValue(maxNum.toString());
        props.onChange({
          name: props.param,
          value: maxNum,
        });
      } 
    }
    catch(ex){
      console.log("TagDecimalFielsEditable exception", ex.toString());
      setNumValue(num.toString());
        props.onChange({
          name: props.param,
          value: num,
        });
    }
  };

  const clearNum = () => {
    setNumValue(0);
    props.onChange({
      name: props.param,
      value: 0,
    });
  };


  return (
    <Grid container className="tag-decimal-field-editable-container">
      <Grid item xs={11} sm={11} className="text-field-container">
        <Tooltip title={tipText} placement="top" arrow>
          <TextField
            className="decimal-text-field"
            label={props.label}
            size="small"
            type="number"
            value={numValue}
            onChange={handleChange}
            onBlur={handleBlur}
            variant={props.variant || "outlined"}
            style={styles.formControlTextfield}
            inputProps={{
              className: "decimal-text-field-input"
            }}
          />
        </Tooltip>
        <Typography className="error-message" color="error"> {warningMsg} </Typography>
      </Grid>
      <Grid item xs={1} sm={1} className="icon-button-container">
        <CustomIconButton
          icon={props.icon || "ClearIcon"}
          tooltip={props.iconTooltip || "Clear"}
          onClick={clearNum}
          color={props.color}
          className="custom-icon-button"
        />
      </Grid>
    </Grid>
  );
};

export default TagDecimalFielsEditable;
