import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
//import InvoiceDatagrid from "./invoiceDatagrid";
import InvoicesListDatagrid from "./invoicesListDatagrid";
import Copyright from "../Copyright";
import httpRequest from "../HttpService";
import { palette } from "../palette";
import Button from "@material-ui/core/Button";
import PaymentIcon from "@material-ui/icons/Payment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CustomDialog from "../newOrder/components/dialog/Dialog";
import { Snackbar, Alert } from "@mui/material";
import { useUserContext} from "../lib/user_context";

export default function Invoice(props)  {
    const { sessionAccount} = useUserContext();

   // const IsEmbbed = localStorage.getItem('IsEmbedded')!==null && localStorage.getItem('IsEmbedded')==='yes';
   // const account = localStorage.getItem('current_accountNumber')!== '' ? localStorage.getItem('current_accountNumber'):localStorage.getItem('accountNumber'); 
    const account = sessionAccount; 
    //const showPaymentPart = localStorage.getItem("accountNumber") === "9910" && IsEmbbed !== "yes";
    const showPaymentPart = false;


    const [selectedInvoices,setSelectedInvoices] = useState({ invoiceList: [], paymentAmount: 0,});
    const [totalOutstanding,setTotalOutstanding] = useState({ invoiceList: [], paymentAmount: 0,}); 
    const [openDialog, setOpenDialog] = useState(false);
    const [openCustomDialog, setOpenCustomDialog] = useState(false);
    const [customDialogTitle, setCustomDialogTitle] = useState('');
    const [customDialogMsg, setCustomDialogMsg] = useState('');
    const [openSnack, setOpenSnack] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [paymentData, setPaymentData] = useState({});
    const [loading, setLoading] = useState(false);

    /*
    useEffect(() => {   
      const loadScript = (callback) => {
          const tidioScript = document.createElement("script");
          tidioScript.src = "//code.tidio.co/zj4irmzxslnsmyu84t0zthq5r1m9jgxl.js";
          tidioScript.id = "tidioscript";
          window.document.body.appendChild(tidioScript);
          //document.getElementById("tidio-chat-iframe").style.visibility = 'visible';
          callback();
      };

      loadScript(() => {
          const tidioChatIframe = document.getElementById("tidio-chat-iframe");
          if (tidioChatIframe) {
             tidioChatIframe.style.display = 'inline';  // Setting the display style as needed
             console.log('Tidio chat iframe is now visible');
          } else {
              console.log('Tidio chat iframe not found at the time of script load.');
          }
      });

      return () => {
          const removeScript = (callback) => {     
              console.log("Script removing");
              const scriptElement = document.getElementById("tidio-chat");
              if (scriptElement && scriptElement.parentNode) {
                  //scriptElement.parentNode.removeChild(scriptElement);
                  document.getElementById("tidio-chat-iframe").style.display='none';
                  console.log('Script removed',document);  
              }
          };
          removeScript(() => {
              console.log("Script removed");
          });
      };
  }, []);
   */
 
    //check if there is transaction data passed:
    const search = new URLSearchParams(props.location?.search);
    if (search.has("ssl_invoice_number")) {
      if (search.get("ssl_result") == 0) {
        console.log("payment was successfully processed!");
        
        const invoiceList = JSON.parse("[" + search.get("ssl_invoice_number") + "]").map(String);
        const paymentAmount = parseFloat(search.get("ssl_amount"));
        const confirmationNumber = search.get("ssl_txn_id");
        const accountNumber = search.get("ssl_description");
        window.location = window.location.href.split("?")[0];
        httpRequest.payInvoices(accountNumber,invoiceList, paymentAmount,confirmationNumber).then((response) => {
            console.log(response);
            window.location.reload(false);
          })
          .catch( (error) => {
            setAlertMsg("Transaction was failed: " + error);
            setOpenSnack(true);            
          });          
      } else {
        //alert("Transaction was cancelled");
        setAlertMsg("Transaction was cancelled");
        setOpenSnack(true); 
        window.location = window.location.href.split("?")[0];
        window.location.reload(false);
      }
    }
  

  /**
   * Set selected invoices
   * @param {*} ivList
   * @param {*} amount
   */
  const setSelectedInvoicesFunc = (ivList, amount) => {
    let temp = {
      invoiceList: ivList,
      paymentAmount: amount,
    };
    setSelectedInvoices(temp);   
  };

  /**
   * Set invoice list and total outstanding
   * @param {*} ivList
   * @param {*} amount
   */
    const setTotalInvoices = (ivList, amount) => {
        let temp = {
            invoiceList: ivList,
            paymentAmount: amount,
        };
        setTotalOutstanding(temp);
        if (showPaymentPart)
            document.getElementById("totalbalance").innerHTML = `Total outstanding balance: ${amount}`;
    };

  /**
   * Checkout selected invoices
   * @param {*} event
   * @returns
   */
    const checkoutSelected = (event) => {
        console.log("Checking out");
        if (selectedInvoices.paymentAmount === 0) {
            //alert("Please select at least one invoice to pay.");
            setOpenSnack(true);
            setAlertMsg("Please select at least one invoice to pay.");     
            return;
        }
        event.preventDefault();
        setPaymentData({
            paymentAmount: selectedInvoices.paymentAmount,
            invoiceList: selectedInvoices.invoiceList,
        }); 
        processPayment({
           paymentAmount: selectedInvoices.paymentAmount,
           invoiceList: selectedInvoices.invoiceList,
        });
    };

   /**
   * Check out total outstanding
   * @param {} event
   */
    const checkoutTotal = (event) => {
        console.log("Checking out all");
        event.preventDefault();
        setPaymentData({
            paymentAmount: totalOutstanding.paymentAmount,
            invoiceList: totalOutstanding.invoiceList,
        }); 
        processPayment({
            paymentAmount: totalOutstanding.paymentAmount,
            invoiceList: totalOutstanding.invoiceList,
         });
    };

    const processPayment = (stripeInvoice) => {
       stripeInvoice.paymentAmount = Math.round(stripeInvoice.paymentAmount * 100) / 100;
       const msg = `
        Your total to process is $ ${stripeInvoice.paymentAmount.toFixed(2)}.        
        Invoice(s) selected - ${stripeInvoice.invoiceList}
        Click OK to proceed to checkout.
        `;
        setOpenCustomDialog(true);
        setCustomDialogMsg(msg);
        setCustomDialogTitle("Payment");   

       // 'Would you like to proceed to checkout: Amount is ' + stripeInvoice.paymentAmount + ' for invoices: '+ stripeInvoice.invoiceList;
        /*
        if (window.confirm(msg) === false) {
            return;
        }

        httpRequest.getTransactionToken(account, stripeInvoice.paymentAmount,  stripeInvoice.invoiceList.toString()).then(function (response) {
            console.log("response returned!");
            console.log(response.data);
            if (response.status !== 200) {
                alert("Please try again!");
            } else {
                const data = response.data;
                console.log(data);
                window.open(
                    "https://api.demo.convergepay.com/hosted-payments?ssl_txn_auth_token=" +
                     data.token,
                    "_self"
                );
            }
        })
        .catch(function (error) {
            console.error("Error:", error);
            alert(error);
        });
      */
    };

    const submitPayment = () => {
        setOpenCustomDialog(false);   
        let paymentAmount = paymentData.paymentAmount;
        let invoiceList = paymentData.invoiceList;
        paymentAmount = Math.round(paymentAmount * 100) / 100;
        httpRequest.getTransactionToken(account, paymentAmount, invoiceList.toString()).then(function (response) {
            console.log("response returned!");
            console.log(response.data);
            if (response.status !== 200) {
                // alert("Please try again!");
                setOpenSnack(true);
                setAlertMsg("Please try again!");          
            } else {
                const data = response.data;
                console.log(data);
                window.open(
                    "https://api.demo.convergepay.com/hosted-payments?ssl_txn_auth_token=" +
                    data.token,
                    "_self"
                );
                // window.open(
                //   "https://api.demo.convergepay.com/hosted-payments?ssl_txn_auth_token=" +
                //     data.token,
                //   "_blank"
                // );
                 // window.location.reload();
            }
        })
       .catch(function (error) {
            console.error("Error:", error);
            setOpenSnack(true);
            setAlertMsg("Transaction was failed: " + error);         
        });
    };

    return (
      <Container maxWidth="lg" >
        {showPaymentPart && (
          <div style={{position: "relative",height: 90, backgroundColor: "white",}} >
            <Card
              style={{
                width: "fit-content",
                minWidth: 400,
                margin: "auto",
                marginTop: 10,
                paddingBottom: 10,
              }}
            >
              <CardContent>
                {/* <Typography variant='heading1' id='totalbalance'>Total outstanding balance: {(this.state.totalOutstanding?.paymentAmount)?.toFixed(2)} &emsp; </Typography> */}
                <Typography variant="heading1" id="totalbalance">
                  Total outstanding balance:{" "}
                  {totalOutstanding?.paymentAmount} &emsp;{" "}
                </Typography>
                <Button size="small" variant="contained" color="primary"
                  startIcon={
                    <PaymentIcon
                      style={{
                        verticalAlign: "middle",
                        transform: "scale(1.5)",
                        color: palette.greys.white,
                      }}
                    />
                  }
                  disableRipple
                  onClick={checkoutTotal}
                  style={{ marginRight: "10", marginLeft: "5", float: "right" }}
                  disabled={totalOutstanding?.paymentAmount === 0}
                >
                  Pay Total
                </Button>
              </CardContent>
            </Card>
            <Button size="small"  variant="contained"  color="primary"
              startIcon={
                <PaymentIcon
                  style={{
                    verticalAlign: "middle",
                    transform: "scale(1.5)",
                    color: palette.greys.white,
                  }}
                />
              }
              disableRipple
              onClick={checkoutSelected}
              style={{ position: "absolute", right: 5, top: 30 }}
            >
              Pay Selected
            </Button>
          </div>
        )}
        {/* <Backdrop style={{color: '#fff', zIndex:9}} open={this.state.openDialog}>
                    <InvoiceDialog
                        paymentAmount={this.state.stripeInvoice?.paymentAmount}
                        invoiceList={this.state.stripeInvoice?.invoiceList}
                        onClose={this.setState({openDialog: false})}
                        dialogAction={this.createStripePayment}
                    />
                </Backdrop> */}
        <Backdrop style={{ color: "#fff", zIndex: 10 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* <InvoiceList account={this.state.account} selectedInvoices={this.state.selectedInvoices} totalOutstanding={this.state.totalOutstanding}/>    */}
        <div style={{ height: 1200, width: "100%" }}>
          {/* <InvoiceDatagrid
            account={account}
            selectedInvoices={selectedInvoices}
            totalOutstanding={totalOutstanding}
            setSelectedInvoices={setSelectedInvoicesFunc}
            setTotalInvoices={setTotalInvoices}
          /> */}
            <InvoicesListDatagrid
              account={account}
              selectedInvoices={selectedInvoices}
              totalOutstanding={totalOutstanding}
              setSelectedInvoices={setSelectedInvoicesFunc}
              setTotalInvoices={setTotalInvoices}
            />
        </div>

        <CustomDialog
            open={openCustomDialog}
            onClose={() => { setOpenCustomDialog(false) }}
            dialogTitle={customDialogTitle}
            dialogMessage={customDialogMsg}
            dialogAction={submitPayment}
        />

        <Snackbar
          open={openSnack}
          autoHideDuration={1800}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={() => {setOpenSnack(false)}}
        >
          <Alert
            onClose={() => {setOpenSnack(false)}}
            severity="info"
            sx={{ width: "100%" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>

        {props.isEmbedded!==null && props.isEmbedded!=="yes"  && (<footer> <Copyright /> </footer>)}
       
    </Container>
    ); 
}


