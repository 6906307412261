import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import PackageOptions from './PackageOptions';
import styles from './styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';


const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: 0,
        border: 0,
        direction: "horizontal",
        flexWrap: 'wrap',
    }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles({
    root: {
        padding: 0,
        textTransform: "none",
        display: "block",
        width: "180px",
        height: "180px",
        "& img": {
            border: '2px solid #e5e5e5',
            borderRadius: '7px',
            // height: '150px',
        },
        "&:hover": {
            "& img": {
                color: "rgb(26, 88, 159)",
                border: "2px solid #337AB7"
            }
        },
    },
    selected: {
        "& img": {
            color: "rgb(26, 88, 159)",
            border: "2px solid #337AB7",
        }
    }
})(ToggleButton);

const theme = createTheme({
    palette: {
        action: {
            active: "#EEEEEE"
        },
        text: {
            primary: "#EEEEEE"
        }
    }
});
function AllPackageOptions(props) {
    const [packageSize, setSize] = React.useState("");
    const [packageOptionList, setPackageOptionList] = useState(props.packageOptionList); 

    const handleSize = (event, newSize) => {
        // console.log("handleSize-" + newSize);
        setSize(newSize);
        props.onChange({
            target: {
                name: "packageSize",
                value: newSize,
            }
        });

        const selectedOption = packageOptionList.find(e => e.iconTitleText === newSize) || {};

        props.onChange({
            target:{
               name: "packageDesc",
               value: selectedOption.iconTitleText
            }
        });

        props.onChange({
            target:{
              name: "packageImage",
              value: selectedOption.icon_url
            }
          });
    };

    return (
        <div>
            {/* <Typography variant='h6' color="primary">Package</Typography> */}
            <div style={{ paddingLeft: '45px' }}>
            <ThemeProvider theme={theme}>
                <StyledToggleButtonGroup
                    size="small"
                    value={props.packageSize}
                    exclusive
                    onChange={handleSize}
                    style={styles.root}
                >
                    {/* <Grid container columns={16} direction="row" justifyContent="center"> */}
                        {packageOptionList.map((option) => (
                            // <Grid item sm={4} md={2}>
                                <StyledToggleButton value={option.iconTitleText} disableRipple style={{marginBottom:'40px'}}>
                                        <PackageOptions packageType={option.iconTitleText} image={option.icon_url} alt={option.iconTitleText}/>
                                </StyledToggleButton>
                            // </Grid>
                        ))}
                    {/* </Grid> */}
                </StyledToggleButtonGroup>
            </ThemeProvider>
            </div>
            <div style={{ paddingBottom: '20px' }} />
        </div>
    );
}

export default AllPackageOptions;
