import React, { useState, useEffect } from "react";
import {Box} from "@mui/material";
import Grid from '@material-ui/core/Grid';
import CallerInput from "./PackageOptionsandDetails/packageOptions/CallerInput"
import "animate.css/animate.css";
import httpRequest from "../../HttpService";
import SelectInput from './inputFields/SelectInput_org';

const DropdownBox = (props) => {
  const [accountId, setAccountId] = useState(props.value);
  const [nameValue, setNameValue] = useState(null);
  const [newName, setNewName] = useState(null);
  const [nameList, setNameList] = useState([]);
  const [newUser, setNewUser] = useState(false);

  useEffect(() => {
    props.onAlert({"target":"accountId", "value":true});
    props.onAlert({"target":"caller", "value":true});
    props.onAlert({"target":"Initial", "value":false});
  }, [props.value]);

  useEffect(() => {
    if (localStorage.getItem('caller')) {
      const savedName = localStorage.getItem('caller');
      setNameValue(savedName);
      props.onChange({"target":"caller", "value":savedName});
      props.onAlert({"target":"caller", "value":false});
    } else {
      setNameValue("select");
      props.onChange({"target":"caller", "value":undefined});
      props.onAlert({"target":"caller", "value":true});
    }
  }, []); // Empty dependency array for initialization

  useEffect(() => {
    httpRequest.getUserEmails(accountId).then((body) => {
      console.log("=== getUserEmails response ===");
      console.log("Raw response:", body.data);
      let names = body.data.map((person, i) => {
        return({value: person.username, label: person.username, id:i});
      });
      console.log("Processed nameList:", names);
      setNameList(names);
      if(localStorage.getItem('caller'))
        setNameValue(localStorage.getItem('caller'));
    });
  }, [accountId]);

  useEffect(() => {
    if (nameValue === "select" || nameValue === null || nameValue === undefined) {
      props.onChange({"target":"caller", "value":undefined});
      props.onAlert({"target":"caller", "value":true});
    }
  }, [nameValue]);

  useEffect(() => {
    //if (nameList.length > 0) {
    //  setNameValue(nameList[0].label);
    //}
  }, [nameList]);

  useEffect(() => { 
    console.log("Account/Name Effect - Start");
    console.log("Account:", accountId);
    console.log("Name:", nameValue);   
    if (accountId && accountId!=="select" && nameValue !== undefined && nameValue !== null && nameValue !== "select") {
      console.log("useEffect", nameValue);
      props.onChange({"target":"accountId", "value":accountId});
      props.onChange({"target":"caller", "value":nameValue});
      props.onAlert({"target":"accountId", "value":false});
      if (nameValue && nameValue !== "select" && nameValue != "Please select an account first" && nameValue != "") {
        props.onAlert({"target":"caller", "value":false});  
        httpRequest.postQuoteInfo(accountId, nameValue, props.companyID).then((body) => {
          console.log("getQuoteInfo", JSON.stringify(body.data));
          props.onChange({"target":"quoteId", "value":body.data[0].quoteID});
          localStorage.setItem("caller", nameValue);
        });

      } else {
        props.onAlert({"target":"caller", "value":true});
      }
    } else {
      props.onChange({"target":"accountId", "value":undefined});
      props.onChange({"target":"caller", "value":undefined});
      props.onAlert({"target":"accountId", "value":true});
      props.onAlert({"target":"caller", "value":true});
    }
  }, [accountId, nameValue]);


  useEffect(async () => { 
    console.log("DropdownBox nextQuoteID:", props.info[0]?.nextQuoteID);
    if(props.info[0]?.nextQuoteID !== undefined && props.info[0]?.nextQuoteID!== null){
      const res = await httpRequest.postQuoteInfo(accountId, nameValue, props.companyID, props.info[0]?.nextQuoteID);
      console.log("DropdownBox getQuoteInfo", JSON.stringify(res.data));      
    }
  }, [props.info[0]?.nextQuoteID]);

  useEffect(() => { 
    console.log("DropdownBox caller:", props.info[0]?.caller);
    if(props.info[0]?.caller === undefined){
       props.onAlert({"target":"caller", "value":true});
       props.onChange({"target":"caller", "value":undefined});
    }
    //if(props.info[0]?.caller== null)
    //   setNameValue("");
  }, [props.info[0]?.caller]);

  const handleAccountChange = (event) => {  
    setAccountId(event.target.value);
    props.onChange({"target":"accountId", "value":event.target.value});
    
    // Reset the UI state
    setNameValue("select");  
    setNameList([]);  // Clear the name list until new one loads
    props.onChange({"target":"caller", "value":undefined});
    props.onAlert({"target":"caller", "value":true});
    localStorage.removeItem('caller');
  
    // Reset any related state that might be keeping the old values
    if (props.info[0]) {
      props.onChange({"target":"quoteId", "value":undefined});
    }
  };

  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };


  const handleSelectName = (event) => {
  console.log("=== handleSelectName ===");
  console.log("Event value:", event.target.value);
  console.log("Current nameValue:", nameValue);
  console.log("Current nameList:", nameList);
  setNameValue(event.target.value);    
};

  const handleAddPerson = (event) => {
    setNewUser(!newUser);
    props.onChange({
      target: {
        name: "IsNewCaller",
        value: !newUser,
      },
    });
    //////////////ghy////////////////   
    if(!newUser){
      props.onChange({"target":"caller", "value":undefined});
      props.onAlert({"target":"caller", "value":true});
     
    }
    else{
      props.onChange({"target":"caller", "value":nameValue});
      props.onAlert({"target":"caller", "value":false});
    }
  };

  const handleSavePerson = (event) => {  
 
    if (newName !== "") {
      setNameValue(newName);    
      handleAddPerson(event);
      var newCaller = {
        username: newName,
        emailAddress: "",
      };
      httpRequest.postUserEmails(accountId, newCaller).then(async function (body) {
          httpRequest.getUserEmails(accountId).then(async function (body) {
            const nameList = [];
            await body.data.forEach((person) => {
              nameList.push({ value: person.username, label: person.username, id:nameList.length});
              if (person.username === nameValue) {
              }
            });
            setNameList(nameList);
          });
        }
      );
    }   
  };

  const handleRemovePerson = (event) => {
    console.log("handleRemovePerson-" + nameValue);
    httpRequest.removeCaller(accountId, nameValue).then(async function (body) {
      console.log("removeCaller-" + JSON.stringify(body.data));
      let names = nameList.filter(function (obj) {return obj.label !== nameValue;});
      setNameList(names);      
      setNameValue("");
    });
  };

  const  renderPickName = () => {
    return (
      <CallerInput
        label="Name"
        inputType="select"
        iconOne="AddPersonIcon"
        iconTwo="removePersonIcon"
        optionList={nameList}
        name="name"
        value={nameValue}
        icononetooltip="Add new"
        icontwotooltip="remove"
        onChange={handleSelectName}
        onIconOneClick={handleAddPerson}
        onIconTwoClick={handleRemovePerson}
        labelSize={3}
      />
    );
  }

  const renderAddPerson = () =>{
    return (
      <CallerInput
        type="name"
        iconOne="ListIcon"
        iconTwo="saveIcon"
        inputType="text"
        inputLength={20}
        label="Name"
        name="name"
        icononetooltip="Select from list"
        iconTwoTooltip="Save"
        onChange={handleNameChange}
        onIconOneClick={handleAddPerson}
        onIconTwoClick={handleSavePerson}
        labelSize={3}
      />
    );
  }

  console.log("=== Render state ===");
  console.log("nameValue:", nameValue);
  console.log("nameList:", nameList);

  return (  
    <Box sx={{position: 'absolute', zIndex:1, right: 80, display:"flex"}}>   
      <div style={{ marginTop: 8, marginRight: 10 }}>   
        <Grid container sx={12} direction="row" justify-Content="center" alignItems="center" spacing={2} wrap="nowrap">
          <Grid item container sx={6}>        
            <SelectInput
              optionList={props.choices}
              onChange={handleAccountChange}
              value={accountId}               
              label="ACCOUNT"
              labelSize="4"
              color={props.color}
              error={!accountId || accountId === "select" || accountId === "Please Select"}
          />
          </Grid>
          <Grid item container sx={7}>

            {!newUser && <CallerInput
                label="Name"
                inputType="select"
                iconOne="AddPersonIcon"
                iconTwo="removePersonIcon"
                optionList={nameList}
                name="name"
                value={nameValue === "select" ? "" : nameValue}  // Force empty value when "select"
                icononetooltip="Add new"
                icontwotooltip="remove"
                onChange={handleSelectName}
                onIconOneClick={handleAddPerson}
                onIconTwoClick={handleRemovePerson}
                labelSize={3}
                error={!nameValue || nameValue === "" || nameValue === "select" || nameValue === "Please Select"}
                required={true}
            />}
            {newUser && renderAddPerson()}  
          </Grid>
        </Grid> 
      </div> 
    </Box>
  );
};

export default DropdownBox;
