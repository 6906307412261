import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { pad2 } from "./helperFunctions/index.js";
import "../NewOrder.css";

const CountdownTimer = ({ countTimerStatus, currentSection }) => {
  const [duration, setDuration] = useState(-1);
  const [durationText, setDurationText] = useState("");
  const [intervalId, setIntervalId] = useState(-1);

  // Reset timer when navigating away from section 4
  useEffect(() => {
    if (currentSection !== 4) {
      cleanTimer();
    } else if (countTimerStatus === 1) {
      // Only start timer if we're in section 4 and status is 1
      startTimer();
    }
  }, [currentSection]);

  useEffect(() => {
    if (duration === 90) {
      setDuration(-1);
      clearInterval(intervalId);
      setDurationText("");
    } else if (duration !== -1) {
      setDurationText(
        pad2((90 - duration) / 60) + ":" + pad2((90 - duration) % 60)
      );
    }
  }, [duration]);

  useEffect(() => {
    // Only handle timer status changes if we're in section 4
    if (currentSection === 4) {
      if (countTimerStatus === 1) {
        startTimer();
      }
      if (countTimerStatus === 0 || countTimerStatus === 2) {
        cleanTimer();
      }
    }
  }, [countTimerStatus]);

  const cleanTimer = () => {
    clearInterval(intervalId);
    setIntervalId(0);
    setDuration(-1);
    setDurationText("");
  };

  const startTimer = () => {
    setDuration(0);
    if (intervalId !== -1) {
      clearInterval(intervalId);
      setIntervalId(-1);
    }
    const newIntervalId = setInterval(() => {
      setDuration(duration => duration + 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  // Only render if we're in section 4
  if (currentSection !== 4) {
    return null;
  }

  return (
    <div id="indicator_duration">
      {duration !== -1 && (
        <CircularProgressbar
          value={duration}
          text={durationText}
          minValue="0"
          maxValue="92"
          strokeWidth={6}
          styles={buildStyles({
            rotation: 0.25,
            textSize: "28px",
            pathTransitionDuration: 0.5,
            textColor: "red",
          })}
        />
      )}
    </div>
  );
};

export default CountdownTimer;