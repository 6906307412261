import axios from "axios";

//const base_url = "http://192.168.2.201:6655/";  //prod
//const base_url = "http://192.168.2.201:6622/"; //test

// const base_url = "http://192.168.2.16:6688/";  //prod

// const base_url = "https://lattice-webx.azurewebsites.net/"; //dev
//const base_url = window.location.origin+'/'; //staging

console.log(`process.env.NODE_ENV: ${process.env.NODE_ENV}, process.env.REACT_APP_API_URL: ${process.env.REACT_APP_API_URL}`);
// const base_url = process.env.NODE_ENV === "production" ? `${window.location.origin}/api/` : process.env.REACT_APP_API_URL;
let base_url = `${window.location.origin}/`;
if (typeof process !== 'undefined' && process.env && process.env.NODE_ENV === "development") {
	base_url = process.env.REACT_APP_API_URL;
}

const requireAuth = axios.create();

const getOption = () =>{
  return {
		headers: {
			'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '-1',
      'token': localStorage.getItem("sso_token")
		}
  }	
}

class HttpRequest {

  getAPIVersion = () => {
    return requireAuth.get(base_url+ "version", getOption());
  };

  getTopAddress = (accountNumber, search) => {
    return requireAuth.post(`${base_url}topAddress/${accountNumber}`, search, getOption());
  };

  getTopAddressByType = (accountNumber, search, type) => {
    return requireAuth.post(
      `${base_url}topAddress/${accountNumber}/${type}`,search, getOption()
    );
  };

  getExpandedAddress = (accountNumber, search, skip) => {
    return requireAuth.post(
      `${base_url}expandAddress/${accountNumber}/${skip}`, search, getOption()
    );
  };

  addAddress = (accountNumber, address) => {
    return requireAuth.post(
      `${base_url}addAddress/${accountNumber}/${address.addressID}`,
      {},
      getOption()
    );
  };

  postAddress = (accountNumber, address) => {
    var content = {
      address: address,
      accountNumber: accountNumber,
    };
    return requireAuth.post(`${base_url}address`, content, getOption());
  };

  addContact = (accountNumber, address) => {
    var content = {
      accountNumber: accountNumber,
      address: address,
    };
    return requireAuth.post(`${base_url}contact`, content, getOption());
  };

  removeAddress = (accountNumber, address) => {
    return requireAuth.delete(`${base_url}removeAddress/${accountNumber}/${address.addressID}`, getOption());
  };

  updateAddressContact = (accountNumber, addressID, contactID) => {
    var content = {
      addressID: addressID,
      contactID: contactID,
    };
    return requireAuth.post(`${base_url}addressContact/${accountNumber}`, content, getOption());
  };

  getRateChoice = (accountNumber, postContent) => {
    return requireAuth.post(`${base_url}rateChoices/${accountNumber}`, postContent, getOption());
  };

  submitOrder = (order) => {
    return requireAuth.post(`${base_url}order`, order, getOption());
  };

  /** get Label String from DB
   * @param accountNumber, jobID
   */
  getLabelString = (id) => {
    return requireAuth.get(`${base_url}labelString/${id}`, getOption());
  };

  getZPLLabel = (accountNumber, id, currentPage, totalPages) => {
    return requireAuth.get(
      `${base_url}ZPLlabel/${accountNumber}/${id}/${currentPage}/${totalPages}`, getOption()
    );
  };

  getOrderTrack = (orderID) => {
    return requireAuth.get(`${base_url}track/${orderID}`, getOption());
  };

  /**
   * get accounts in group
   * @param groupID
   **/
  getGroupAccounts = (groupID) => {
    return requireAuth.get(`${base_url}groupAccounts/${groupID}`, getOption());
  };

  getAddressByID = (accountNumber, addressID) => {
    return requireAuth.get(`${base_url}address/${accountNumber}/${addressID}`, getOption());
  };

  /**
   * get Reference Fields
   * @param accountNumber
   **/
  getReferenceFields = (accountNumber) => {
    return requireAuth.get(`${base_url}referenceFields/${accountNumber}`, getOption());
  };

  getReferenceDropDown = (refID) => {
    return requireAuth.get(`${base_url}referenceDropDown/${refID}`, getOption());
  };

  /**
   * post_referenceFields,
   * @param jobId
   * @param reference
   */
  postReferenceFields = (jobID, reference) => {
    var content = {
      jobID: jobID,
      reference: reference,
    };
    return requireAuth.post(`${base_url}referenceFields`, content, getOption());
  };

  getUserEmails = (accountNumber) => {
    return requireAuth.get(`${base_url}userEmails/${accountNumber}`, getOption());
  };

  /**
   * get account service notes
   * @param accountNumber
   **/
  getAccountServiceNotes = (accountNumber) => {
    return requireAuth.get(`${base_url}accountServiceNotes/${accountNumber}`, getOption());
  };

  orderSearch = (accountNumber, searchParams) => {
    var content = {
      accountNumber: accountNumber,
      searchParams: searchParams,
    };
    return requireAuth.post(`${base_url}searchOrders`, content, getOption());
  };

  /**
   * get orders
   * @param accountNumber
   **/
  getOrders = (accountNumber) => {
    return requireAuth.get(`${base_url}orders/${accountNumber}`, getOption());
  };

  /**
   * get order
   * @param accountNumber
   * @param orderID
   **/
  getOrder = (accountNumber, orderID) => {
    return requireAuth.get(`${base_url}order/${accountNumber}/${orderID}`, getOption());
  };

  /**
   * get Job GPS
   * @param orderID
   **/
  getJobGPS = (orderID) => {
    return requireAuth.get(`${base_url}jobGPS/${orderID}`, getOption());
  };

  getPackageChoice = (accountNumber, postContent) => {
    return requireAuth.post(
      `${base_url}packageChoices/${accountNumber}`,
      postContent,
      getOption()
    );
  };

  /**
   * get signature photo
   * @returns {Promise}
   * in order status page
   */
  getSignature = (accountNumber, jobID) => {
    return requireAuth.get(
      `${base_url}orderSignature/${accountNumber}/${jobID}`, getOption()
    );
  };

  /**
   * get list of pictures of the order
   * @returns {Promise}
   * in order status page
   */
  getPhotoURLs = (orderID) => {
    return requireAuth.get(`${base_url}photoURL/${orderID}`, getOption());
  };

  /* update weight  in order status (order detail page)  */
  patchUpdateWeight = (accountNumber, jobID, newWeight) => {
    var content = {
      accountNumber: accountNumber,
      jobID: jobID,
      newWeight: newWeight,
    };
    return requireAuth.patch(
      `${base_url}orderUpdateWeight/${accountNumber}/${jobID}`,
      content,
      getOption()
    );
  };

  /* update phone number  in order status (order detail page)  */
  callMe = (accountNumber, jobID, phone) => {
    var content = {
      accountNumber: accountNumber,
      jobID: jobID,
      phone: phone,
    };
    return requireAuth.post(
      `${base_url}orderCall/${accountNumber}/${jobID}`,
      content,
      getOption()
    );
  };

  /* login page  - rquest for new sso-token*/
  generateLoginToken = (body) => {
    return requireAuth.patch(`${base_url}generateLoginToken`, body,  getOption());
  };

  /**
   * sso_token is from url  ex: https://ship.directx.ca/#?sso_token=6b36437b-522e-499a-9802-1fc74b4f2f30
   * get account and user detail by sso_token if it is available
   * @returns {Promise}
   */
  postLoginToken = (token) => {
    var content = {
      sso_token: token,
    };
    return requireAuth.post(`${base_url}loginToken`, content,  getOption());
  };

  /*old version called Login */
  /*called when selected different account form account list on Create Order page */
  getAccountDetail = (accountNumber) => {
    var data = {
      accountNumber: accountNumber,
    };
    return requireAuth.post(`${base_url}login`, data,  getOption());
  };

  getInvoices = (accountNumber) => {
    return requireAuth.get(`${base_url}invoices/${accountNumber}`,  getOption());
  };

  /**
   * check Invoice Password
   * @returns {*}
   * @constructor
   */
  checkInvoicePassword = (accountNumber, password) => {
    var data = {
      aaccountNumber: accountNumber,
      password: password,
    };
    return requireAuth.post(`${base_url}invoicePassword`, data,  getOption());
  };

  /**
   * post address with addressID and google return json string
   * @param addressID
   * @param addrSearchResult
   */

  postAddressEXT = (addressID, addrSearchResult) => {
    var content = {
      addrSearchResult: addrSearchResult,
      addressID: addressID,
    };
    return requireAuth.post(`${base_url}addressEXT`, content,  getOption());
  };

  /* add new caller ( name and email) 
    * caller = {
    *    username: ggggg,
    *    emailAddress:  qwe@123.com
    }
    */
  postUserEmails = (accountNumber, caller) => {
    var content = {
      caller: caller,
      accountNumber: accountNumber,
    };
    return requireAuth.post(`${base_url}userEmails`, content, getOption());
  };

  /* remove caller (name and email)
   *  caller=
   *    {
   *    username: ggggg
   *   }
   */
  /**
   *
   * @param {*} accountNumber
   * @param {*} callerName
   * @returns
   */
  removeCaller = (accountNumber, callerName) => {
    var content = {
      username: callerName,
    };
    return requireAuth.post(`${base_url}caller/${accountNumber}`, content, getOption());
  };

  /**
   * send webMessage
   * @param messageID
   * @returns {promise}
   */
  sendWebMessage = (accountNumber, message) => {
    var content = {
      msg: message,
      accountNumber: accountNumber,
    };
    return requireAuth.post(`${base_url}webMessage`, content, getOption() );
  };

  /**
   *
   * @param {Int} accountNumber
   * @param {String []} invoice_List
   * @param {Float} payment_Amount
   * @param {String} conf_Numer
   * @returns
   */
  payInvoices = (accountNumber, invoiceList, paymentAmount, confNumber) => {
    var content = {
      //     IMEI: null,
      invoiceList: invoiceList,
      paymentAmount: paymentAmount,
      confNumber: confNumber,
      //     json: null
    };
    return requireAuth.post(`${base_url}payInvoices/${accountNumber}`, content, getOption());
  };

  /**
   * doUploadImage
   * @param params
   **/
  doUploadImage = (base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return requireAuth.post(`${base_url}image`, content, getOption());
  };

  /**
   * doUploadPDF
   * @param params
   */
  doUploadFile = (base64String, blobFileName) => {
    var content = {
      base64: base64String,
      fileName: blobFileName,
    };
    return requireAuth.post(`${base_url}file`, content, getOption());
  };

  /**
   * add attachment
   * @param params
   */
  doAttachment = (jobID, URL, fileType, comment) => {
    var content = {
      URL: URL,
      fileType: fileType,
      comment: comment,
    };
    return requireAuth.post(`${base_url}attachment/${jobID}`, content, getOption());
  };

  /**
   * obtaines transaction token for converge
   * @param {Float} amount
   * @param {String} invoiceNumber
   * @param {Int} account
   * @returns
   */
  getTransactionToken = (account, amount, invoiceNumber) => {
    console.log("sending request to create checkout session " + amount);
    const saleData = {
      ssl_amount: amount,
      ssl_invoice_number: invoiceNumber,
      ssl_description: account,
    };
    return requireAuth.post(`${base_url}transactionToken`, saleData, getOption());
  };

  //////////////Lattice API ///////////////////////
  /**
   * get quote info
   * @param {Int} account
   * @param {String} caller
   * @param {Int} company
   */
  getQuoteInfo = (account, caller, company) => {
    //return requireAuth.get(base_url + "quote/" + account + "/" + caller);  
    return requireAuth.get(`${base_url}quote/${account}/${caller}`, getOption());  
  };

  /**
   * get quote descriptions
   * @param {Int} quoteID
   */
  getQuoteDescriptions = (quoteID) => {
    return requireAuth.get(`${base_url}quoteDescriptions/${quoteID}`, getOption());
  };

  /**
   * post quote descriptions
   * @param {Int} quoteID
   * @param {Int} descriptionID
   * @param {Int} pieces
   * @param {Int} weight
   * @param {String} additionalDescription
   * @param {String} piecesJSON
   */
  postQuoteDescriptions = (quoteID, descriptionID, pieces, weight, additionalDescription, piecesJSON) => {
    const descriptionInfo = {
      descriptionID,
      pieces,
      weight,
      additionalDescription,
      piecesJSON
    };
    //return requireAuth.post(base_url + "quoteDescriptions/" + quoteID, descriptionInfo);
    return requireAuth.post(`${base_url}quoteDescriptions/${quoteID}`, descriptionInfo, getOption());
  };

  /**
   * get quote tags
   * @param {Int} quoteID
   */
  getQuoteTags = (quoteID) => {
    return requireAuth.get(`${base_url}quoteTags/${quoteID}`, getOption());
  };

  /**
   * post quote tags
   * @param {Int} quoteTagID
   * @param {String} variableJSON
   */
  postQuoteTags = (quoteTagID, variableJSON) => {
    const quoteTagInfo = {
     quoteTagID,
     variableJSON
    };
    console.log("postQuoteTags tag", JSON.stringify(quoteTagInfo)); 
    return requireAuth.post(`${base_url}quoteTags/`, quoteTagInfo, getOption());
  };

  /**
   * get quote time options
   * @param {Int} quoteID
   */
  getQuoteTimeOptions = (quoteID) => {
    const utc = (new Date(new Date().toUTCString()).getTime())/1000;
    return requireAuth.get(`${base_url}quoteTimeOptions/${quoteID}/${utc}`, getOption())
      .then(response => {
        const modifiedData = response.data.map(item => {
          if (item.infoText) {
            const pairs = item.infoText.split('|');
            const formattedLines = pairs.map(pair => {
              const match = pair.match(/(.*?):?\s*([\d.]+)/);
              if (match) {
                const [, title, value] = match;
                return `${title.trim()}: ${value}`;
              }
              return pair.trim();
            });
            item.infoText = formattedLines.join('\n').trim(); // Added trim() here
          }
          return item;
        });
        return { ...response, data: modifiedData };
      });
  };

  /**
   * post quote addresses
   * @param {Int} quoteID
   * @param {Int} shipAddressID
   * @param {Int} shipContactID
   * @param {Int} conAddressID
   * @param {Int} conContactID
   */
  postQuoteAddresses = (quoteID, shipAddressID, shipContactID, conAddressID, conContactID) => {
    const addressesInfo = {
      quoteID,
      shipAddressID,
      shipContactID,
      conAddressID,
      conContactID,
    };
    return requireAuth.post(`${base_url}quoteAddresses`, addressesInfo, getOption());
  };

  /**
   * postQuoteCustomTime
   * @param quoteID {int}
   * @param customDTPickupStartUTC {datetime}
   * @param customDTDeliveryEndUTC {datetime}
   */
  postQuoteCustomTime = (quoteID, customDTPickupStartUTC, customDTDeliveryEndUTC) => {
    const utc = (new Date(new Date().toUTCString()).getTime())/1000;
    const info = {
      quoteID,
      customDTPickupStartUTC,
      customDTDeliveryEndUTC
    };
    return requireAuth.post(`${base_url}quoteCustomTime/${utc}`, info, getOption());
  };

  /**
   * post quote execute
   * @param {Int} quoteTimeOptionID
   * @param {0/1} next
   */
  postQuoteExecute = (quoteTimeOptionID, companyID, next) => {
    const quoteExecuteInfo = {
      quoteTimeOptionID,
      //companyID,
      next,
    };
    return requireAuth.post(`${base_url}quoteExecute`, quoteExecuteInfo, getOption());
  };


  /**
   * post quoteInfo  (post methord)
   * @param {Int} account
   * @param {String} caller
   * @param {Int} companyID
   * @param {Int} quoteID
   */
  postQuoteInfo = (account, caller, companyID, quoteID) => {
    const content ={
      account,
      caller,
      companyID,
      quoteID
    }
    return requireAuth.post(`${base_url}quote`,content, getOption());  
  };


  getOrderDetailBilling = (jobID) => {
    return requireAuth.get(`${base_url}orderDetailBilling/${jobID}`, getOption());
  };

}

const httpRequest = new HttpRequest();

export default httpRequest;
