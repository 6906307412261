import React, { useState, useEffect, useRef } from 'react';

const CustomTooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      
      setPosition({
        top: triggerRect.top - tooltipRect.height - 10,
        left: triggerRect.left + (triggerRect.width / 2) - (tooltipRect.width / 2)
      });
    }
  }, [isVisible]);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div
        ref={triggerRef}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div
          ref={tooltipRef}
          style={{
            position: 'fixed',
            top: `${position.bottom}px`,
            left: `${position.left}px`,
            backgroundColor: 'rgba(245, 245, 247, 0.98)',
            color: 'black',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            zIndex: 1000,
            maxWidth:'400px',
            whiteSpace: 'pre-line',
            fontSize:'1rem'
          }}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;