import React, {useEffect, useState, useRef, useCallback} from 'react'
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Container, Grid, Typography, Button, IconButton, Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import PageBanner from "./components/pageBanner";
import Panel from "./components/whitePanel";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import httpRequest from "../HttpService";
import { getStatusString, getLocationString,} from "../newOrder/components/helperFunctions/index.js";
import MapboxPanel from "./components/MapboxPanel/index";
import logo from "../directitgroupcourier.jpg";
import moment from "moment";
import Carousel from "./components/carouselPanel";
import PrintIcon from "@material-ui/icons/Print";
import MailIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/PhoneAndroid";
import CopyIcon from "@material-ui/icons/FileCopy";
import SelectInput from "../newOrder/components/inputFields/SelectInput_org";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {Dialog, DialogTitle, DialogContent, TextField, DialogActions} from "@material-ui/core";
import { ToastContainer, toast } from "react-toast";
import copy from "copy-to-clipboard";
import Copyright from "../Copyright";
import { JSPrintManager, WSStatus, ClientPrintJob,InstalledPrinter,ClientScanJob, ScannerImageFormatOutput } from 'jsprintmanager';
import ReactToPrint from "react-to-print";
import PrintPanel from "../newOrder/components/printPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Gallery from "../newOrder/components/gallery";
import "./orderDetail.css";
import OrderReceiptPanel from './components/OrderReceiptPanel.js';
import BillsList from './components/orderDetailBillingList.js'
import ImageViewer from './components/imageViewer.js';

export default function OrderDetail(props) {
    const {orderID} = useParams();
    const {account} = useParams(); 
    const componentRef = useRef();
    const receiptPrintRef = useRef();

    const [order, setOrder] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
    const [photoArray, setPhotoArray] = useState([]);
    const [steps, setSteps] = useState([]);
    const [bills, setBills] = useState([]);
    const [openMessageBox, setOpenMessageBox] = useState(false);
    const [openCallMeBox, setOpenCallMeBox] = useState(false);
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState({phoneNumber:'', ext:''});
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [printsList, setPrintsList] = useState([]);
    const [isPrint, setIsPrint] = useState(false);
    const [intervalId, setIntervalId] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [isUploadFile, setIsUploadFile] = useState(false);
    const [isPrintReceipt, setIsPrintReceipt] = useState(false);

    const getOrder = useCallback(async () => {
      try {
        getPhotos();    
        httpRequest.getOrder(account, orderID).then((response) => {
            console.log("useEffect getOrder--" + JSON.stringify(response.data));
            var _order = response.data;
            _order.dtDelivered_display = _order.dtDelivered ? _order.dtDelivered.substring(0, 19).replace("T", " ") : '';
            _order.dtCallIn_display = _order.dtCallIn ? _order.dtCallIn.substring(0, 19).replace("T", " ") : '';
            _order.dtDCT_display = _order.dtDCT ? _order.dtDCT.substring(0, 19).replace("T", " ") : '';
            setOrder(_order);

            httpRequest.getOrderDetailBilling(orderID).then((response) => {
              console.log("getOrderDetailBilling--" + JSON.stringify(response.data));
              var bList = response.data.map((option,index) => {
                return{
                  ...option,
                  id: index
                };
              });
              setBills(bList);          
            });
            
            getOrderTrack();  
            if (_order.Status < 8) {
                const newIntervalId = setInterval(() => {
                  getPhotos();
                  httpRequest.getOrder(account, orderID).then((response) => {
                      var _order = response.data;
                      _order.dtDelivered_display = _order.dtDelivered ? _order.dtDelivered.substring(0, 19).replace("T", " ") : '';
                      _order.dtCallIn_display = _order.dtCallIn ? _order.dtCallIn.substring(0, 19).replace("T", " ") : '';
                      _order.dtDCT_display = _order.dtDCT ? _order.dtDCT.substring(0, 19).replace("T", " ") : '';
                      setOrder(_order);
                      getOrderTrack();
                      if (_order.Status >= 8) clearInterval(intervalId);
                  });
              }, 60000);  
              return () => {
                  clearInterval(newIntervalId);
              };          
            }         
        })  
      } catch (error) {
        console.log(error);
      }

      try{
        JSPrintManager.auto_reconnect = false;
        JSPrintManager.start();  
        var printListStr = localStorage.getItem('PrintList');
        if (printListStr && printListStr !== "") {  
          let printers = JSON.parse(printListStr); 
          setPrintsList(printers);         
          var defaultPrinter = printers.find(function (obj) {
            return obj.label.includes("ZPL");
          });
          if (defaultPrinter === undefined)
            setSelectedPrinter(printers[0].label)         
          else {
            var index = printers.findIndex(x => x.label ===defaultPrinter.label);   
            setSelectedPrinter(printers[index].label)            
          }
          console.log( "order detail defaultPrinter :" + selectedPrinter );
        }  
        JSPrintManager.WS.onStatusChanged = () => {
          if (JSPrintManager.websocket_status === WSStatus.Open) {
          }
        };
      }
      catch (e) {
        console.log("JSPrintManager--- exception", e);
      }    
    }, []);
  
    useEffect(() => {
      getOrder();
    }, [orderID, account]);

    const allyProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          "aria-controls": `simple-tabpanel-${index}`,
        };
    };
    
    const getOrderTrack = () => {
        httpRequest.getOrderTrack(orderID).then((response) => {
          var tempTrackList = response.data.map((option,index) => {
              return {
                ...option,
                id: index
              };
          });
          setSteps(tempTrackList);         
        });
    };

    // const getPhotos = () => {
    //     httpRequest.getPhotoURLs(orderID).then((response) => {
    //       var imageList = [];
    //       response.data.forEach((value) => {
    //         value.savedTimeUTC = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
    //         value.id = imageList.length;
    //         value.imageType = value.url.endsWith("png") || value.url.endsWith("jpg") || value.url.endsWith("bmp") ? "image" : "file";
    //         value.fileType = value.url.endsWith("png") || value.url.endsWith("jpg") || value.url.endsWith("bmp") ? "image" : value.url.substring(value.url.length - 3);
    //         value.fileExt = value.url.substring(value.url.length - 3);
    //         if (value.imageType === "image") imageList.push(value);
    //       });
    //       setPhotoArray(imageList);
    //       setIsUploadFile(false);         
    //     });
    // };

    const getPhotos = () => {
      httpRequest.getPhotoURLs(orderID).then((response) => {
        //console.log("getPhotoURLs--" + JSON.stringify(response.data));
        let imageList =  response.data.map((value,i) => {
          value.savedTimeUTC = moment(value.savedTimeUTC).format("YY-MM-DD HH:mm");
          value.id = i;
          value.imageType =   value.url.endsWith("png") || value.url.endsWith("jpg") || value.url.endsWith("bmp")? "image" : "file";
          value.fileType =  value.url.endsWith("png") ||  value.url.endsWith("jpg") || value.url.endsWith("bmp") ? "image" : value.url.substring(value.url.length - 3);
          value.fileExt = value.url.substring(value.url.length - 3);
          if (value.fileExt === "pdf" || value.fileExt === "doc" ||value.fileExt === "docx" ||value.fileExt === "xls" ||value.fileExt === "xlsx" ||value.fileExt === "csv"){
            var file_url = `https://drive.google.com/viewerng/viewer?embedded=true&url=${value.url}#toolbar=0&scrollbar=0`;
            value.file_url = file_url;
          }    
          return value;
        });
        setPhotoArray(imageList);
        setIsUploadFile(false);      
      });
    };
   
     
    const renderStepContentText = (label) => {
        return (
          <div>
            <Typography>{label.bigText}</Typography>
            <Typography variant="caption">
              {label.smallText + moment(label.timestamp_utc).format("MM-DD") + " @ " + moment(label.timestamp_utc).format("LT")}          
            </Typography>
            {label.img_url && (
              <span>
                <a onClick={() => { window.open(label.img_http_link, "_blank"); }}>{label.img_text}</a>
                <div>
                  <img
                    style={{ width: "80px", height: "60px" }}
                    src={label.img_url}
                    onClick={() => {window.open(label.img_http_link, "_blank");}}
                    alt="Step content"
                  />
                </div>
              </span>
            )}
          </div>
        );
    };
    
    const renderFromAddress = () => {
        let suite = order.sSuite ? order.sSuite + ", " : '';
        return (
          `${suite} ${order.sAddress}  ${order.sQuad}, ${order.sCity}, ${order.sProv}, ${order.sPostal}, ${order.sCountry}`
        );
    };
    
    const renderToAddress = () => {
        let suite = order.cSuite ? order.cSuite + ", " : '';
        return (
          `${suite} ${order.cAddress}  ${order.cQuad}, ${order.cCity}, ${order.cProv}, ${order.cPostal}, ${order.cCountry}`
        );
    };

    const handleTabChange = (_event, newValue) => {
        setTabValue(newValue);
        if (newValue === 0 && isUploadFile) {
          getPhotos();
        }
    };

    const handlePrintChange = (event) => {
        try {
          const { value } = event.target;
          setSelectedPrinter(printsList[parseInt(value)].label);
          console.log("selectedPrinter---", selectedPrinter);
        }
        catch(err) {
           console.log("handlePrintChange exception:" + err);
        }
    };
    
    const zplPrint = () => {
        if (JSPrintManager.websocket_status === WSStatus.Open) {
          httpRequest.getLabelString(order.jobID).then(async (body) => {
            //console.log("getZPLLabel return-" + JSON.stringify(body.data));
            var zplLabel = body.data;
            var cpj = new ClientPrintJob();
            cpj.clientPrinter = new InstalledPrinter(selectedPrinter);
            cpj.printerCommands = zplLabel.ZPLString;
            cpj.sendToClient();
          });
        }
        else {
          toast("Failed to print label, please check print connection!");
        }
    };    

    const messageSubmit = () => {
        var msg = {
          userName: " ",
          userEmail: " ",
          messageText: message,
          jobID: orderID
        };
        httpRequest.sendWebMessage(account, msg).then((response) => {
            setOpenMessageBox(false);        
            toast("You will see requested changes to a trip here within the next 5 minutes");
        });
    };
    
    const callMeSubmit = () => {
        if (order.PROOF_DEL) { 
            setOpenCallMeBox(false);   
            return; 
        }   
        var phoneNum = phone.ext === '' ? phone.phoneNumber : phone.phoneNumber + "-" + phone.ext;
        toast("submit phone number:" + phoneNum);
        setOpenCallMeBox(false);      
        httpRequest.callMe(account, orderID, phoneNum).then((response) => {    
            toast("We will call you in the next 5 minutes (M-F 7:30am - 4:30pm)");
        })
        .catch((err) => {
            toast("Failed to add Job Phone Number" + err);
        });
    };   
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {setIsPrint(false)},
    });

    const doPrintReceipt = useReactToPrint({
        content: () => receiptPrintRef.current,
        onAfterPrint: () => {setIsPrintReceipt(false)},
    });

    const renderDeliveryDetails = () => {
      return (
        <div>
          <Grid container spacing={2} style={{ marginBottom: '25px' }}>
            <Grid item md={6} style={{ height: 80 }}>
              <h3><strong>LOCATION</strong></h3>
              <Typography style={{ fontWeight: 420 }}> {getLocationString(order.location)} </Typography>
            </Grid>
            <Grid item md={6}>
              <h3><strong>BILLING REFERENCE</strong></h3>
              <Typography style={{ fontWeight: 420 }}> {order.CUST_REF} </Typography>
            </Grid>
            <Grid item md={6} style={{ height: 80 }}>
              <h3><strong>CALLED IN BY</strong></h3>
              <Typography style={{ fontWeight: 420 }}>{order.CALLER} </Typography>
            </Grid>
            <Grid item md={6}>
              <h3><strong>CALL IN</strong></h3>
              <Typography style={{ fontWeight: 420 }}>
                {order.dtCallIn_display + " " + order.timezone}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <h3><strong>STATUS</strong></h3>
              <Typography style={{ fontWeight: 420 }}>{getStatusString(order.Status)}</Typography>
            </Grid>
            <Grid item md={6} style={{ height: 80 }}>
              <h3><strong>SERVICE MINUTES</strong></h3>
              <Typography style={{ fontWeight: 420 }}> {order.SER_TIME}</Typography>
            </Grid>
            <Grid item md={6}>
              <h3><strong>RATE(BEFORE TAX)</strong></h3>
              <Typography style={{ fontWeight: 420 }}> ${order.RATE} </Typography>
            </Grid>
            <Grid item md={6}>
              <h3><strong>PROOF OF DELIVERY</strong></h3>
              <Typography style={{ fontWeight: 420 }}> {order.PROOF_DEL}</Typography>
            </Grid>
          </Grid>
    
          <div>
            <h3><strong>BILLS</strong></h3>
            <BillsList bills={bills} />
          </div>
        </div>
      );
    }

    return (
        <Container maxWidth="lg" class="NewOrder">
          <PageBanner title="PARCEL STATUS" subheading={"#" + orderID} />
          <div style={{ margin: "-15% 8% 5% 8%" }}>
            {!isPrint && ( 
              <Grid container>
                <Grid item xs={12}>
                  <Panel>
                    <Grid container>
                    <Grid item xs={12} md={4}>
                      <p>STATUS</p>
                      <h3>{getStatusString(order.Status)}</h3>
                      <br />
                      <p>JOB ID</p>
                      <h3>{orderID}</h3>
                      <br />
                      <p>TRACKING NUMBER</p>
                      {order.TrackNumber && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <h3 style={{ marginRight: '10px' }}>{order.TrackNumber}</h3>
                          <IconButton 
                            aria-label="copy" 
                            onClick={() => {
                              copy("https://tracking.directx.ca/trackNo/" + order.TrackNumber);
                            }}
                          >
                            <CopyIcon color="primary" />
                          </IconButton>
                        </div>
                      )}
                    </Grid>
                      <Grid item xs={12} md={8}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label.bigText} completed={label.filled}>
                              <StepLabel> {renderStepContentText(label)} </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Grid>
                    </Grid>
                  </Panel>
                </Grid>
                <Grid item xs={12}>
                  <Panel>
                    <Grid container>
                      <Grid container xs={12} xl={12}>
                        <Grid item xs={3} xl={2}>
                          <img src={logo} alt="Direct-IT Group Courier" style={{ width: "150px", marginTop: -10 }}/>
                          <Grid container direction="column" justifyContent="center" alignItems="left" spacing={0}>
                            <Grid item>
                                <Button variant="contained" color="primary" style={{float: "center", margin: "5px",width: "180px",}}                             
                                startIcon={<PrintIcon className="side_icon" />}
                                onClick={() => {console.log("selectPrinter :" + selectedPrinter); setIsPrint(true)}}>
                                RePrint Label
                              </Button>
                            </Grid>
                            <Grid item>
                              {props.variant === "orderStatus" && (
                                <Button variant="contained" color="primary" style={{float: "center", margin: "5px", width: "180px"}}
                                  startIcon={<MailIcon className="side_icon" />}
                                  onClick={()=>{setOpenMessageBox(true)}}
                                >
                                  Send Message
                                </Button>
                              )}
                            </Grid>
                            <Grid item>
                              {props.variant === "orderStatus" && (
                                <Button variant="contained" color="primary" style={{float: "center",margin: "5px", width: "180px"}}
                                  startIcon={<PhoneIcon className="side_icon" />} onClick={()=>{setOpenCallMeBox(true)}}
                                >
                                  Call Me
                                </Button>
                              )}
                            </Grid>
                            <Grid item>
                              {props.variant === "orderStatus" && (
                                <Button variant="contained" color="primary" style={{float: "center",margin: "5px", width: "180px"}}
                                  startIcon={<PrintIcon className="side_icon" />} onClick={()=>{setIsPrintReceipt(true)}}
                                >
                                  Print Receipt
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container xs={9} xl={10}>
                          <Grid item xs={12} xl={5} style={{paddingBottom:25}}>
                            <h3><strong>FROM</strong></h3>
                            <Typography style={{ fontWeight: 420 }}> {order.sCompanyName} </Typography>
                            <Typography>{renderFromAddress()}</Typography>
                            <br />
                            <h3><strong>TO</strong></h3>
                            <Typography style={{ fontWeight: 420 }}> {order.cCompanyName} </Typography>
                            <Typography>{renderToAddress()}</Typography>
                          </Grid>

                          <Grid container xs={12} xl={5} spacing={2}>
                            <Grid item xs={6} style={{ paddingBottom: 25 }}>
                              <h3><strong>Pieces</strong></h3>
                              <Typography style={{ fontWeight: 420 }}>{order.PIECES}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 25 }}>
                              <h3><strong>Weight</strong></h3>
                              <Typography style={{ fontWeight: 420 }}>{order.WEIGHT}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 25 }}>
                              <h3><strong>Service Type</strong></h3>
                              <Typography style={{ fontWeight: 420 }}>{order.SER_AREA}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 25 }}>
                              <h3><strong>Instructions</strong></h3>
                              <Typography>{order.DEL_INST}</Typography>
                            </Grid>
                            <Grid item md={6} style={{ height: 80 }}>
                              <h3><strong>{order.Status >= 8 ? "Delivered At" : "Due By"}</strong></h3>
                              <Typography style={{ fontWeight: 420 }}>
                                {order.Status >= 8 ? order.dtDelivered_display : order.dtDCT_display} {order.timezone}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingBottom: 25 }}>
                              {order.PROOF_DEL? <h3><strong>Signature "{order.PROOF_DEL}"</strong></h3>
                              :<h3><strong>Signature</strong></h3>}
                              {order.signatureURL && (
                                <img src={order.signatureURL} alt="Customer Signature" style={{ height: 100 }} />
                              )}
                            </Grid>
                          </Grid>

                          <Grid item xs={0} xl={2} />
                        </Grid>
                      </Grid> 
                      <Grid item xs={0} xl={2}></Grid>
                      <Grid item xs={12} xl={8} style={{marginTop:'1em'}}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="Additional Details"
                            id="Additional Details"
                          >
                            <Typography>Additional Details</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {renderDeliveryDetails()}
                          </AccordionDetails>
                        </Accordion>
                        </Grid>
                        <Grid item xs={0} xl={2}></Grid>
                    </Grid>
                  </Panel>
                </Grid>
                <Grid item md={7} xs={12}>
                  <Panel>
                    <p>LIVE TRACKING</p>
                    <Grid container>
                      {order.jobID && (
                        <MapboxPanel orderID={order.jobID} order={order} photoList={photoArray}/>
                      )}
                    </Grid>
                  </Panel>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Panel>                 
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
                      <Tab label="Attached Picture" {...allyProps(0)} />
                      <Tab label="Add Files" {...allyProps(1)} />
                    </Tabs>
                    {tabValue === 0 && (
                      <Carousel show={photoArray.length !== 0 ? true : false} numOfImages={photoArray.length}>
                        {photoArray.map((image, i) => (
                          <div key={i} style={{justifyContent: "center", height: "50%", verticalAlign: "middle", display: "flex"}}>
                          {image.imageType === "image" ?
                          // <img                      
                          //   src={image.url}
                          //   alt={`delivery-${i}`}
                          //   style={{width: "380px", margin: "auto", display: "block",justifySelf: "center",height: "380px"}}
                          // />
                          <ImageViewer src={image.url}/>
                          :
                          <iframe src={image.file_url} height="420px"  width="80%"></iframe>}
                        </div>
                        ))}
                      </Carousel>
                    )}
                    {tabValue === 1 && (
                      <div><Gallery jobId={orderID} setUploadFileFlag={()=>{setIsUploadFile(true)}} /></div>
                    )}
                  </Panel>
                </Grid>
              </Grid>
            )}

            {isPrint && (
              <Panel>
                {printsList && printsList.length>0 &&<SelectInput optionList={printsList} value={selectedPrinter?.value} name="selectPrinter" onChange={handlePrintChange}
                    label="Printer"                     
                /> }
                <Button variant="contained" color="primary" onClick={() => {setIsPrint(false)}} style={{ float: "right", margin: "2px" }}>
                  Cancel
                </Button>
                { selectedPrinter && selectedPrinter.includes("ZPL") ?
                <Button variant="contained" color="primary" onClick={zplPrint} style={{ float: "left", margin: "2px" }}>
                   Print
                </Button>
                :
                <Button variant="contained" color="primary" onClick={handlePrint}> Print</Button> 
                }           
                <PrintPanel id={orderID} account={order.ACCOUNT} ref={componentRef}/>                 
              </Panel>
            )}
            {isPrintReceipt &&<Panel>
              <Button variant="contained" color="primary" onClick={() => {setIsPrintReceipt(false)}} style={{ float: "right", margin: "2px" }}>
                  Back
              </Button>
              <OrderReceiptPanel orderID={order.jobID} order={order} ref={receiptPrintRef} doPrintReceipt={doPrintReceipt}></OrderReceiptPanel>
            </Panel>}
         
          </div>

          <Dialog open={openMessageBox} onClose={()=>{setOpenMessageBox(false)}}>
            <DialogTitle>Send Message about the Trip</DialogTitle>
            <DialogContent>
              <TextField autoFocus margin="dense" name="message" label="Message" type="text" fullWidth variant="standard"
                onChange={(event) => { setMessage(event.target.value)}}  
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{setOpenMessageBox(false)}}>Cancel</Button>
              <Button onClick={messageSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>
  
          <Dialog open={openCallMeBox} onClose={()=>{setOpenCallMeBox(false)}}>
            <DialogTitle>Call Request</DialogTitle>
            <DialogContent>
              <TextField autoFocus margin="dense" name="phone" label="Phone Number" type="number" fullWidth variant="standard"
                onChange={(event) =>{ setPhone((prevState)=>{return{...prevState, phoneNumber:event.target.value};})}} />
              <TextField margin="dense" name="ext" label="Ext" type="number" fullWidth variant="standard" 
                onChange={(event) =>{ setPhone((prevState)=>{return{...prevState, ext:event.target.value};})}}/>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{setOpenCallMeBox(false)}}>Cancel</Button>
              <Button onClick={callMeSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>  
          <ToastContainer />
          {props.isEmbedded!==null && props.isEmbedded!=="yes" && <footer>
            <Copyright />
          </footer>}
        </Container>
    );
}