import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import OrderInfo from "../orderInfo";
import MapContainer from "../mapContainer";
import "animate.css/animate.css";
import httpRequest from "../../../HttpService";

const Review = (props) => {  
  //const [info, setInfo] = useState(props.info);
  const [isUpdateMap, setIsUpdateMap] = useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [readyAtDateChecked, setReadyAtDateChecked] = useState(false);
 
  useEffect(() => {     
    props.onAlert({"target":"Initial", "value":false});    
  }, []);
  
  const createTrip = () => {
    localStorage.setItem("pickupLocation","");
    localStorage.setItem("deliveryLocation","");
    //httpRequest.postQuoteExecute(this.props.info[4].timeOption.quoteTimeOptionID, this.props.companyID, 0).then((res) => {
    httpRequest.postQuoteExecute(props.info[4].timeOption.quoteTimeOptionID, '1', 0).then((res) => {  
      console.log("postQuoteExecute_data", JSON.stringify(res.data))
      props.onChange({"target":"postQuoteExecute_data", "value":res.data[0]});
      props.onChange({"target":"jobID", "value":res.data[0].jobID});
      props.onChange({"target":"nextQuoteID", "value":null});
      props.updateCounterStatus(2); 
      props.onNextPanel();
    });
  };

  const createTripAndNew = () => {
    console.log("Review createTripAndNew pickUpLocation-" +  JSON.stringify(props.info[1].pickUpLocation));
    console.log("Review createTripAndNew deliveryLocation-" +  JSON.stringify(props.info[1].deliveryLocation))

    localStorage.setItem("pickupLocation",JSON.stringify(props.info[1].pickUpLocation));
    localStorage.setItem("deliveryLocation",JSON.stringify(props.info[1].deliveryLocation));

    httpRequest.postQuoteExecute(props.info[4].timeOption.quoteTimeOptionID, '1',1).then((res) => {
      console.log("postQuoteExecute_data", JSON.stringify(res.data));    
      props.onChange({"target":"jobID", "value":res.data[0].jobID});
      props.onChange({"target":"nextQuoteID", "value":res.data[0].nextQuoteID});
      props.onChange({"target":"postQuoteExecute_data", "value":res.data[0]});
      props.updateCounterStatus(2); 
      // props.newOrderPanel();
    });
    props.newOrderPanel();
  };

  return (
      <Grid 
        container 
        direction="row" 
        justifyContent="space-between" 
        alignItems="stretch" // Changed from flex-start to stretch
        spacing={3}
        style={{ display: 'flex', minHeight: '100%' }} // Added display: flex
      >
        <Grid 
          item 
          sm={10} 
          md={7} 
          style={{ display: 'flex', flexDirection: 'column' }} // Added flex styles
        >
          { (props.info[1].pickUpLocation && props.info[1].deliveryLocation && props.info[4].timeOption) ? (
            <OrderInfo
              name={props.info[0].caller}
             // callIn={props.info[4].timeOption?.dtCallIn}
             // dueBy={props.info[4].timeOption?.dtdct}
              callIn={props.info[4].timeOption?.dtPickupStart}
              dueBy={props.info[4].timeOption?.dtDeliveryEnd}
              fromName={props.info[1].pickUpLocation.companyName}
              fromAddress={props.info[1].pickUpLocation.address + (props.info[1].pickUpLocation.quad? ", " + props.info[1].pickUpLocation.quad : "")}
              toName={props.info[1].deliveryLocation.companyName}
              toAddress={ props.info[1].deliveryLocation.address +(props.info[1].deliveryLocation.quad? ", " +props.info[1].deliveryLocation.quad: "")}
              pieces={props.info[2].pieces}
              weight={props.info[2].weight}
              instructions={props.info[3].instructions}
              rate={{Rate:props.info[4].timeOption.RATE}}
              returnTripService={props.info[3].returnTripService}
              packageSize={props.info[2].packageSize}
              packageDesc={props.info[2].packageDesc}
              packageImage={props.info[2].packageImage}
              references={props.info[3]}
              signatureRequired={props.info[3].signatureRequired}
              pictureRequired={props.info[3].pictureRequired}
              prevPanel={props.onNextPanel}
              nextPanel={props.onNextPanel}
            />) : ""
          }
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>          
            <Grid item xs={12} md={12} container direction="row">
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={props.info[4].timeOption === null || isCreatingOrder}
                  style={{ float: "left", margin: "2px" }}
                  startIcon={
                    <DoneIcon
                      style={{
                        verticalAlign: "middle",
                        transform: "scale(1.5)",
                      }}
                    />
                  }
                  onClick={createTrip}
                >
                  Finished
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={props.info[4].timeOption === null ||isCreatingOrder}
                  style={{ float: "right", margin: "2px", display: "none" }}
                  startIcon={
                    <DoneIcon
                      style={{
                        verticalAlign: "middle",
                        transform: "scale(1.5)",
                      }}
                    />
                  }
                  onClick={createTripAndNew}                 
                >
                  Finished And Create a new Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid 
          item 
          sm={10} 
          md={5}
          style={{ 
            display: 'flex',
            flexDirection: 'column',
            flex: 1  // Added flex: 1
          }}
        >
        { (props.info[1].pickUpLocation && props.info[1].deliveryLocation && props.info[4].timeOption) ? (
            <div style = {{height:"420px"}}>
              <MapContainer
              top={0}
              IsUpdateMap={isUpdateMap}
              consignAddress={
                props.info[1].deliveryLocation.address +
                " " +
                (props.info[1].deliveryLocation.quad ? " " + props.info[1].deliveryLocation.quad + ", " : "") + props.info[1].deliveryLocation.city +
                " " +
                props.info[1].deliveryLocation.prov +
                ", " +
                props.info[1].deliveryLocation.postal
              }
              consignCoord={{lat: props.info[1].deliveryLocation.lat, long: props.info[1].deliveryLocation.long,}}
              shipperAddress={
                props.info[1].pickUpLocation.address +
                " " +
                (props.info[1].pickUpLocation.quad? " " + props.info[1].pickUpLocation.quad + ", " : "") + props.info[1].pickUpLocation.city +
                " " +
                props.info[1].pickUpLocation.prov +
                ", " +
                props.info[1].pickUpLocation.postal
              }
              shipperCoord={{lat: props.info[1].pickUpLocation.lat, long: props.info[1].pickUpLocation.long,}}
              type="directions"
            /> </div>) : ""          
          }
        </Grid>
      </Grid>
    );
};

export default Review;