import React, { useState } from 'react';
import '../../../NewOrder.css'
import Popover from '@mui/material/Popover';

function ImageHoverZoom (props){   
    const [anchorEl, setAnchorEl] = React.useState(null); 

    const handlePopoverOpen = (event) => {    
      setAnchorEl(event.currentTarget);
      console.log("enter img");
    };
  
    const handlePopoverClose = () => {
      console.log("leave img");
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    return (
    //   <div className="img-wrapper" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
      <div>    
        <img src={props.src} alt={props.alt} className="hover-zoom" />
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            >
            <img src={props.src}/>         
        </Popover>
      </div>
    );
  };

  export default ImageHoverZoom;