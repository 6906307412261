import React, { useState,useEffect } from 'react';
import './PanelItem.css';
import { Grid } from '@material-ui/core';
import ContactForm from './ContactForm';
import CustomIconButton from '../../inputFields/CustomIconButton';
import AddressForm from '../addressForm';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import CustomDialog from '../../dialog/Dialog';
import httpRequest from '../../../../HttpService';

const PanelItem = props => {

    const [openDialog, setOpenDialog] = useState(false);

    // useEffect(() => {
    //     console.log('PanelItem-' + JSON.stringify(props.data));
        
    // }, []);

    const toggleDialog = () => {
        console.log('toggle');
        setOpenDialog(!openDialog);
    };

    const deleteLocation = () => {
        console.log(props.accountNumber + '=====addressID_og', props.data.addressID)
        setOpenDialog(!openDialog); //close dialog
        httpRequest.removeAddress(props.accountNumber, props.data).then(function (response) {
            props.removedAddressIndex(props.index);
            console.log("removeAddress" + JSON.stringify(response.data));
        });        
    };
    
    const [showEditAddressForm, setShowEditAddressForm] = useState(false);

    const handleClick = (event) => {
        console.log(event); 
        if(props.selected)
            setShowEditAddressForm(!showEditAddressForm);         
          
    };

    const handleUpdateAddress = (newAddress) => {     
        props.onChange(newAddress, props.type);
    }

    //Format Last Delivery date
    let dateFormatted = "";
    if (props.data.LastDate) {
        const date = new Date(props.data.LastDate.split("T")[0]);
        const dateString = date.toDateString();
        dateFormatted = dateString.split(" ")[1] + " " + dateString.split(" ")[2] + ", " + dateString.split(" ")[3];
    }

    return (
        <Grid container className={props.selected ? "panel-item-box" : "panel-item-box-unselected"} spacing={1} key='panel'> 
            <Grid item lg={9} sm={8} xs={12}>
                <div className="panel-item-title">
                    <h3>{props.data.companyName}</h3>
                </div>
                <div className="panel-item-info">
                    {(props.data.suite !== '') ? 
                        (<p>Suite {props.data.suite},  {props.data.address}, {props.data.quad}</p>)
                        : (<p>{props.data.address}, {props.data.quad}</p>)
                    }
                    <p>{props.data.city}, {props.data.prov}, {props.data.country}, {props.data.postal} &nbsp;
                        <Tooltip title={props.data.addressID} placement="top" arrow>
                            <LocationOnIcon style={{fontSize: "14px"}}/>
                        </Tooltip> 
                    </p>
                    <p>
                        <strong>Last delivery completed:&nbsp;</strong> 
                        {dateFormatted} &nbsp;
                        <Tooltip title={`${props.data.lat}, ${props.data.long}`} placement="top" arrow>
                            <GpsFixedIcon style={{fontSize: "14px", display: "inline"}}/>
                        </Tooltip>
                    </p>
                </div>
            </Grid>
            <Grid item lg={3} sm={4}>
            {props.selected && <div style={{float: 'right'}}>
                    <CustomIconButton icon="VisibilityOffIcon" tooltip="Hide Address" label="Hide" onClick={toggleDialog} variant="outlined" color="primary" selected={props.selected} style={{marginRight: '2px'}}/>
                    <CustomIconButton icon="CreateIcon" tooltip="Edit Address" label="Create" onClick={handleClick} variant="outlined" color="primary" selected={props.selected}/>
                    <CustomDialog
                    open={openDialog}
                    onClose={toggleDialog}
                    dialogTitle = 'Warning'
                    dialogMessage = 'Address will be permanently removed from the list!'
                    dialogAction={deleteLocation}
                    />
                </div>}
                
            </Grid>
            {props.selected && !showEditAddressForm &&<ContactForm data={props.data} onChangeContact={props.onChangeContact} type={props.type}/>}
                     
            {(showEditAddressForm && props.selected) && ( 
               <div className='fixedElement'> 
                <AddressForm 
                    title="Edit Address" 
                    cancelButtonLabel="Cancel" 
                    saveButtonLabel="Save Address" 
                    variant="edit"
                    cancelButtonOnClick={() =>{handleClick();}}
                    updatedAddress = {handleUpdateAddress}   
                    data={props.data}
                    accountNumber={props.accountNumber}  
                /> 
                </div>
            )} 
           
            
        </Grid>
    );
};

export default PanelItem;


//https://stackoverflow.com/questions/1216114/how-can-i-make-a-div-stick-to-the-top-of-the-screen-once-its-been-scrolled-to