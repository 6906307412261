import { palette } from "../../../../palette";

const styles = {
  root: {
    display: 'flex',
  },
  cardHeader: {
    display: 'flex',
    width: '100%',
    overflowWrap: 'normal',
    fontWeight: 'bold',
  },
  cardTitleIcon: {
    display: 'inline',
    width: '10%',
    marginRight: '5px',
    height: '44px',
    width: '44px',
  },
  cardTitleRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  dateTimePicker: {
    marginRight: '30px',
  },
  description: {
    overflowWrap: 'normal',
  },
  divider: {
    minWidth: '150px',
  },
  dividerWeekend: {
    minWidth: '150px',
    marginTop: -20,
  },
  inlineIcon: {
    height: 30,
    width: 30,
    marginLeft: 5,
    marginBottom: -4,
  },
  priceText: {
    textAlign: 'right',
    color: 'black',
    paddingTop: '5px',
    fontWeight: 'bold',
  },
  quickSelectRow: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  radio: {
    display: 'inline',
  },
  warningBannerBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f8d7d9',
    marginBottom: 4,
    border: '1px solid #f5c2c7',
    borderRadius: 4,
  },
};

export default styles;