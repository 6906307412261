import React, { useState, useEffect,useRef,useCallback} from "react";
import Barcode from "react-barcode";
import Typography from '@mui/material/Typography';
import { Button, Grid, IconButton  } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import SelectInput from "../../components/inputFields/SelectInput_org";
import "animate.css/animate.css";
import httpRequest from "../../../HttpService";
import { useNavigate } from 'react-router-dom';
import { JSPrintManager, WSStatus, ClientPrintJob,InstalledPrinter } from 'jsprintmanager';
import PrintPanel from "../printPanel";
// import ReactToPrint from "react-to-print";
import CopyIcon from "@material-ui/icons/FileCopy";
import copy from "copy-to-clipboard";
import { useReactToPrint } from 'react-to-print';

const Finalview = (props) => {
  console.log("Finalview props:", props);
  const navigate = useNavigate();
  const [selectPrinter,setSelectPrinter] = useState(null);
  const [selectDefaultValue,setSelectDefaultValue] = useState(-1);
  const [printsList,setPrintsList] = useState([]);
  const [isPrint,setIsPrint] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);
  
  const accountNumber= localStorage.getItem("current_accountNumber") !== ""? localStorage.getItem("current_accountNumber"): localStorage.getItem("accountNumber"); 

  const componentRef = useRef(null);
  
  useEffect(() => {     
    props.onAlert({"target":"Initial", "value":false});  
    try{
      console.log("=========Final Review get  Printer list=============");
     //JSPrintManager.license_url = "https://webxapi.azurewebsites.net/jspm";
     JSPrintManager.auto_reconnect = true;
     JSPrintManager.start();
     JSPrintManager.WS.onStatusChanged = () => {
     if (JSPrintManager.websocket_status === WSStatus.Open) {
            JSPrintManager.getPrintersInfo().then(async (printersList) => {  
              let printers =  printersList.map((item,i) => {
              return {value: i, label: item.name};
            });
            setPrintsList(printers);
            localStorage.setItem("PrintList", JSON.stringify(printers));
            console.log("=============Final Review Printer list :" + JSON.stringify(printers));
            var defaultPrinter = printers.find(function (obj) {
              return obj.label.includes("ZDesigner");
            });
            console.log("Final Review Default Printer :" + JSON.stringify(defaultPrinter));
            if(defaultPrinter !== undefined){
              var index = printers.findIndex(x => x.label ===defaultPrinter.label); 
              setSelectPrinter(printers[index].label);
              setSelectDefaultValue(printers[index].value);
            }          
            console.log(">>>>>>>>>>>>>>>>>>>>>>>Final Review selectPrinter :" + selectPrinter);
          });
      }
    }
  }
  catch(e){console.log("get printer list is :" + e);}
    
  }, []);

  useEffect(() => {     
    props.onAlert({"target":"Initial", "value":false});  
    try{
      console.log("=========Final Review get  Printer list=============");
      JSPrintManager.auto_reconnect = true;
      JSPrintManager.start();
      JSPrintManager.WS.onStatusChanged = () => {
        if (JSPrintManager.websocket_status === WSStatus.Open) {
          JSPrintManager.getPrintersInfo().then(async (printersList) => {  
            let printers =  printersList.map((item,i) => {
              return {value: i, label: item.name};
            });
            setPrintsList(printers);
            localStorage.setItem("PrintList", JSON.stringify(printers));
            var defaultPrinter = printers.find(function (obj) {
              return obj.label.includes("ZDesigner");
            });
            if(defaultPrinter !== undefined){
              var index = printers.findIndex(x => x.label ===defaultPrinter.label); 
              setSelectPrinter(printers[index].label);
              setSelectDefaultValue(printers[index].value);
            }          
          });
        }
      }
    }
    catch(e){console.log("get printer list is :" + e);}
  }, []);

  useEffect(() => {
    if (props.info[5]?.postQuoteExecute_data?.jobID && props.info[0]?.accountId) {
      httpRequest.getOrder(props.info[0].accountId, props.info[5].postQuoteExecute_data.jobID)
        .then(response => {
          if (response.data?.TrackNumber) {
            setTrackingNumber(response.data.TrackNumber);
          }
        })
        .catch(err => console.error("Error getting order:", err));
    }
  }, [props.info[5]?.postQuoteExecute_data?.jobID]);

  const handlePrintChange = (event) => {
    console.log(event.target.name, event.target.value);
    setSelectPrinter(printsList[parseInt(event.target.value)]?.label)   
    setSelectDefaultValue(printsList[parseInt(event.target.value)]?.value)
    console.log("selectedPrinter", printsList[parseInt(event.target.value)]);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {setIsPrint(false)},
  });

  const handleNewTrip = () => {
    window.location.reload();
  };

  const printOrder = () => {
    console.log("selectPrinter :" + selectPrinter);
    if (selectPrinter.includes("ZPL")) {
        httpRequest.getLabelString(props.info[5].postQuoteExecute_data.jobID).then(async (body) => {
            console.log("getZPLLabel return-" + JSON.stringify(body.data));
            var zplLabel = body.data;
            var cpj = new ClientPrintJob();
            cpj.clientPrinter = new InstalledPrinter(
              selectPrinter
            );
            cpj.printerCommands = zplLabel.ZPLString;
            cpj.sendToClient();
          });     
    } else {
      setIsPrint(true);      
    }
  };


  return (
    <Grid
      container
      direction="cloumn"     
      spacing={1}
    >
      <Grid item container xs={12} justifyContent="center">
        { props.info[5].postQuoteExecute_data &&<Barcode value={parseInt(props.info[5].postQuoteExecute_data.jobID)} />}
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        {props.info[5].postQuoteExecute_data  && <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
          Job ID {props.info[5].postQuoteExecute_data.jobID} entered successfully!
        </Typography>}
      </Grid>    
      {trackingNumber && (
        <Grid item container xs={12} justifyContent="center" alignItems="center">
          <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
            Tracking No: {trackingNumber}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            startIcon={<CopyIcon />}
            onClick={() => copy("https://tracking.directx.ca/trackNo/" + trackingNumber)}
          >
            Copy Tracking URL
          </Button>
        </Grid>
      )}
      <Grid item container xs={12} justifyContent="center">          
          <Grid item container xs={12} justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
                {printsList && <SelectInput
                  optionList={printsList}                
                  value = {selectDefaultValue}
                  name="selectPrinter"
                  onChange={handlePrintChange}
                  label="Printer"
                />}
              </Grid>
              <Grid item>
                { selectPrinter  &&  selectPrinter?.includes("ZPL")?  <Button variant="contained" color="primary" style={{ float: "center", margin: "2px" }}
                  startIcon={<PrintIcon style={{verticalAlign: "middle",transform: "scale(1.5)",}}/>}
                  onClick={printOrder}
                 >
                 Print Order
                </Button>   
                :
                <Button variant="contained" color="primary" style={{ float: "center", margin: "2px" }}
                  endIcon={<PrintIcon style={{verticalAlign: "middle",transform: "scale(1.5)",}}/>}
                  onClick={handlePrint}> 
                     Print Order </Button>}          
              </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="center" style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewTrip}
              style={{ width: "200px" }}
            >
              Create New Trip
            </Button>
          </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="center"> 
         { selectPrinter!== undefined && selectPrinter!== null && !selectPrinter?.includes("ZPL") && props.info[5]?.postQuoteExecute_data?.jobID &&
          <PrintPanel
            id={props.info[5]?.postQuoteExecute_data?.jobID}
            account={props.accountNumber}
            ref={componentRef}
          />
         }
      </Grid>       
      </Grid> 
  );
};

export default Finalview;