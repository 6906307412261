import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "./lib/user_context";

import ReHelmet from "./Helmet";

ReactDOM.render(
    <>
        <ReHelmet title="Lattice Webx" />
        <UserProvider>
          <App />
        </UserProvider>
    </>,
    document.getElementById('root')
);

// Add Tidio chat
if (!document.querySelector('script[src="//code.tidio.co/c9qnm0vk1h183dspvaeg2nir0zq8ixfh.js"]')) {
  const script = document.createElement('script');
  script.src = "//code.tidio.co/c9qnm0vk1h183dspvaeg2nir0zq8ixfh.js";
  script.async = true;
  document.body.appendChild(script);
}

reportWebVitals();