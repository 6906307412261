import { palette } from "../../../palette";
import { theme } from '../../theme';

const styles = {
    orderContainer: {
        backgroundColor: palette.primary.light,
        padding: '10px',
        position: 'relative',
        margin: '0 0 5px 0',
        width:'100%',
    },
    orderDetailsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
    },
    orderDetailsTitle: {
        color: palette.primary.main,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    }
  };
  
  export default styles;