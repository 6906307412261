import React, { useRef } from 'react';
import "devextreme/dist/css/dx.light.css";
import DataGrid, {Column,Selection,FilterRow,Paging,Editing,ColumnChooser,Pager,Scrolling,allowedPageSizes,SearchPanel,GroupPanel, Grouping, SortByGroupSummaryInfo, Summary,StateStoring, Lookup,} from "devextreme-react/data-grid";
import {HeaderFilter,FilterPanel,FilterBuilderPopup,Export} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import { format, parseISO } from "date-fns";


import { useNavigate } from 'react-router-dom';

export default function OrdersDevExpressDataGrid(props){
    const navigate = useNavigate(); 
    const dataTable = useRef(null);
    const gridHeight = props.listType==="history"? "85vh" : "100vh";

    const renderJobID = (params) => {
        if(params.value){        
            return (      
                <a style={{ color: "#1234b0" }} href={`${window.location.origin}/orderDetail/${params.value}/${props.account}`}>
                    {params.value.substring(0,10)}
                </a>
            );
        }
    };     

    const onExporting = (e) => {
        console.log("onExporting-" + e.format);
        if (e.format === "xlsx") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Orders");
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                 workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "Orders.xlsx"
                    );
                });
            });
            e.cancel = true;
        } else if (e.format === "pdf") {
            const doc = new jsPDF();
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component,
            }).then(() => {
                doc.save("Orders.pdf");
            });
        } else if (e.format === "csv") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Orders");
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
            }).then(function () {
                workbook.csv.writeBuffer().then(function (buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "Orders.csv"
                    );
                });
            });
         }
    };

    const exportFormats = ["pdf", "xlsx", "csv"];

    const filterBuilderPopupPosition = {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
    };  

    const startViewDetail = (e) => {
        if (!e || !e.data || !e.data.jobID) {
            console.log("Invalid row data for view detail");
            return;
        }
        console.log("startViewDetail --" + JSON.stringify(e.data));
        console.log("Row data:", JSON.stringify(e.data, null, 2));
        navigate(`/orderDetail/${e.data.jobID}/${props.account}`);
    };


   return(

    <DataGrid
        className ={"gridContainer"}
        ref={dataTable}
        dataSource={props.orders}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        height = {gridHeight}
        keyExpr="id"  
        onCellDblClick ={startViewDetail} 
        onRowClick={(e) => {
            console.log("Row data:", JSON.stringify(e.data, null, 2));
        }}
        onExporting={onExporting} 
        onRowPrepared={(e) => {
            if(e.data && (e.data.Status === 'Verified' ||e.data.Status === 'Delivered' ||e.data.Status === 'Invoiced'))
                e.rowElement.style.backgroundColor = "#c6ecc6";            
            else if (e.data && e.rowIndex % 2 === 0)
                e.rowElement.style.backgroundColor = "#FFFFFF";
            else if (e.data && e.rowIndex % 2 !== 0)
                e.rowElement.style.backgroundColor = "#E5E7E9";    

    }}
  >
    
    <FilterPanel visible={true} />
    <FilterBuilderPopup position={filterBuilderPopupPosition} />
    <HeaderFilter visible={true} />
    <GroupPanel visible={true} />
    <Grouping autoExpandAll={false} />
    <SearchPanel visible={true} width={240} placeholder="Search..." />
    <StateStoring
        enabled={true}
        type="localStorage"
        storageKey="ordersGridState"
        savingTimeout={1000}
    />
    <Selection mode="single" />
    <FilterRow visible={true} />
    <Paging defaultPageSize={35} /> 
    <Pager
      visible={true}
    //allowedPageSizes={[20,30, 'all']}
      allowedPageSizes={[20,35,60,'all']}
      showPageSizeSelector={true}
      showInfo={true}
      infoText="Page #{0}. Total: {1} ({2} items)"
      displayMode="full"    />

    <Scrolling rowRenderingMode="virtual"></Scrolling>
    

    <Column  dataField="jobID" caption="OrderID"  width={100} cellRender={renderJobID} />   
    <Column dataField="Status" caption="Status" width={100} alignment="center"/>  
    <Column  dataField="location" caption="Location"  width={100} alignment="center"/> 
    <Column dataField="sCompanyName" caption="From" width={180} alignment="center"/>  
    <Column dataField="cCompanyName" caption="To" width={180} alignment="center"/>  
    <Column dataField="PROOF_DEL" caption="Deli. Proof" width={130} alignment="center"/>  
    <Column dataField="dtCallIn_UTC" caption="Call Time" width={160} alignment="center"/> 
    <Column  dataField="CALLER" caption="Caller"  width={120} alignment="center"/> 
    <Column dataField="RATE" caption="Rate" width={75} alignment="center"/>  
    <Column dataField="dtDCT_UTC" caption="Due By" width={160} alignment="center"/>  
    <Column 
        dataField="dtDelivered_UTC" 
        caption="Delivery Time" 
        width={160} 
        alignment="center"
        dataType="datetime"
        format="HH:mm"
    />

    <Column 
        dataField="deliveryDate" 
        caption="Delivery Date" 
        width={120} 
        alignment="center"
        cellRender={(cellData) => {
            if (!cellData.value) return null;
            const date = new Date(cellData.value);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return format(date, 'yyyy-MM-dd');
        }}
        calculateFilterExpression={(filterValue, selectedFilterOperation) => {
            if (!filterValue) return null;
            try {
                const date = new Date(filterValue);
                if (isNaN(date.getTime())) return null;
                date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                return [["deliveryDate", "=", date]];
            } catch (err) {
                console.log("Filter error:", err);
                return null;
            }
        }}
        dataType="date"
    />

    <Column 
        dataField="entryDate" 
        caption="Entry Date" 
        width={120} 
        alignment="center"
        cellRender={(cellData) => {
            if (!cellData.value) return null;
            const date = new Date(cellData.value);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return format(date, 'yyyy-MM-dd');
        }}
        calculateFilterExpression={(filterValue, selectedFilterOperation) => {
            if (!filterValue) return null;
            try {
                const date = new Date(filterValue);
                if (isNaN(date.getTime())) return null;
                date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
                return [["entryDate", "=", date]];
            } catch (err) {
                console.log("Filter error:", err);
                return null;
            }
        }}
        dataType="date"
    />
    <Column dataField="CUST_REF" caption="Reference" width={120} alignment="center"/> 
    <Column dataField="SER_AREA" caption="Ser.Area" width={120} alignment="center"/>  
    <Column dataField="SER_TIME" caption="Ser.Mins" width={120} alignment="center"/> 
    <Column dataField="sContact" caption="Sh.Contact" width={120} alignment="center"/> 
    <Column dataField="sPhone" caption="Sh.Phone" width={120} alignment="center"/>  
    <Column dataField="cContact" caption="Co.Contact" width={120} alignment="center"/> 
    <Column dataField="cPhone" caption="Co.Phone" width={120} alignment="center"/>  
    <Column dataField="PIECES" caption="Pieces" width={120} alignment="center"/> 
    <Column dataField="WEIGHT" caption="Weight" width={120} alignment="center"/>   
    <Column 
        dataField="BOLURL" 
        caption="BOL" 
        width={100} 
        alignment="center"
        cellRender={(cellData) => {
            if (!cellData.value) return null;
            return (
            <a 
                href={cellData.value}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.open(cellData.value, '_blank');
                }}
                style={{ color: "#1234b0" }}
            >
                <i className="dx-icon-download" />
            </a>
            );
        }}
        />
    <ColumnChooser enabled={true} mode="select" />
  
    <Export
      enabled={true}
      formats={exportFormats}
      allowExportSelectedData={false}
    />
   
  
  </DataGrid>
  )
}