import React from "react";
import Box from "@mui/material/Box";
import CheckIcon from "@material-ui/icons/Check";

const AccordionSummaryHead = (props) => {
  return (
    <Box
      sx={{
        width: 30,
        height: 30,
        borderRadius: "100%",
        backgroundColor: props.complete ? "#65CCB4" : "#337AB7",
        color: "#fff",
        fontWeight: 600,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "15px",
      }}
    >
      {props.complete ? <CheckIcon style={{ marginTop: "3px" }}/> : props.num}
    </Box>
  )
}

export default AccordionSummaryHead;