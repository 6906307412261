import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import styles from './styles';
import TextInput from '../../inputFields/TextInput';
import CustomIconButton from '../../inputFields/CustomIconButton';
import SelectInput from '../../inputFields/SelectInput_org';
import CustomDialog from '../../dialog/Dialog';

function CallerInput(props) {
    const [openDialog, setOpenDialog] = useState(false);
    const { error } = props;

    const toggleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const confirmHandler = () => {
        toggleDialog();
        props.onIconTwoClick();
    };

    function renderInput() {        
        if(props.inputType === "select"){
            return (
                <SelectInput
                    optionList={props.optionList}
                    value={props.value}
                    onChange={props.onChange}
                    label={props.label}
                    labelSize={props.labelSize}
                    color={props.color}
                    error={error}
                    required={true}
                    {...props}
                />
            );
        }
        if(props.inputType === "text"){
            return (
                <TextInput
                    type={props.type}
                    label={props.label}
                    id={props.name}
                    labelSize={props.labelSize}
                    inputLength={props.inputLength}                  
                    variant="outlined"
                    size="small"
                    color={props.color}
                    onChange={props.onChange}
                    error={error}
                    required={true}
                    {...props}
                />    
            );
        }
    }     

    return (
        <div style={{ 
            padding: error ? '4px' : '5px',
            border: error ? '1px solid #f44336' : 'none',
            borderRadius: '4px',
            transition: 'all 0.2s ease-in-out'
        }}>            
            <Grid container  
                direction='row' 
                justifyContent='stretch'
                alignItems='center' 
                xs={12} 
                style={{margin:'0', width:'100%' }} 
                spacing={0}>
                <Grid item xs={8}>
                    {renderInput()}
                </Grid>
                <Grid item xs={4}>
                    <span>
                        <CustomIconButton
                            icon={props.iconOne}
                            tooltip={props.iconOneTooltip}
                            onClick={props.onIconOneClick}
                            color={error ? 'secondary' : props.color}
                        />
                        {(props.inputType === "select" && 
                          props.value !== null && 
                          props.value !== 'select' && 
                          props.value !== '') && 
                            <CustomIconButton
                                icon={props.iconTwo}
                                tooltip={props.iconTwoTooltip}
                                onClick={toggleDialog}
                                color={props.color}
                            />
                        }
                        {(props.inputType === "text") &&
                            <CustomIconButton
                                icon={props.iconTwo}
                                tooltip={props.iconTwoTooltip}
                                onClick={props.onIconTwoClick}
                                color={props.color}
                            />
                        }    
                        <CustomDialog
                            open={openDialog}
                            onClose={toggleDialog}
                            dialogTitle='Warning'
                            dialogMessage='Caller will be permanently removed from the list!'
                            dialogAction={confirmHandler}
                        />    
                    </span>
                </Grid>              
            </Grid>
        </div>
    );
}

export default CallerInput;