import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';


const Canceled = () => {
    localStorage.removeItem('invoiceList');
    localStorage.removeItem('paymentAmount');
    localStorage.removeItem('paymentIntent');
    localStorage.removeItem('checkoutSession');
    return (
        <Container style={{padding: 100}}>
            <Card style={{width: 'min-content', minWidth: 450, margin: '0 auto'}}>
                <CardContent>
                    <Typography variant='heading1'>Your payment was cancelled</Typography>
                </CardContent>
                <CardActions>
                    <Button variant='outlined' color='primary' href='/invoices' style={{margin: '0 auto'}}>Return</Button>
                </CardActions>
            </Card>
        </Container>
    );
};


export default Canceled;
