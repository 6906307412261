import React, { useState, useEffect } from 'react';
import Copyright from '../Copyright';
import { Container, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import httpRequest from '../HttpService';
import PageBanner from '../orderStatus/components/pageBanner';
import { makeStyles } from '@material-ui/core/styles';
import { Button} from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ArrowForward';
import { useUserContext} from "../lib/user_context";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: 'white'
    },   
  }));
  


export default function Preferences(props)  {
    const navigate = useNavigate();
    const { sessionLoginUser,sessionPreference,sessionAccountAddress} = useUserContext();
    const [user,setUser] = useState(sessionLoginUser);
    const [address,setAddress] = useState(JSON.parse(sessionAccountAddress));
    const [preferences,setPreferences] = useState(sessionPreference);

    useEffect(() => {
        //{"dbID":884,"addressID":"2366670","companyName":"DirectIT Group","suite":"Bay 30","address":"333 28 St","quad":"NE","city":"Calgary","prov":"Alberta","country":"Canada","postal":"T2A7P4","zone":"","contact":null,"phone":null,"long":-113.993987,"lat":51.053217,"gpserror":false,"locationID":"886092","private":false,"custNo":null,"contactID":null,"cnt":10,"cnt2":21,"HasGPS":1,"RowNumber":null,"events":null,"addressExtID":null,"LastDate":"2024-03-06T09:39:35.597Z","contactName":null,"contactPhone":null}
        // console.log("Preferences", props.address);
        // console.log("sessionPreference",sessionPreference)
        // console.log("sessionLoginUser",sessionLoginUser)
        // console.log("sessionAccountAddress",sessionAccountAddress)
        setUser(sessionLoginUser);
        setAddress(JSON.parse(sessionAccountAddress));
        setPreferences(sessionPreference);
       
    },[sessionLoginUser,sessionPreference,sessionAccountAddress]);

       
   const handleLogout = (event) => {
        localStorage.clear();   
        window.location.href = "/"; 
    }

         
    return (
            <Container maxWidth="lg">
            <PageBanner title={address?.companyName} subheading={"#"+ user?.accountNumber} />
            <div style={{ margin: '5% 5% 5% 5%'}}>
            <Grid container height="100vh" display="flex" rowSpacing={0}   style={{marginLeft: 350}}>
                <Grid item md={2} style={{height: 80}}>
                    <Typography style={{ fontWeight: 600 }}>Address</Typography>
                </Grid>
                <Grid item md={10}>
                    <p>{ address?.hasOwnProperty('suite') && props.address?.suite && <Typography style={{ fontWeight: 400 }}>Suite {props.address?.suite},</Typography>} <Typography style={{ fontWeight: 400 }}>{address?.address}, {address?.quad}</Typography></p>
                    <p> <Typography style={{ fontWeight: 400 }}>{props.address?.city} </Typography> { address?.hasOwnProperty('prov') &&  <Typography style={{ fontWeight: 400 }}>{address?.prov}</Typography>} { address?.hasOwnProperty('country') &&  <Typography style={{ fontWeight: 400 }}>,{address?.country}</Typography>}</p>
                    <p>{props.address?.postal}</p>                 
                </Grid>
                <Grid item md={2} style={{height: 80}}>
                    <Typography style={{ fontWeight: 600 }}>Phone</Typography>
                </Grid>
                <Grid item md={10}>
                    <Typography style={{ fontWeight: 400 }}>{address?.phone}</Typography>
                </Grid>
                <Grid item md={2} style={{height: 80}}>
                    <Typography style={{ fontWeight: 600 }}>Openning Time</Typography>
                </Grid>
                <Grid item md={10}>
                   <Typography style={{ fontWeight: 400 }}>{preferences?.opentime}-{preferences?.closeTime}</Typography>
                </Grid> 
                <Grid item md={12}>
                    <Button
                        style={{maxWidth:"300px", minWidth:"200px", float:"center"}}
                        variant="contained"                           
                        color="primary" 
                        startIcon={<LogoutIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}
                        onClick={handleLogout}
                         >
                         Logout
                    </Button>
                </Grid>
            </Grid>  
            </div>
                {props.isEmbbed!== null && props.isEmbedded!=='yes' && <footer>
                    <Copyright/>
                </footer>}
             </Container>

        );
    }





