import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CustomInput from "../inputFields/CustomInput";
import PackageInfo from "./packageOptions/PackageInfo";
import AllPackageOptions from "./packageOptions/AllPackageOptions";
import PackageOptionsDetails from "./packageOptions/PackageOptionsDetails";
import DefineIndividualPieces from "./packageOptions/DefineIndividulePieces";
import "animate.css/animate.css";
import httpRequest from "../../../HttpService";
import AccordionNextButton from "../AccordionNextButton";

const PackageOptionsAndDetails = (props) => {  

  const [info, setInfo] = useState(props.info);
  const [packageDetail, setPackageDetail] = useState({});
  const [defineIndividual, setDefineIndividual] = useState([]);
  
  const [referenceList, setReferenceList] = useState([]);
  const [accountServiceNotes, setAccountServiceNotes] = useState([]);
  const [inputReferences, setInputReferences] = useState({});

  const [packageOptionList, setPackageOptionList] = useState([]);
  const [finishedMount, setFinishedMount] = useState(false); 
  const [validation, setValidation] = useState({ disabled: true, errorMessages: [] });


  useEffect(() => {
      setInfo(props.info);
            //if (props.completed.get(0) && props.completed.get(1) && !finishedMount) {
            //if (props.completed.get(0) && props.completed.get(1) && !finishedMount) {
      if (props.completed.get(0) && props.completed.get(1)) {  
        setPackageDetail({
          packageSize:"",
          packageDesc:"",
          pieces:0,
          weight:0,
          packageImage: null,
          additionalDescription:""
        });
        setDefineIndividual([]);
        console.log("getQuoteDescriptions" + JSON.stringify(props.info[1]));
        if(props.info[0].quoteId !== undefined){
          setTimeout(() =>{
            getPackageOptions();    
          }, 1000);
          //getPackageOptions();      
          //setFinishedMount(true);      
          //getReferenceList();
          props.onAlert({"target":"packageSize", "value":true});
          props.onAlert({"target":"packageDesc", "value":true});
          props.onAlert({"target":"pieces", "value":true});
          props.onAlert({"target":"weight", "value":true});
          props.onAlert({"target":"Initial", "value":false});
          
        }
      }    
  }, [props.info[0].quoteId,props.info[1].pickUpLocation,props.info[1].deliveryLocation,props.completed.get(1)]);

  const handleChange = (event) => {
    //this.setState({ [event.target.name]: event.target.value });
    setPackageDetail((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,        
      };
    });
    props.onChange({"target":event.target.name, "value":event.target.value});
    if (event.target.value !== null && event.target.value !== undefined) {
      props.onAlert({"target":event.target.name, "value":false});
    }

    props.setCompletedStep(2);
  };

  

  const getPackageOptions = () => {
    const tempOptionList = [];
   
    httpRequest.getQuoteDescriptions(info[0].quoteId).then((body) => {
        console.log("getQuoteDescriptions",JSON.stringify(body.data));
        console.log("getQuoteDescriptions size is",body.data.length);
        body.data.forEach((e, i) => {
          const option = tempOptionList.find((obj) => obj.descriptionID === e.descriptionID);   
          console.log("getQuoteDescriptions find option is ",JSON.stringify(option));
          if(option === undefined){
            tempOptionList.push({
              ...e,
              id: i+1,
            });
          }
        });
        setPackageOptionList(tempOptionList);
        
      }
    );
  };
  /*
  const handleNextPanel = () => {
    var packOption = packageOptionList.find(e => e.iconTitleText === packageDetail.packageSize);
    if(packOption !== undefined){
      httpRequest.postQuoteDescriptions(props.info[0].quoteId,
        packageOptionList.find(e => e.iconTitleText === packageDetail.packageSize).descriptionID,
        packageDetail.pieces,
        packageDetail.weight,
        packageDetail.additionalDescription)
      .then((body) => {
        console.log("postQuoteDescriptions",JSON.stringify(body.data));
        props.onChange({"target":"quoteDescriptions_data", "value":{pieces:packageDetail.pieces,weight:packageDetail.weight}});     
      });
      props.onNextPanel();
    }
  }
  */

  const handleNextPanel = async () => {
    console.log("handleNextPanel postQuoteDescriptions"); 

    let piecesOption = [];
    defineIndividual.forEach((item) => {
      delete item.id; 
      piecesOption.push(item)
    });
    console.log("handleNextPanel postQuoteDescriptions-"+ JSON.stringify(piecesOption)); 
    var packOption = packageOptionList.find(e => e.iconTitleText === packageDetail.packageSize);
    if(packOption !== undefined){
      let res = await httpRequest.postQuoteDescriptions(props.info[0].quoteId,
        packageOptionList.find(e => e.iconTitleText === packageDetail.packageSize).descriptionID,
        packageDetail.pieces,
        packageDetail.weight,
        packageDetail.additionalDescription,
        piecesOption.length>0? JSON.stringify(piecesOption):""
      );
      console.log("postQuoteDescriptions",JSON.stringify(res.data)); 
      props.onChange({"target":"quoteDescriptions_data", "value":{pieces:packageDetail.pieces,weight:packageDetail.weight}});     
      props.onNextPanel();   
    }
  }

  useEffect(() => {
    const { packageSize, pieces, weight } = packageDetail;
    let errorMessages = [];
    let disabled = false;
    const selectedPackage = packageOptionList.find(p => p.iconTitleText === packageSize);
  
    if (!packageSize) {
      errorMessages.push("Please select a package type.");
    }
  
    if (!pieces || pieces <= 0) {
      errorMessages.push("Please enter a valid number of pieces.");
    }
  
    // Validate total weight or sum of individual piece weights
    if (selectedPackage) {
      const { validateMaxWeight, validateMinWeight } = selectedPackage;
      const individualPiecesWeight = defineIndividual.reduce((sum, piece) => sum + Number(piece.weight || 0), 0);
      const totalWeight = individualPiecesWeight || weight;
  
      if (!totalWeight || totalWeight <= 0) {
        errorMessages.push("Please enter a valid weight.");
      } else if (validateMaxWeight && totalWeight > validateMaxWeight) {
        errorMessages.push(`Total weight cannot exceed ${validateMaxWeight} lbs for this package type.`);
      } else if (validateMinWeight && totalWeight < validateMinWeight) {
        errorMessages.push(`Total weight must be at least ${validateMinWeight} lbs for this package type.`);
      }
    }
  
    setValidation({ disabled: errorMessages.length > 0, errorMessages });
  }, [packageDetail, packageOptionList, defineIndividual]); // Added defineIndividual dependency

  return (
      <Grid container style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <Grid container justifyContent="left" sm={12}>
          <Grid item sm={10} md={10}>
            {packageOptionList.length > 0 && (
              <AllPackageOptions
                accountNumber={info[0].accountId}
                packageSize={packageDetail.packageSize}
                onChange={handleChange}
                packageOptionList={packageOptionList}
              />
            )}
            {packageOptionList.length > 0 && (
              <PackageInfo
                accountNumber={info[0].accountId}
                packageOptionList={packageOptionList}
                packageSize={packageDetail.packageSize}
                packageDesc={packageDetail.packageDesc}
                pieces={packageDetail.pieces}
                weight={packageDetail.weight}
                additionalDescription={packageDetail.additionalDescription}
                onChange={handleChange}
                defineIndividual={defineIndividual}
              />
            )}
          </Grid>      
        </Grid>
        <Grid item sm={10} md={10}>
          <DefineIndividualPieces setDefineIndividual={setDefineIndividual}/>
        </Grid>
        <Grid item sm={12}>
          <AccordionNextButton 
            onClick={handleNextPanel}
            disabled={validation.disabled}
            errorMessage={validation.errorMessages.join('\n')}
          />
        </Grid>
      </Grid>
  );
}

  

export default PackageOptionsAndDetails;
