import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@mui/material/Select'
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './styles.js';

function SelectInput(props) {
    const { error } = props;

    let optionList = props.optionList && props.optionList.map((item, i) => {
        return (           
            <option key={item.value} value={item.value}>{item.label}</option>
        )
    });

    const errorStyle = error ? {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336',
            borderWidth: '2px'
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336'
        }
    } : {};

    return (
        <Grid
            container
            direction="row"
            justifyContent={props.justifyContent}            
            alignItems="stretch"
            spacing={0}
            style={styles.root}         
        >
            <Grid item xs={props.labelSize} style={styles.labelWrapper}>
                <Typography 
                    variant="subtitle1" 
                    style={{
                        ...styles.label,
                        color: error ? '#f44336' : styles.label.color
                    }}
                >
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={12 - props.labelSize}>
                <FormControl size="small" fullWidth error={error}>
                    <InputLabel style={{ color: error ? '#f44336' : undefined }}>
                        {props.label}
                    </InputLabel>
                    <Select
                        variant="outlined"  
                        style={{
                            ...styles.formControlSelect,
                            ...errorStyle
                        }}
                        defaultValue="Please Select ..."
                        input={
                            <OutlinedInput
                                name={props.label}
                                error={error}
                            />
                        }
                        native
                        {...props}
                    >
                        <option value="select">Please Select ... </option>
                        {optionList}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default SelectInput;