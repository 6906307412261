import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import NoticeEmitter from '../../../../NoticeUtil';

const styles = {
  root: {
    display: 'flex',
  },
  formControlSelect: {
    minWidth: 170,
    paddingRight: 20,
    paddingBottom: 16,
  },
  formControlTextfield: {
    width: 115,
    paddingRight: 20,
    paddingBottom: 16,
  }
};

function PackageInfo(props) {
  const [pieces, setPieces] = useState(props.pieces);
  const [weight, setWeight] = useState(props.weight);
  const [packageOptionList, setPackageOptionList] = useState(props.packageOptionList);
  const [additionalDescription, setAdditionalDescription] = useState(props.additionalDescription);
  const [defineIndividual, setDefineIndividual] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);



 
  useEffect(() => {    
    const callback = (data) => {
      if(data === "add") {      
        setPieces(Math.max(defineIndividual.length, props.pieces || 0));
      } else {
        setPieces(Math.max(defineIndividual.length, Math.max((props.pieces || 0) - 1, 0)));
      }
    };
    
    NoticeEmitter.addListener("PackagePiecesUpdate", callback);
    return () => NoticeEmitter.removeListener("PackagePiecesUpdate", callback);
  }, [defineIndividual.length, props.pieces]);

  // useEffect(() => {    
  //   NoticeEmitter.addListener("PackagePiecesUpdate",updatePiecesCallBack);
  //   return () =>NoticeEmitter.removeListener("PackagePiecesUpdate");
  // }, []);
  

  useEffect(() => {    
      
      let p_piece = props.pieces === undefined? 0: props.pieces;
      setPieces(defineIndividual.length>p_piece? defineIndividual.length : p_piece);    

     //setPieces(props.pieces === undefined? 0: props.pieces);
     setWeight(props.weight === undefined? 0: props.weight);
     setPackageOptionList(props.packageOptionList);
     setAdditionalDescription(props.additionalDescription);
  },[props.weight,props.packageOptionList,props.additionalDescription]);

   useEffect(() => {    
    console.log("props.defineIndividual",props.defineIndividual)
    setDefineIndividual(props.defineIndividual)
    // let p_piece = props.pieces === undefined? 0: props.pieces;
    // setPieces(props.defineIndividual.length>p_piece? props.defineIndividual.length : p_piece);   
  },[props.defineIndividual]);


  
  useEffect(() => {
    if (isInitialLoad && props.packageSize) {
      const selectedOption = packageOptionList.find(e => e.iconTitleText === props.packageSize) || {};
      setPieces(selectedOption.fillPieces);
      setWeight(selectedOption.fillWeight);
      setIsInitialLoad(false);
    }
  }, [props.packageSize, isInitialLoad]);

  useEffect(() => {
    props.onChange({
      target:{
         name: "pieces",
         value: pieces
      }
    });  
  },[pieces]);

  useEffect(() => {
    props.onChange({
      target:{
        name: "weight",
        value: weight
      }
    });
  },[weight]);

  const handleDescriptionChange = (event) => {
    props.onChange({
      target:{
        name: "additionalDescription",
        value: event.target.value
      }
    });
    setAdditionalDescription(event.target.value);
  }

  const handleWeightChange = (event) => {
    console.log("handleWeightChange",event.target.value)
    if(event.target.value==='') setWeight('');
    else{
      const newWeight = parseInt(event.target.value);
      if (newWeight < 0) setWeight(0);
      if (newWeight >= 0 && newWeight <= 99999) {
        setWeight(newWeight.toString());
      }
    }
    
  }

  const handlePiecesChange = (event) => {
    console.log("handlePiecesChange",event.target.value)
    if(event.target.value==='') setPieces('');
    else{
      const newPieces = parseInt(event.target.value);
      if (newPieces < 0) setPieces(0);
      if (newPieces >= 0 && newPieces <= 999) {
        setPieces(newPieces.toString());
      }
    }
  }
 
  const handlePackageChange = (event) => {
     props.onChange({
      target:{
        name: "packageDesc",
        value:event.target.value
      }
    });

    const selectedOption = packageOptionList.find(e => e.iconTitleText === event.target.value) || {};

    setPieces(selectedOption.fillPieces);
    props.onChange({
      target:{
         name: "pieces",
         value: selectedOption.fillPieces
      }
      });
    
    props.onChange({
      target:{
        name: "packageSize",
        value: selectedOption.iconTitleText
      }
    }); 

    props.onChange({
      target:{
        name: "packageImage",
        value: selectedOption.icon_url
      }
    }); 
  }

  //packageType
  return (
    <Tooltip title="Refine even more" placement="top" arrow>
    <div>
      {/* <Typography variant = 'h6' color="primary">Package Info</Typography> */}
      <div style={{ paddingLeft: '45px',paddingTop:'10px' }}>
      {/* <FormControl variant="outlined" style={styles.formControlSelect}>
        <InputLabel>Size</InputLabel>
        <Select
          value={props.packageDesc}
          onChange={handlePackageChange}
          label="Size"
          name="packageDesc"
        >
          {packageOptionList.map((option) => (
            <MenuItem value={option.iconTitleText}>{option.iconTitleText}</MenuItem>
          ))}
        </Select>
      </FormControl> */}
      <FormControl style={styles.formControlTextfield}>
        <Typography style={{fontSize: 20, fontWeight: 600, marginLeft: 30}} color="primary">Total:</Typography>
      </FormControl>     
      <FormControl style={styles.formControlTextfield}>
        <InputLabel>Pieces</InputLabel>
        <Input
          name="pieces"
          value={pieces}
          onBlur={props.onChange}
          onChange={handlePiecesChange}          
          type="number"
          startAdornment={
            <InputAdornment position="start">
              #
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl style={styles.formControlTextfield}>
        <InputLabel>Weight</InputLabel>
        <Input
          name="weight"
          value={weight}
          onBlur={props.onChange}
          onChange={handleWeightChange}
          type="number"
          startAdornment={
            <InputAdornment position="start">
              lbs
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl>
        <InputLabel>additional descriptions</InputLabel>
        <Input
          name="additionalDescription"
          value={additionalDescription}
          onBlur={props.onChange}
          onChange={handleDescriptionChange}
          type="text"
        />
      </FormControl>
      </div>
    </div>
    </Tooltip>

    
  );
}

export default PackageInfo;