// src/components/common/AccordionNextButton.jsx
import React from 'react';
import Button from "@material-ui/core/Button";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const AccordionNextButton = ({ 
  onClick, 
  disabled = false,
  errorMessage = '',
}) => {
  return (
    <div style={{ marginTop: '16px' }}>
      <Button
        variant="contained"
        color="primary"
        endIcon={<KeyboardArrowRightIcon />}
        onClick={onClick}
        disabled={disabled}
        style={{
          backgroundColor: '#337AB7',
          width: '200px'
        }}
      >
        Next
      </Button>
      {errorMessage && errorMessage.split('\n').map((msg, i) => (
        <div 
          key={i}
          style={{ 
            marginTop: '8px', 
            color: '#f44336', 
            fontSize: '0.875rem' 
          }}
        >
          {msg}
        </div>
      ))}
    </div>
  );
};

export default AccordionNextButton;