import React, {useEffect, useContext, useState } from "react"
import HttpRequest from "../HttpService";
import axios from "axios";

export const UserProvider = ({ children }) => {
  const [sessionToken, setSessionToken] = useState(null);
  const [sessionAccount, setSessionAccount] = useState(null);
  const [sessionLoginInfo, setSessionLoginInfo] = useState(null);
  const [sessionLoginUser, setSessionLoginUser] = useState(null);
  const [sessionAccountAddress, setSessionAccountAddress] = useState(null);
  const [sessionPreference, setSessionPreference] = useState(null);
  const [sessionIP, setSessionIP] = useState();
  const [previousOrder, setPreviousOrder] = useState();
  const [isEmbedded, setIsEmbedded] = useState();
  const [apiVersion, setAPIVersion] = useState("1.1.0");

  useEffect(() => {
    let token = "";
    console.log("UserProvider App wondow token is", window.token);
    console.log("UserProvider App url is", window.location.href);
    token = window.location.href.split("sso_token=")[1];
    console.log("App token", token);

    HttpRequest.getAPIVersion().then(async(body)=> {
      try{
        console.log("getAPIVersion:" + body.data.message);      
        setAPIVersion(body.data.message.substring(body.data.message.indexOf(":") + 1));
      }
      catch(e){console.log("getAPIVersion exception  "+ e )}
    });

    axios.get('https://api.ipify.org/').then((res)=> {
      //console.log(res.data);
      //localStorage.setItem("ipaddress",res.data);
      updateIPAddress(res.data);
    });
        
    if (token === undefined) {
      token = localStorage.getItem("sso_token");    
      console.log("token", token);
      if (token === null) {
        return;
      }
    } else {          
      updateLoginUser(null);
      updatePreviousOrder(null);
      updateIsEmbedded(window.location.href.includes("CreateOrder")?"yes":"no");
    }
    window.token = token;

    HttpRequest.postLoginToken(token).then((body) => {
        console.log("Post Login Token", JSON.stringify(body.data));
        if (body.data.hasOwnProperty("error")) {
          console.log("wrong token", body.error);
          window.location.href = "/";
        } else {               
          localStorage.setItem("sso_token", token);  
          console.log("Post Login Token -account", body.data.user.accountNumber);
          updateToken(token);
          setSessionLoginInfo(body.data);
          updateLoginUser(body.data.user);
          updateAccount(body.data.user.accountNumber); 
          setSessionPreference(body.data.preferences);  
          
          localStorage.setItem("accountNumber", body.data.user.accountNumber);      

          HttpRequest.getAddressByID(body.data.user.accountNumber,body.data.user.address.addressID).then(async (body) => {
            console.log("getAddressByID:" + JSON.stringify(body.data[0]));            
            setSessionAccountAddress(JSON.stringify(body.data[0]));
          });
        }
      })
      .catch((body) => {
        console.log("error", body);       
      });

      axios.get('https://api.ipify.org/').then((res)=> {
        //console.log(res.data);      
        updateIPAddress(res.data);
      });
  }, []);


  const updateToken = (token) => {
    console.log("updateToken", token);
    setSessionToken(token)
  }
  const getToken = () => {
    return sessionToken
  }

  const updateAccount = (account) => {
    setSessionAccount(account)
  }
  const getAccount = () => {
    return sessionAccount
  }

  const updateIsEmbedded = (val) => {
    setIsEmbedded(val)
  }
  const getIsEmbedded = () => {
    return isEmbedded
  }

  const updatePreviousOrder = (val) => {
    setPreviousOrder(val)
  }
  const getPreviousOrder = () => {
    return previousOrder;
  }

  const updateLoginUser = (val) => {
    setSessionLoginUser(val)
  }

  const getLoginUser = () => {
    return sessionLoginUser;
  }

  const updateAccountAddress = (val) => {
    setSessionAccountAddress(val)
  }

  const getAccountAddress = () => {
    return sessionAccountAddress;
  }

  const updateIPAddress = (val) => {
    setSessionIP(val)
  }

  const getIPAddress = () => {
    return sessionIP;
  }

  return (
    <UserContext.Provider value={
      {       
        updateToken,
        getToken,
        sessionToken,
        sessionAccount,
        sessionLoginInfo,
        sessionLoginUser,
        sessionAccountAddress,
        sessionIP,
        previousOrder,
        isEmbedded,
        sessionPreference,
        apiVersion,
        updateAccount,
        getAccount,
        updateLoginUser,
        getLoginUser,
        updateIsEmbedded,
        getIsEmbedded,
        updatePreviousOrder,
        getPreviousOrder,
        updateAccountAddress,
        getAccountAddress,
        updateIPAddress,
        getIPAddress
      }
    }>
      {children}
    </UserContext.Provider>
  )
}

const UserContext = React.createContext();
// make sure use
export const useUserContext = () => {
  return useContext(UserContext)
}
