import React, { useState, useEffect } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MapContainer from "../mapContainer";
import AddressPanel from "./addressPanel";
import CustomInput from "../inputFields/CustomInput";
import "animate.css/animate.css";
import httpRequest from "../../../HttpService";
import { useUserContext } from "../../../lib/user_context";
import AccordionNextButton from "../AccordionNextButton";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 200px)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xl')]: {
      height: 'calc(100vh - 220px)',
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
  },
  addressColumn: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mapColumn: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mapContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(2),
  },
  toggleButton: {
    [theme.breakpoints.up('xl')]: {
      padding: '8px 16px',
      fontSize: '1rem',
    },
  },
}));

const PickUpandDeliveryLocations = (props) => {  
  const classes = useStyles();
  const [info, setInfo] = useState(props.info);
  const [deliveryLocation, setDeliveryLocation] = useState({});
  const [pickUpLocation, setPickupLocation] = useState({});
  const [coContactName, setCoContactName] = useState(null);
  const [coContactPhone, setCoContactPhone] = useState(null);
  const [shContactName, setShContactName] = useState(null);
  const [shContactPhone, setShContactPhone] = useState(null);
  const [isUpdateMap, setIsUpdateMap] = useState(false);
  const [mapButtonText, setMapButtonText] = useState("StreetView");
  const [mapType, setMapType] = useState("directions"); 

  const {sessionToken} = useUserContext();

  useEffect(() => {
    console.log('PickUpandDeliveryLocations  useEffect');
    props.onAlert({"target":"pickUpLocation", "value":true});
    props.onAlert({"target":"deliveryLocation", "value":true});
    props.onAlert({"target":"Initial", "value":false});
  }, [props.info[0].quoteId]);

  const changeMapType = () => {
    if (mapType === "directions") {
      setMapType("place");
      setMapButtonText("StreetView");
    } else {
      setMapType("directions");
      setMapButtonText("Directions");
    }
    if (mapType === "directions") {
      setMapType("place");
      setMapButtonText("StreetView");
    } else {
      setMapType("directions");
      setMapButtonText("Directions");
    }
  };

  const handleAddressChange = (address, type, disable = false) => {
    if (type === "consignee") {
      setDeliveryLocation(address);
      props.onChange({"target":"deliveryLocation", "value":address});
      props.onAlert({"target":"deliveryLocation", "value":false});
      setCoContactName(null);
      setCoContactPhone(null);     
    } else {
      if (!disable && pickUpLocation !== undefined && address.addressID !== pickUpLocation.addressID) {
        props.openDialog("openChangePickupDialog");
      }
      setPickupLocation(address);
      props.onChange({"target":"pickUpLocation", "value":address});
      props.onAlert({"target":"pickUpLocation", "value":false});
      setShContactName(null);
      setShContactPhone(null);
    }
    setIsUpdateMap(true); 
    props.setCompletedStep(1);  
  };

  const handleFedexTrackNumChange = (event) => {
    props.onChange({"target":"URL", "value":event.target.value});
  };

  const handleNextPanel = () => {
    console.log("==============getToken",sessionToken);
    httpRequest.postQuoteAddresses(props.info[0].quoteId,
      parseInt(pickUpLocation.addressID),
      pickUpLocation.contactID,
      parseInt(deliveryLocation.addressID),
      deliveryLocation.contactID)
    .then((body) => {
      console.log("postQuoteAddresses",JSON.stringify(body.data));
      props.onChange({"target":"quoteAddresses_data", "value":body.data});
    });
    props.onNextPanel();
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.addressColumn}>
            <AddressPanel
              type="shipper"
              onChange={handleAddressChange}
              initialSelected={""}
              onChangeContact={props.onChange}
              accountNumber={info[0].accountId} 
              quoteId={props.info[0].quoteId}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.addressColumn}>
            <AddressPanel
              type="consignee"
              onChange={handleAddressChange}
              initialSelected={""}
              pickUpLocation={pickUpLocation}
              accountNumber={info[0].accountId}
              quoteId={props.info[0].quoteId}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.mapColumn}>
            <ToggleButtonGroup
              size="small"
              value={mapType}
              exclusive
              onChange={(event, newMapType) => setMapType(newMapType)}
              aria-label="map view toggle"
            >
              <ToggleButton value="directions" aria-label="directions map" className={classes.toggleButton}>
                Directions
              </ToggleButton>
              <ToggleButton value="place" aria-label="street view map" className={classes.toggleButton}>
                StreetView
              </ToggleButton>
            </ToggleButtonGroup>
            <div className={classes.mapContainer}>
              <MapContainer
                accountNumber={info[0].accountId}
                IsUpdateMap={isUpdateMap}
                consignAddress={deliveryLocation.companyName}
                consignCoord={{
                  lat: deliveryLocation.lat,
                  long: deliveryLocation.long,
                }}
                shipperAddress={pickUpLocation.companyName}
                shipperCoord={{
                  lat: pickUpLocation.lat,
                  long: pickUpLocation.long,
                }}
                type={mapType}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.footer}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <CustomInput
              type="text"
              icon="ClearIcon"
              inputType="text"
              label="Third Party URL"
              name="FedexTrackNum"
              iconTooltip="Clear"
              labelSize={4}
              onBlur={handleFedexTrackNumChange}
            />
          </Grid>
          <Grid item sm={12}>
            <AccordionNextButton 
              onClick={handleNextPanel}
              disabled={!info[0].accountId || !info[0].caller || !pickUpLocation.companyName || !deliveryLocation.companyName}
              errorMessage={
                Object.keys(pickUpLocation).length === 0 && Object.keys(deliveryLocation).length === 0 ? '' :
                !info[0].accountId ? 'Please select an account.' :
                !info[0].caller ? 'Please enter your name.' :
                !pickUpLocation.companyName ? 'Please select a pickup location.' :
                !deliveryLocation.companyName ? 'Please select a delivery location.' :
                ''
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PickUpandDeliveryLocations;