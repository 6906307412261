import 'date-fns';
import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import styles from '../newOrder/components/DeliveryTime/pickUpDeliveryTime/styles.js';

function SelectSearchTypeOptions(props) {
  const [value, setValue] = useState(localStorage.getItem('searchHistory')!==null ? localStorage.getItem('searchHistory') === "no" ? "month" :localStorage.getItem('searchHistory') : "month")
  const handleChange = (event) => {
    setValue(event.target.value);
    props.onChange({
      target: {
        name: props.name,
        value: event.target.value,
      }
    });
  };
 
  return (
    <div>
      <Typography color="primary">Search Type</Typography>
      <RadioGroup value={value} onChange={handleChange} style={styles.radio}>
        <FormControlLabel value="month" control={<Radio color='primary'/>} label='Month'/>
        <FormControlLabel value="date" control={<Radio color='primary'/>} label='Date'/>
      </RadioGroup>    
    </div>
  );
}

export default SelectSearchTypeOptions;

