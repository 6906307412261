import React, { useState, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "animate.css/animate.css";
import './styles/TagCheckBox.css';

const TagCheckBox = (props) => {
  const [checkBoxValue, setCheckBoxValue] = useState(props.value);

  // useEffect(() => {
  //   if (props.required) {
  //     if (!checkBoxValue) {
  //       props.onAlert.addAlert(props.label);
  //     } else {
  //       props.onAlert.removeAlert(props.label);
  //     }
  //   }
  // }, [checkBoxValue]);

  const handleChange = (event) => {
    props.onChange({
      name: props.param,
      value: !checkBoxValue,
    });
    setCheckBoxValue(!checkBoxValue);
  };

  return (
    <div className="tag-checkbox-container">
      <FormControlLabel
        control={<Checkbox checked={checkBoxValue} />}
        label={props.param}
        onChange={handleChange}
        className="checkbox-label"
      />
    </div>
  );
};

export default TagCheckBox;
