import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "animate.css/animate.css";
import InstructionInput from "../PackageOptionsandDetails/packageOptions/MemoInput";
import './styles/TagSelects.css';

const TagSelects = (props) => {
  const [signatureRequired, setSignatureRequired] = useState(true);
  const [defaultInstructions, setDefaultInstructions] = useState("");

  useEffect(() => {
    props.onAlert({"target":"instructions", "value":true});
  }, []);

  useEffect(() => { 
    setDefaultInstructions("");
    props.onAlert({"target":"instructions", "value":true});
  }, [props.weight,props.pieces]);

 

  const handleSignatureRequired = (event) => {
    props.onChange({"target":"signatureRequired", "value":!signatureRequired});
    setSignatureRequired(!signatureRequired);
  };

  const handleDefaultInstructions = (event) => {
    props.onChange({"target":"instructions", "value":event.target.value});
    setDefaultInstructions(event.target.value);
    if (event.target.value === "" || event.target.value === null || event.target.value === undefined) {
      props.onAlert({"target":"instructions", "value":true});
    } else {
      props.onAlert({"target":"instructions", "value":false});
    }
  };

  return (
    <div className="tag-selects-container">
      <div className="tag-selects-padding-bottom">
        {/* Padding adjusted using CSS */}
      </div>
      <div>
        <InstructionInput
          className="tag-selects-instruction-input"
          type="textarea"
          iconOne="ClearIcon"
          inputType="text"
          inputLength={200}
          multiline
          minRows={2}
          label="Instruction"
          name="instructions"
          iconOneTooltip="Clear"
          onIconOneClick={(event) => {
            setDefaultInstructions("");
            props.onAlert({"target":"instructions", "value":true});
          }}
          value={defaultInstructions}
          onChange={handleDefaultInstructions}
          labelSize={3}
          //onBlur={handleDefaultInstructions}
        />
  
        <FormControlLabel
          className="tag-selects-form-control"
          control={
            <Checkbox
              checked={!signatureRequired}
              onClick={handleSignatureRequired}
              color="primary"
            />
          }
          label="No Signature Required"
        />
      </div>
    </div>
  );  
};

export default TagSelects;
