import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@mui/material/Tooltip';
import "animate.css/animate.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import './styles/TagTimePicker.css';


const TagTimePicker = (props) => {
  const [value, setValue] = useState(dayjs(props.value));
  //const [value, setValue] = useState(dayjs(Date.now()));
  const tipText =  props.prompt?props.prompt:''; 

  useEffect(() => {
    console.log("------TagDatePicker-----" + props.value);    
  },[props.value]);   


  const handleChange = (newValue) => {
    console.log("TagDatePicker handleChange-" + JSON.stringify(newValue));
    props.onChange({
      name: props.param,
      value: newValue,
    });
    setValue(newValue);
  };

  return (
    <Grid container className="time-picker-container">
      <Grid item xs={12} sm={12} className="time-picker-item">
        <Tooltip title={tipText} placement="top" arrow>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker 
              className="time-picker"
              fullWidth
              size='small'
              label={props.label}
              value={value}
              onChange={handleChange}
            />
          </LocalizationProvider>
        </Tooltip>
      </Grid>
    </Grid>
  );  
};

export default TagTimePicker;
