import React, { useState, useEffect,useCallback} from "react";
import { format, parseISO } from "date-fns";
import "devextreme/dist/css/dx.light.css";

import httpRequest from '../HttpService';
import { useUserContext} from "../lib/user_context";
import OrdersDevExpressDataGrid from "../components/OrdersDevExpressDataGrid";

export default function OrderList(props) {
  const { sessionAccount} = useUserContext();
  const [orders, setOrders] = useState([]);    
  const [account,setAccount] = useState(sessionAccount);

  const getOrderList = useCallback(async (sessionAccount) => {
    try {
      setAccount(sessionAccount);
      getOrder(sessionAccount);
        const interval = setInterval(() => {
           getOrder(sessionAccount)
      },     60000);
      return () => clearInterval(interval);
    } catch (error) {
      console.log(error);
    }
  }, []);



  useEffect(() => {
      console.log("OrderStatus sessionAccount is " + sessionAccount)
      if(sessionAccount!==null){
        getOrderList(sessionAccount)
      }
  }, [sessionAccount]);


  const locationToString = new Map(
    [
    [-1, "All"],
    [0, ""],
    [1, "At Client"],
    [2, "On Driver"],
    [4, "In Sort"],
    [5, "Rotation Sort"],
    [8, "Delivered"]]
);
const statusToString = new Map(
  [
    [-1, "All"],
    [0, ""],
    [1, "New/Review"],
    [2, "Avail for Dispatch"],
    [3, "Driver Pending"],
    [4, "Driver Responsbility"],
    [8, "Delivered"],
    [16, "Verified"],
    [32, "Invoiced"]
  ]
);


  const getOrder= (accountNum) =>{  
    httpRequest.getOrders(accountNum).then(async (body) =>{
      let _orderList =  body.data.map((value,index) => {
        value.id = index;
        value.Status = statusToString.get(value.Status);
        value.location = locationToString.get(value.location);
        value.dtCallIn_UTC =!value.dtCallIn_UTC ? '' : format(parseISO(value.dtCallIn_UTC), dateFormat);
        value.dtDCT_UTC =!value.dtDCT_UTC ? '' : format(parseISO(value.dtDCT_UTC), dateFormat);
        value.dtDelivered_UTC = !value.dtDelivered_UTC ? '' : format(parseISO(value.dtDelivered_UTC), dateFormat);
        return value;
    });
    setOrders(_orderList);
  });    
}

  const dateFormat = "MM/dd/yyyy HH:mm";

  return (    
      <OrdersDevExpressDataGrid orders={orders} account={props.account} listType="orderStatus"/>  
  );
}

/*
import React, { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { format, parseISO } from "date-fns";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
  ColumnChooser,
  Pager,
  SearchPanel,
  GroupPanel,
  Grouping,
  SortByGroupSummaryInfo,
  Summary,
  MasterDetail,
} from "devextreme-react/data-grid";
import {
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Scrolling,
} from "devextreme-react/data-grid";


import httpRequest from '../HttpService';
import { useNavigate } from 'react-router-dom';
import { useUserContext} from "../lib/user_context";
import OrdersDevExpressDataGrid from "../components/OrdersDevExpressDataGrid";

export default function OrderList(props) {
  const { sessionAccount} = useUserContext();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);    
  const dataTable = useRef(null);
  const [account,setAccount] = useState(sessionAccount);

  
  useEffect(() => {
      console.log("OrderStatus sessionAccount is " + sessionAccount)
      if(sessionAccount!==null){
        setAccount(sessionAccount);
        getOrder(sessionAccount);
          const interval = setInterval(() => {
             getOrder(sessionAccount)
        },     60000);
        return () => clearInterval(interval);
      }
  }, [sessionAccount]);


  const locationToString = new Map(
    [
    [-1, "All"],
    [0, ""],
    [1, "At Client"],
    [2, "On Driver"],
    [4, "In Sort"],
    [5, "Rotation Sort"],
    [8, "Delivered"]]
);
const statusToString = new Map(
  [
    [-1, "All"],
    [0, ""],
    [1, "New/Review"],
    [2, "Avail for Dispatch"],
    [3, "Driver Pending"],
    [4, "Driver Responsbility"],
    [8, "Delivered"],
    [16, "Verified"],
    [32, "Invoiced"]
  ]
);


const getOrder= (accountNum) =>{  
  httpRequest.getOrders(accountNum).then(async (body) =>{
    let _orderList =  body.data.map((value,index) => {
        value.id = index;
        value.Status = statusToString.get(value.Status);
        value.location = locationToString.get(value.location);
        value.dtCallIn_UTC =!value.dtCallIn_UTC ? '' : format(parseISO(value.dtCallIn_UTC), dateFormat);
        value.dtDCT_UTC =!value.dtDCT_UTC ? '' : format(parseISO(value.dtDCT_UTC), dateFormat);
        value.dtDelivered_UTC = !value.dtDelivered_UTC ? '' : format(parseISO(value.dtDelivered_UTC), dateFormat);
        return value;
  });
  setOrders(_orderList);
});    
}

const dateFormat = "MM/dd/yyyy HH:mm";

const renderJobID = params => {
    return(
      //   <a style={{ color: "#1234b0" }}
      //     onClick={() => {       
      //        navigate(`/orderDetail/${e.data.jobID}`);  
      //     }}
      //   >
      //      {params.value}{" "}
      //  </a>

        <Link to = {`/orderDetail/${params.value}/${sessionAccount}`}>{params.value}</Link>
     );    
}


const renderDataTime = (params) => {
      if(params.value){
      return (      
          <a style={{ color: "#1234b0" }}>
            {new Date(params.value).toLocaleString("en", {               
                year: "numeric",
                month: "2-digit",
                 day: "numeric"
            })}
          </a>
      );
      }
};

const startViewDetail = (e) => {
    console.log("startViewDetail --" + JSON.stringify(e.data));
    // navigate(`/orderDetail/${e.data.jobID}`);  
    
};
 
  const onSelectionChanged = ({ selectedRowsData }) => {
    //const data = selectedRowsData[0];
    console.log("onSelectionChanged --" + JSON.stringify(selectedRowsData));
   // props.setSelection(selectedRowsData);
  };


  const filterBuilderPopupPosition = {
    of: window,
    at: "top",
    my: "top",
    offset: { y: 10 },
  };

  return (  
    <DataGrid
      ref={dataTable}
      dataSource={orders}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="widget"
      keyExpr="id"
      onSelectionChanged={onSelectionChanged}
      onCellDblClick ={startViewDetail}
      //onExporting={onExporting}
      //rowAlternationEnabled={true}
      onRowPrepared={(e) => {
        //if(e.data)
       //  console.log(e.data.jobID + "--onRowPrepared --" + e.data.Status);  //Status
        if (e.data && e.rowIndex % 2 == 0)
          e.rowElement.style.backgroundColor = "#FFFFFF";
        else if (e.data && e.rowIndex % 2 !== 0)
          e.rowElement.style.backgroundColor = "#E5E7E9"; 
      }}
    >
      
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={filterBuilderPopupPosition} />
      <HeaderFilter visible={true} />

      <GroupPanel visible={true} />
      <Grouping autoExpandAll={false} />
      <SearchPanel visible={true} width={240} placeholder="Search..." />

      <Selection
        mode="multiple"
        selectAllMode="page"
        showCheckBoxesMode="always"
      />

      <FilterRow visible={true} />
      <Paging defaultPageSize={30} />
      <Pager
        visible={true}
        allowedPageSizes="all"
        showInfo={true}
        infoText="Page #{0}. Total: {1} ({2} items)"
        displayMode="full"
      />
   
      <Column  dataField="jobID" caption="OrderID"  width={100} cellRender={renderJobID}/> 
      <Column  dataField="Status" caption="Status"  width={120} />
      <Column  dataField="location" caption="location" width={120} />     
      <Column  dataField="PROOF_DEL" caption="Deli. Proof" width={150} />   
      <Column dataField="dtCallIn_UTC" caption="Call Time" width={150} /> 
      <Column dataField="CALLER" caption="Caller" width={150} />
      <Column dataField="RATE" caption="RATE#" width={120} />
      <Column dataField="dtDCT_UTC" caption="Due By" width={160} />
      <Column dataField="dtDelivered_UTC" caption="Deli. By" width={150}/>     
      <Column dataField="CUST_REF" caption="Reference" width={140} />  
      <Column dataField="sCompanyName" caption="From" width={180} />  
      <Column  dataField="cCompanyName" caption="To" width={180}/>       
      <Column dataField="SER_AREA" caption="Ser.Area" width={120}/>           
      <Column dataField="SER_TIME" caption="Ser.Mins" width={120}/>
      <Column dataField="sContact" caption="Sh.Contact" width={140}  />   
      <Column dataField="sPhone" caption="Sh.Phon" width={140}/>
      <Column dataField="cContact" caption="Co.Contact" width={140} /> 
      <Column  dataField="cPhone" caption="Co.Phon"  width={140}/>     
     
      <ColumnChooser enabled={true} mode="select" />
      <Summary>        
      </Summary>
      <SortByGroupSummaryInfo summaryItem="ID" />     
    </DataGrid>    
  );
}


*/