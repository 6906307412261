import React, { useState, useEffect } from 'react';
import { Container} from '@material-ui/core';
// import OrderDataGrid from './orderDataGrid'
import OrderList from './orderList'
import Copyright from '../Copyright';
import { useUserContext} from "../lib/user_context";


export default function OrderStatus(props) {     
    const { sessionAccount} = useUserContext();
     useEffect(() => {
         console.log("OrderStatus sessionAccount is " + sessionAccount)
     }, []);

    return (
        <Container maxWidth="lg" class="NewOrder">
            <div style = {{height: 1200, width: '100%'}}>
                {/* <OrderList account={localStorage.getItem('current_accountNumber')!== ''?localStorage.getItem('current_accountNumber'):localStorage.getItem('accountNumber')}/>   */}
                <OrderList account= {sessionAccount}/>  
            </div>
            {/* {!(localStorage.getItem('IsEmbedded') !== null && localStorage.getItem('IsEmbedded')==='yes') && <footer> */}
            {props.isEmbedded!==null && props.isEmbedded!=="yes" && <footer>
                <Copyright/>
            </footer>}
        </Container>        
    );
 

}



