import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container} from '@material-ui/core';
import Copyright from './Copyright';


export default function ExtendWeb(props)  {

    const {webtype} = useParams();    
    const [url, setUrl] = useState('https://www.directitgroup.ca/faq');

    useEffect(() => {
        console.log("webtype is " + webtype)
        var wd_url;
        switch(webtype) {
            case "statement":
                wd_url = "https://www.directitgroup.ca/terms-policy";
                break;
            case "privacypolicy":
                wd_url = "https://www.directitgroup.ca/privacy-policy";
                break;
            case "covid":
                wd_url = "https://www.directitgroup.ca/covid19-statement";
                break;
            case "term":
                wd_url = "https://www.directitgroup.ca/t-s-and-c-s";
                break;  
            // case "payment":
            //     wd_url = localStorage.getItem('payment_url');;
            //     break;  
            default:
                wd_url = "https://www.directitgroup.ca/faq";
        }
        setUrl(wd_url);

    },[webtype]);

   return (
            <Container maxWidth="lg" class="NewOrder">
                <iframe
                    width="100%"
                    height="1150px"
                    src={url}
                />   
                {!props.isEmbbed && <footer>
                    <Copyright/>
                </footer>}
            </Container>            
        );
    }


