import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@material-ui/core/Typography';
import styles from '../inputFields/styles.js';
import CustomIconButton from '../inputFields/CustomIconButton';
import Alert from '@mui/material/Alert';
import "animate.css/animate.css";
import './styles/TagTextFielsEditable.css';

const TagTextFielsEditable = (props) => {
  const [textValue, setTextValue] = useState("");
  const tipText =  props.prompt?props.prompt:'';

  useEffect(() => {
    //console.log("========TagTextFielsEditable============" + props.value);
    setTextValue(props.value)
  },[props.value]);

  useEffect(() => {
    if (props.required) {
      if (textValue === "" || textValue === null || textValue === undefined) {        
        props.onAlert.addAlert(props.label);
      } else {
        props.onAlert.removeAlert(props.label);
      }
    } 
  }, [textValue]);

  const handleChange = (event) => {
    if (props.maxLength === undefined || event.target.value.length <= props.maxLength) {
      setTextValue(event.target.value);
      props.onChange({
        name: props.param,
        value: event.target.value,
      });
    }
  };

  const clearText = () => {
    setTextValue("");
    props.onChange({
      name: props.param,
      value: "",
    });
  };

  const createHelperText = () => {
    if (props.maxLength === undefined) {
      return '';
    }
    return `There are ${props.maxLength - textValue.length} characters available to enter.`
  }

  return (
    <Grid container className="tag-textfield-editable-container">
      <Grid item xs={11} sm={11} className="tag-textfield-editable-item">
        <Tooltip title={tipText} placement="top" arrow>
          <TextField
            className="tag-textfield-editable-input"
            label={props.label}
            size="small"
            variant="outlined"
            value={textValue}
            placeholder={props.prompt}
            onChange={handleChange}
            inputProps={{
              style: {
                height: 40             
              },
            }}
            style={styles.formControlNoColor} 
            InputProps={{
              classes: {
                root: "tag-textfield-editable-input-root"
              }
            }}
          />
        </Tooltip>        
      </Grid>
      <Grid item xs={1} sm={1} className="tag-textfield-editable-icon">
        <CustomIconButton
          icon={props.icon || "ClearIcon"}
          tooltip={props.iconTooltip || "Clear"}
          onClick={clearText}
          color={props.color}
          className="tag-textfield-editable-custom-icon"
        />
      </Grid>
    </Grid>
  );  
};

export default TagTextFielsEditable;
