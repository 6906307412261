import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { Tooltip,IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ClearIcon from '@mui/icons-material/Clear';
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Selection,
  Pager,
  Paging
} from "devextreme-react/data-grid";
import NoticeEmitter from '../../../../NoticeUtil';

const styles = {
  root: {
    display: 'flex',
  },
  formControlSelect: {
    minWidth: 200,
    paddingRight: 20,
    paddingBottom: 16,
  },
  formControlTextfield: {
    width: 125,
    paddingRight: 20,
    paddingBottom: 16,
  },
  formControlIconfield: {
    width: 80,
    paddingRight: 20,
    paddingBottom: 16,
  }
};

function DefineIndividualPieces(props) {
  
  const [piecesOptionList, setPiecesOptionList] = useState([]);
  const [piecesOption, setPiecesOption] = useState(
    {
      length:'',
      height:'',
      width:'',
      weight:'',
      positions:'',
      description:''
    });
 

  useEffect(() => {
    console.log("piecesOptionList updated- " + JSON.stringify(piecesOptionList));
    props.setDefineIndividual(piecesOptionList)
  },[piecesOptionList]);

 
   //const selectedOption = piecesOptionList.find(e => e.id === id);

    const handleChange = (event) => {
        setPiecesOption((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,        
            };
        });
    };

    const handleAddOption = (event) => {
        console.log("handleAddOption handleAddOption- " + JSON.stringify(piecesOption));
        if(piecesOptionList.length>0){
            var maxObj = piecesOptionList.reduce(function(max, obj) {
                return obj.id > max.id? obj : max;
            });
            const newOption = {...piecesOption, id: maxObj.id+1}           
 
            setPiecesOptionList(prevLista =>{
                return prevLista.concat(newOption)
            })
        }
        else{
            const newOption = {...piecesOption, id: 1}
            setPiecesOptionList(prevLista =>{
                return prevLista.concat(newOption)
            })
        }
        setPiecesOption((prevState) => {
            return {
                length:'',
                height:'',
                width:'',
                weight:'',
                positions:'',
                description:''    
            };
        });
        setTimeout(() => {  NoticeEmitter.emit('PackagePiecesUpdate', 'add'); }, 500);   
       // NoticeEmitter.emit('PackagePiecesUpdate', 'add');    
       
    };

    const renderDelete = params => {
      return(
        <IconButton  
            color="primary"             
            onClick = {()=>{
               var option = params.data;
               console.log("renderDelete- " + JSON.stringify(option));
               const newArr = piecesOptionList.filter(object => object.id !== option.id);
               //setPiecesOption(newArr)
               setPiecesOptionList(prevLista =>{
                return prevLista.filter(object => object.id !== option.id)
               })
               setTimeout(() => {  NoticeEmitter.emit('PackagePiecesUpdate', 'minus'); }, 500); 
               //NoticeEmitter.emit('PackagePiecesUpdate', 'minus');              
           }}>   
           <ClearIcon style={{ fontSize: 12 }}/>  
        </IconButton>
      )
    }

    const renderInputField =(label, name, value, handleOnChange, type,adorment, adormentPosition, adormentLebel) =>{
      return(
        <FormControl style={styles.formControlTextfield}>
        <InputLabel>{label}</InputLabel>
        <Input
          name={name}     
          value={value}   
          onBlur={handleOnChange}
          onChange={handleOnChange}
          type= {type}
          endAdornment={
            adorment?<InputAdornment position={adormentPosition}>
              {adormentLebel}
            </InputAdornment> 
            : <></>
          }
        />
      </FormControl>
      )
    }
   
  //packageType
  return (
    <Tooltip title="Define Individual Pieces" placement="top" arrow>
    <div>
      <div style={{ paddingLeft: '60px',paddingTop:'10px' }}>
         <Typography variant = 'h6' color="primary">Define Individual Pieces (Optional)</Typography>
      </div>
      <div style={{paddingLeft: '25px',paddingTop:'10px' }}>
        {/* <FormControl>
        <Typography style={{fontSize: 20, fontWeight: 600, marginLeft: 10,marginRight:10}} color="primary">Define Individual Pieces (Optional):</Typography>
      </FormControl>      */}
      <FormControl style={styles.formControlIconfield}>
        <IconButton   onClick = {()=>{handleAddOption()}}>   <AddBoxIcon/>  </IconButton>
      </FormControl>
      {renderInputField("length","length",piecesOption.length,handleChange,"number",true,"end","Inches")}
      {renderInputField("width","width",piecesOption.width,handleChange,"number",true,"end","Inches")}
      {renderInputField("height","height",piecesOption.height,handleChange,"number",true,"end","Inches")}
      {renderInputField("weight","weight",piecesOption.weight,handleChange,"number",true,"end","lbs")}
      {renderInputField("skid positions", "positions",piecesOption.positions,handleChange,"number",true,"start","#")}
      {renderInputField("piece description","description",piecesOption.description,handleChange,"text",false,null,null)}      
      </div>
      <div style={{ paddingLeft: '80px',paddingTop:'10px' }}>
        {piecesOptionList.length>0 &&<DataGrid
            dataSource={piecesOptionList}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            keyExpr="id"
            rowAlternationEnabled={true} 
        >                    
            <Paging defaultPageSize={10} />
            <Pager
                visible={true}
                showInfo={true}
                infoText="Page #{0}. Total: {1} ({2} items)"  
                displayMode="full"
            />
            <Selection  mode="single"/>

            <Column dataField="id" caption="ID" alignment="center" width={100} visible={false}/>
            <Column dataField="length" caption="Length" alignment="center" width={120}/> 
            <Column dataField="height" caption="Height" alignment="center" width={120}/> 
            <Column dataField="width" caption="Width" alignment="center" width={120}/> 
            <Column dataField="weight" caption="Weight" alignment="center" width={120}/> 
            <Column dataField="positions" caption="Positions"  width={200}/> 
            <Column dataField="description" caption="Description"  width={200}/>                   
            <Column dataField="id" caption="Delete" width={100} alignment="center" cellRender={renderDelete}/>
        </DataGrid> }
        </div>
    </div>
    </Tooltip>    
  );
}

export default DefineIndividualPieces;