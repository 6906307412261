import React, { useState, useEffect } from 'react';
import { format, parseISO } from "date-fns";
import { useNavigate } from 'react-router-dom';
import OrdersDevExpressDataGrid from '../components/OrdersDevExpressDataGrid';

export default function HistoryOrdersList(props){
  const navigate = useNavigate();     
  const [orders, setOrders] = useState([]);    

  const locationToString = new Map(
      [
      [-1, "All"],
      [1, "At Client"],
      [2, "On Driver"],
      [4, "In Sort"],
      [5, "Rotation Sort"],
      [8, "Delivered"]]
  );
  const statusToString = new Map(
    [
      [-1, "All"],
      [1, "New/Review"],
      [2, "Avail for Dispatch"],
      [3, "Driver Pending"],
      [4, "Driver Responsbility"],
      [8, "Delivered"],
      [16, "Verified"],
      [32, "Invoiced"]
    ]
  );
   
  useEffect(() => {      
    console.log("OrderList useEffect- getOrders" + props.account);  
    const dateFormat = 'yy-MM-dd HH:mm'
    const fixedData = (props.orders.map((trip,index) => (
      {
        ...trip,
        ['id']:index,
        ['Status']: statusToString.get(trip.Status),
        ['location']: locationToString.get(trip.location),
        ['dtDCT_UTC']: !trip.drDCT_UTC ? null : format(parseISO(trip.dtDCT_UCT), dateFormat),
        ['dtCallIn_UTC']: !trip.dtCallIn_UTC ? null : format(parseISO(trip.dtCallIn_UTC), dateFormat),
        ['dtDelivered_UTC']: !trip.dtDelivered_UTC ? null : format(parseISO(trip.dtDelivered_UTC), dateFormat)
      }
    )))
    setOrders(fixedData)     
  },[props.orders])


    return(
        <OrdersDevExpressDataGrid orders={orders} account={props.account} listType="history"/>       
    )

}