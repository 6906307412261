import "./App.css";

import React, { useState, useEffect } from "react";
import NewOrder from "./newOrder";
import OrderStatus from "./orderStatus/orderStatus";
import OrderDetail from "./orderStatus/orderDetail";
import Invoice from "./invoices/invoices";
import Login from "./login";
import Tracking from "./tracking";
import OrderHistory from "./history";
import TrackingOrderDetail from "./orderStatus/trackingOrderDetail";
import { useUserContext} from "./lib/user_context";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import HttpRequest from "./HttpService";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import NavigationBar from "./NavigationBar";
import WDWebview from "./WDWebview";
import ExtendWeb from "./ExtendWeb";
import Preferences from "./preferences";
import Success from "./checkout/Success";
import Canceled from "./checkout/Canceled";
import axios from "axios";


function App() {
  require("dotenv").config();

  const [loginUser, setLoginUser] = useState({});
  const [loginInfo, setLoginInfo] = useState({});
  const [userAddress, setUserAddress] = useState({});
  const [accountList, setAccountList] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [apiVersion, setAPIVersion] = useState("1.1.0");
  const [webEmbedded, setWebEmbedded] = useState(false);

  const {updateToken,updateAccount,updateLoginUser,updateIsEmbedded,updatePreviousOrder,updateAccountAddress,updateIPAddress} = useUserContext();
  const { sessionToken,sessionAccount,sessionLoginUser,sessionLoginInfo,sessionAccountAddress, sessionIP, previousOrder, isEmbedded,} = useUserContext();

  useEffect(() => {
    console.log("App sessionToken is", sessionToken);
  },[sessionToken]);

  useEffect(() => {
    console.log("App sessionAccount is", sessionAccount);
  },[sessionAccount]);

  useEffect(() => {
    console.log("App sessionAccountAddress is", sessionAccountAddress);
    setUserAddress(sessionAccountAddress);
  },[sessionAccountAddress]);

  useEffect(() => {
    console.log("App sessionLoginInfo is", sessionLoginInfo);
    setLoginInfo(sessionLoginInfo);
  },[sessionLoginInfo]);

  useEffect(() => {
    console.log("App isEmbedded is", isEmbedded);
    setWebEmbedded(isEmbedded)
  },[isEmbedded]);

  useEffect(() => {
    console.log("App sessionLoginUser is", sessionLoginUser);
    if(sessionLoginUser !== null){
      setLoginUser(sessionLoginUser);
      localStorage.setItem("accountNumber", sessionLoginUser.accountNumber);
      setIsAuthenticated(true);
          let groupID = sessionLoginUser.groupID;
          console.log("sessionLoginUser groupID --" + groupID);
          if (groupID !== null) {
            HttpRequest.getGroupAccounts(groupID).then(async function (body) {
              console.log("getGroupAccounts:" + JSON.stringify(body.data));
              let accounts =  body.data.map((person,i) => {
                return {id:i, value: person.account,label: person.accountName};
              });  
              setAccountList(accounts);  
            });
          }
          else{
            setAccountList([{id:1,value:sessionLoginUser.accountNumber,label:sessionLoginUser.accountNumber}]); 
          }
    }
  },[sessionLoginUser]);

  useEffect(() => {
    console.log("App sessionLoginUser is", sessionAccountAddress);
  },[sessionAccountAddress]);
  
  useEffect(() => {
    let token = "";
    console.log("App wondow token is", window.token);
    console.log("App url is", window.location.href);

    token = window.location.href.split("sso_token=")[1];
    console.log("App token", token);
     
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Router>          
          <NavigationBar isLoggedIn={isAuthenticated} />
          <Routes>
            <Route path="/"  element={!isAuthenticated ? <Login/> 
              : <NewOrder accountList={accountList} loginInfo={loginInfo}  account={loginUser.accountNumber}/> }>
            </Route>  
            <Route path="/neworder" element={             
              <NewOrder accountList={accountList} loginInfo={loginInfo} account={loginUser.accountNumber}/>}>             
            </Route>
            <Route path="/orderstatus"  element={
              <OrderStatus account={loginUser.accountNumber} isEmbedded={webEmbedded}/>}>
            </Route>
            <Route path="/orderDetail/:orderID/:account"  element={
              <OrderDetail variant="orderStatus" account={loginUser.accountNumber} isEmbedded={webEmbedded}/>}>
            </Route>
            <Route  path="/trackOrderDetail/:orderID/:account" element={
              <OrderDetail variant="orderTrack" account={loginUser.accountNumber} isEmbedded={webEmbedded}/>}>
            </Route>    
            <Route path="/invoices" element={<Invoice  account={loginUser.accountNumber} isEmbedded={webEmbedded}/>} /> 
            <Route path="/history" element={<OrderHistory isEmbedded={webEmbedded}/>} />         
            <Route path="/faq" element={<WDWebview url="https://www.directitgroup.ca/faq" isEmbedded={webEmbedded} />}/>           
            <Route path="/web/:webtype" element={<ExtendWeb isEmbedded={webEmbedded}/>}/>         
            <Route path="/preferences" element={<Preferences isEmbedded={webEmbedded} address={userAddress}/>} />         
            <Route path="/tracking" element={<Tracking type="none" isEmbedded={webEmbedded}/>} />  
            <Route path="/trackNo/:trackingNumber" element={<TrackingOrderDetail trakingType="trackingNumber"/>} />
            <Route exact path="/TrackAccount/:trackingAccount/:trackingReference"
                elelment={<TrackingOrderDetail trakingType="reference"/>} />
            <Route path="/success" element={<Success />}/> 
            <Route path="/canceled" element={<Canceled />}/>
            {/*
            <Route>
              <div>Page not found!</div>
            </Route> */}
          </Routes>         
        </Router>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;

