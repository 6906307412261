import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import TagSelects from "./TagSelects";
import Tags from "./Tags";
import "animate.css/animate.css";
import httpRequest from "../../../HttpService";
import './styles/index.css';


const Tag = (props) => {  
   
  //const [alertMsg, setAlertMsg] = (new Map());
  useEffect(() => {
    //if(props.info[2]) console.log("get tags", JSON.stringify(props.info[2]));
    props.cleanData();
    props.onAlert({"target":"Initial", "value":false});
  }, [props.info[2].weight,props.info[2].pieces]);


  return (
    <Grid
      container
      direction="column"
      justifyContent="left"
      spacing={3}
      className="tag-container" // Added class for the main container
      //style={{ paddingLeft: "5%", paddingRight: "5%" }}
    >
      {/* <Grid item xs={12} sm={12} md={12} lg={12}>
        <TagSelects
          onAlert={props.onAlert}
          onChange={props.onChange}
          pieces={props.info[2].pieces}
          weight={props.info[2].weight}
        />
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} className="tags-grid"> {/* Added class for the grid item */}
        <Tags
          completed={props.completed.get(2)}
          quoteId={props.info[0].quoteId}
          onAlert={props.onAlert}
          onChange={props.onChange}
          nextPanel={props.onNextPanel}
          info={props.info}
          setCompletedStep={props.setCompletedStep}
          className="tags-component" // Added class for the Tags component
        />
      </Grid>
    </Grid>
  );
};

export default Tag;

