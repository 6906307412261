import * as React from "react";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Container,Grid,Typography,IconButton,} from "@material-ui/core";
import Panel from "./whitePanel";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import httpRequest from "../../HttpService";
import {getStatusString,getLocationString,} from "../../newOrder/components/helperFunctions/index.js";
import logo from "../../directitgroupcourier.jpg";
import moment from "moment";
import "../orderDetail.css";

class OrderReceiptPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: localStorage.getItem("accountNumber"),
      activeStep: 0,
      showDeliveryDetails: true,     
      steps: [],
      order: {}          
    };
  }

  componentDidMount = () => {
    console.log("Order detail componentDidMount");  
    this.initOrderDetail();    
    setTimeout(() => {this.props.doPrintReceipt();}, 1000); 
  };

  initOrderDetail = () =>{
    this.setState({order:this.props.order})
    httpRequest.getOrderTrack(this.props.order.jobID).then((response) => {
        console.log("getOrderTrack--" + JSON.stringify(response.data));
        var tempTrackList = response.data.map((option,index) => {
          return{
            ...option,
            id: index
          };
      });
      this.setState({ steps: tempTrackList });
    });    
  }
  
  renderStepContentText(label) {
    const str = " Click Here to Track >>> ";
    return (
      <div>
        <Typography>{label.bigText}</Typography>
        <Typography variant="caption">
          {label.smallText +
            moment(label.timestamp_utc).format("MM-DD") +
            " @ " +
            moment(label.timestamp_utc).format("LT")}
          ;
        </Typography>
           {label.img_url && (
            <span>
                <a> {label.img_text} </a>
                <div>
                   <img style={{ width: "80px", height: "60px" }} src={label.img_url}/>
                </div>
           </span>
        )}
      </div>
    );
  }

  renderFromAddress() {
    const suite = this.state.order.sSuite? `${this.state.order.sSuite}, `:"";
    return (
      `${suite} ${this.state.order.sAddress}  ${this.state.order.sQuad}, ${this.state.order.sCity}, ${this.state.order.sProv}, ${this.state.order.sPostal}, ${this.state.order.sCountry}`
    );    
  }

  renderToAddress() {
    const suite = this.state.order.cSuite? `${this.state.order.cSuite}, `:"";
    return (
      `${suite}${this.state.order.cAddress}  ${this.state.order.cQuad}, ${this.state.order.cCity}, ${this.state.order.cProv}, ${this.state.order.cPostal}, ${this.state.order.cCountry}`
    );
  }

  renderDeliveryDetailslField =(label, value,size) =>{
    return(
      <Grid item xs={12} sm={size}>
          <Typography style={{ fontWeight: 600, fontSize:14}}>{label}</Typography>
          <Typography style={{ fontWeight: 200,  fontSize:13}}>{value}</Typography>
      </Grid>
    )
  }

  renderDeliveryDetails() {
    return (
      <Grid container spacing={1}>
        {this.renderDeliveryDetailslField("LOCATION",getLocationString(this.state.order.location),4)}
        {this.renderDeliveryDetailslField("BILLING REFERENCE #",this.state.order.CUST_REF,4)}
        {this.renderDeliveryDetailslField("YOUR NAME & PHONE",this.state.order.CALLER,4)}
        {this.renderDeliveryDetailslField("CALL IN",`${this.state.order.dtCallIn_display} ${this.state.order.timezone}`,6)}
        {this.renderDeliveryDetailslField("DUE BY",`${this.state.order.dtDCT_display} ${this.state.order.timezone}`,6)}
        {this.renderDeliveryDetailslField("DELIVERED",getStatusString(this.state.order.Status),4)}
        {this.renderDeliveryDetailslField("SERVICE MINUTES",this.state.order.SER_TIME,4)}
        {this.renderDeliveryDetailslField("RATE(BEFORE TAX)",` $${this.state.order.RATE}`,4)}
        {this.renderDeliveryDetailslField("PROOF OF DELIVERY",this.state.order.PROOF_DEL,4)}
        {this.renderDeliveryDetailslField("GST",this.state.order.GST_RATE,6)}        
      </Grid>
    );
  }

  renderOrderDetailslField =(label, value, size1, size2) =>{
    return(
      <Grid item xs={size1} md={size2}>
        <Typography style={{ fontWeight: 600, fontSize:14 }}>{label}</Typography>
        <Typography style={{ fontWeight: 200, fontSize:13}}>{value}</Typography>
      </Grid>
    )
  }

  render() {
    return (
        <Container maxWidth="false" bgcolor = "#ffffff">
        {/* <PageBanner title="PARCEL STATUS" subheading={"#" + this.state.id} /> */}
            <Grid container style={{marginLeft:'5px', marginTop:'5px'}}>              
              <Grid item xs={12}>
                <Panel>
                  <Grid item container xs={12} style={{alignItems:'center', justifyContent:'center', marginBottom:'5px'}}><Typography  variant="h5"> Order# {this.props.order.jobID}</Typography></Grid>
                   <Grid container>
                    <Grid item container xs={12} lg={4} xl={2} style={{display:'flex', alignItems:'stretch', justifyContent:'stretch'}}>
                      <Grid container className="border-mobile-bottom">
                        <Grid item xs={6} style={{display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center'}}>                        
                          <Typography style={{ fontWeight: 600, fontSize:14 }}>Status</Typography>
                          <h3>{getStatusString(this.state.order.Status)}</h3>
                        </Grid>
                        <Grid item xs={6} className="text-mobile-end" style={{display:'flex', flexDirection:'column', alignItems:'stretch', justifyContent:'center'}}>                          
                          <Typography style={{ fontWeight: 600, fontSize:14 }}>Tracking Number</Typography>
                          {this.state.order.TrackNumber && (
                            <h3>
                              <span>{this.state.order.TrackNumber}</span>{" "}                           
                            </h3>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8} xl={10}>
                      <Stepper  activeStep={this.state.activeStep} alternativeLabel>
                        {this.state.steps.map((label) => (
                          <Step key={label.bigText} completed={label.filled}>
                            <StepLabel>
                              {this.renderStepContentText(label)}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>
              <Grid item xs={12}>
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <div className="d-sm-flex">
                        <div className="pe-sm-4">
                          <img  src={logo} alt="Direct-IT Group Courier"
                            style={{ width: "140px", display:"block", marginBottom: "20px" }}
                          /> 
                        </div>
                        <div className="flex-fill">                         
                          <Typography style={{ fontWeight: 600, fontSize:14 }}>FROM</Typography>
                          <Typography style={{ fontWeight: 200, fontSize:13 }}>{this.state.order.sCompanyName}</Typography>
                          <Typography style={{ fontWeight: 200, fontSize:13 }}>{this.renderFromAddress()}</Typography>
                          <Typography style={{ fontWeight: 600, fontSize:14 }}>TO</Typography>
                          <Typography style={{ fontWeight: 200, fontSize:13 }}>{this.state.order.cCompanyName}</Typography>
                          <Typography style={{ fontWeight: 200, fontSize:13 }}>{this.renderToAddress()}</Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={1} style={{paddingTop:"0px"}}>
                        {this.renderOrderDetailslField("Pieces",this.state.order.PIECES, 4,3 )}
                        {this.renderOrderDetailslField("Weight",`${this.state.order.WEIGHT} lbs`, 4,3 )}
                        {this.renderOrderDetailslField("Service Type",this.state.order.SER_AREA, 4,3 )}
                        {this.renderOrderDetailslField("Instructions",this.state.order.DEL_INST, 12,6 )}                    
                        <Grid item xs={12} md={6}>                         
                          <Typography style={{ fontWeight: 600, fontSize:14 }}>Signature</Typography>
                          {this.state.order.signatureURL && (
                            <img
                              src={this.state.order.signatureURL}
                              alt="Customer Signature"
                              style={{ display:"block", width:"100%", maxWidth:"320px",maxHeight:"40px" }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <List>
                            <ListItem onClick={this.handleClick} style={{ padding: 0 }}>
                              <ListItemText
                                disableTypography
                                primary={                                  
                                  <Typography style={{ fontWeight: 600, fontSize:15 }}>Delivery Details</Typography>
                                }
                              />
                              {this.state.showDeliveryDetails ? (
                                <ExpandLess style={{ color: "#337AB7" }} />
                              ) : (
                                <ExpandMore style={{ color: "#337AB7" }} />
                              )}
                            </ListItem>
                            <Collapse
                              in={this.state.showDeliveryDetails}
                              timeout="auto"
                              unmountOnExit
                            >
                              {this.renderDeliveryDetails()}
                            </Collapse>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>              
            </Grid>  
      </Container>
    );
  }
}

export default OrderReceiptPanel;
