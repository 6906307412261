import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Button, Tooltip, Box} from '@material-ui/core';
import KeyIcon from '@material-ui/icons/VpnKey';
import TextInput from '../newOrder/components/inputFields/TextInput';
import './Login.css';
import httpRequest from '../HttpService';
import 'animate.css/animate.css';
import {Alert, AlertTitle} from '@material-ui/lab'
import Copyright from '../Copyright';


export default function Login()  { 
    const [state, setState] = useState({
        accountNumber:"",
        email:"",
        user_name:"",
        loginURL:"",
        successinfo:"none"
    });
    
    //this.handleGenerateKeyRequired = this.handleGenerateKeyRequired.bind(this);  

    const handleGenerateKeyRequired = (event) => {
           console.log("generate key-" + JSON.stringify(state));
           httpRequest.generateLoginToken(state).then((body) =>{
                console.log("generate key return-" + JSON.stringify(body));
                setState((prevState) => {
                    return {
                      ...prevState,
                      successinfo:"block"
                    };
                }) 
           });
    }

    const handleChange = (event) => {
        console.log(event.target.name, event.target.value);
        const { name, value } = event.target;
        setState((prevState) => {
            return {
              ...prevState,
              [name]: value
            };
        })    
    };


 
        return (
            <Container  class="Login">
            <div class={"login-wrapper"}>
            <div className={"login-wrapper-inner"}>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
                <Grid item md={12}>
                    <h2 class={"text-primary text-uppercase title-small"}> Client Login </h2>
                </Grid>
                <Grid item md={12} style={{width:"100%"}}>
                    <TextInput
                      class={"labeledField"}
                      label = "Client Account"
                      type="text"
                      inputType="text"
                      name="accountNumber"                      
                      labelSize = {5}    
                      value={state.accountNumber}     
                      onChange={handleChange}           
                    />
                </Grid>
                <Grid item md={12} style={{width:"100%"}}>
                    <TextInput
                      class={"labeledField"}
                      label = "Your Email"
                      type="text"
                      inputType="text"
                      name="email"
                      labelSize = {5} 
                      value={state.email}  
                      onChange={handleChange}                        
                    />
                </Grid>
                <Grid item md={12} style={{width:"100%"}}>
                    <TextInput
                      class={"labeledField"}
                      label = "Your Name"
                      type="text"
                      inputType="text"
                      name="user_name"
                      labelSize = {5} 
                      value={state.user_name} 
                      onChange={handleChange}                         
                    />
                </Grid> 
                <Grid  item md={12} style={{width:"260px", textAlign:"center"}}>
                        <Button
                           style={{width:"100%", display:'flex', justifyContent:'space-between', marginTop:'20px'}}
                           variant="contained"                           
                           color="primary" 
                           endIcon={<KeyIcon style={{verticalAlign: 'middle', transform: 'scale(1)'}}/>}    
                           disabled={(state.accountNumber === "") && (state.email==="")}                   
                           onClick={handleGenerateKeyRequired}
                         >
                         Generate Token
                         </Button>
                </Grid>  

                <Grid item md={12} style={{marginTop:"100px"}}>
                    <Box display={state.successinfo}>
                    <Alert severity="success">
                    <AlertTitle>Check Your Email </AlertTitle>
                    <strong>
                    Hi {state.email}, you are all set,click on the login link in the email.<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*email will arrive from directitgroup.ca.
                    </strong>
                    <div>
                       <h4>Did you also know?</h4>
                       <ul>
                       <li>You can use the link on any type of device, Photo, Tablet, PC.</li>
                       <li>We recommend saving the link(token) for future use. See our video below.</li>
                       <li>Any previous links generated will no longer work.</li>
                       <li>You can now close this page.</li>
                       </ul>
                   </div>
                   </Alert>
                   </Box>
                </Grid>   
            </Grid>
            </div>
            </div>
            {/* Footer */}
            <footer  style={{float:"center"}}>
                <Copyright/>
            </footer>
            {/* End footer */}
            </Container>
        );  
}


