import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Container } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummaryHead from "./components/AccordionSummaryHead";
import DropdownBox from "./components/DropdownBox";
import PickUpandDeliveryLocations from "./components/PickUpandDeliveryLocations/";
import PackageOptionsandDetails from "./components/PackageOptionsandDetails/";
import Tag from "./components/Tags/"
import DeliveryTime from "./components/DeliveryTime/";
import Review from "./components/Review/";
import Finalview from "./components/Finalview/";
import CustomDialog from "./components/dialog/Dialog";
import Copyright from "../Copyright";
import JSPM from "jsprintmanager";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import {pad2} from "./components/helperFunctions/index.js";
import "./NewOrder.css";
import "animate.css/animate.css";
import "react-circular-progressbar/dist/styles.css";
import CountdownTimer from "./components/CountdownTimer.js";


const accordionStructure= {
  1: {title: "Pick Up and Delivery Locations",},
  2: {title: "Shipment Description",},
  3: {title: "Tags",},
  4: {title: "Delivery Time",},
  5: {title: "Review",},
  6: {title: "Order Number and Print Label",}
}

const accordionNum = Object.keys(accordionStructure).length;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px 0px 1px 0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));


class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: 0,
      info: Array.from({length:accordionNum+1}, () => new Object()),
      alert: Array.from({length:accordionNum+1}, () => new Set().add("Initial")),
      completed: new Map(Array.from({length:accordionNum}, (_,i) => [i+1, false])),
     // accountNumber: localStorage.getItem("current_accountNumber") !== "" ? localStorage.getItem("current_accountNumber"): localStorage.getItem("accountNumber"),
      accountNumber: this.props.account? this.props.account : localStorage.getItem("accountNumber"),
      accountList: this.props.accountList,
      props: [],
      openHideDialog: false,
      openChangePickupDialog: false,
      openRequiredFieldDialog: false,
      countTimerStatus:0,
      duration: -1,
      durationText: "",
      intervalId: 0,
    };
    console.log("info",  Array.from({length:accordionNum+1}, () => new Object()));
    console.log("alert", Array.from({length:accordionNum+1}, () => new Set().add("Initial")));
    console.log("completed", new Map(Array.from({length:accordionNum}, (_,i) => [i+1, false])));
    console.log("alert[0] size is "  + this.state.alert[0].size);
  }
  

   /*
   alert:
   (7) [Set(1), Set(1), Set(1), Set(1), Set(1), Set(1), Set(1)]
   0: Set(1) {'Initial'} 1: Set(1) {'Initial'} 2: Set(1) {'Initial'} 3: Set(1) {'Initial'}
   4: Set(1) {'Initial'}  5: Set(1) {'Initial'} 6: Set(1) {'Initial'}
    completed
    Map(7) {1 => false, 2 => false, 3 => false, 4 => false, 5 => false, …}
   */

  getInfo = () => {
    return this.state.info;
  }

  componentDidMount = () => {
    if (this.state.accountNumber === null || this.state.accountNumber === "")
      window.location.href = "/";
    this.setState({ accountList: this.props.accountList });
    this.setState({ props: [{}, {
      "component": PickUpandDeliveryLocations,
      "props": {
        "info": this.state.info,
      }
    }, {
      "component": PackageOptionsandDetails,
      "props": {
        "info": this.state.info,
      }
    }, {
      "component": Tag,
      "props": {
        "info": this.state.info,
      }
    }, {
      "component": DeliveryTime,
      "props": {
        "info": this.state.info,
      }
    }, {
      "component": Review,
      "props": {
        "info": this.state.info,      
        "newOrderPanel": this.newOrderPanel,
      }
    }, {
      "component": Finalview,
      "props": {
        "info": this.state.info,  
        "newOrderPanel": this.newOrderPanel, 
        "accountNumber":this.state.accountNumber,      
      }
    }] });
    /*try {      
      JSPM.JSPrintManager.license_url = "https://webxapi.azurewebsites.net/jspm";
      JSPM.JSPrintManager.auto_reconnect = false;
      JSPM.JSPrintManager.start();

      JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrintersInfo().then(async (printersList) => {
            let printers =  printersList.map((item,i) => {
              return {value: i, label: item.name};
            });
            this.setState({ printsList: printers});
            console.log("Printer list :" + JSON.stringify(printers));

            var defaultPrinter = printers.find(function (obj) {
              return obj.label.includes("ZDesigner");
            });
            if (defaultPrinter === undefined){              
              this.setState({ selectPrinter: printers[0].label });          
            }
            else {
              //this.setState({ selectPrinter: defaultPrinter.label });
              var index = printers.findIndex(x => x.label ===defaultPrinter.label);  
              this.setState({ selectPrinter: printers[index].label });            
            }
            console.log("defaultPrinter :" + this.state.selectPrinter);
          });
        }
      };
    } catch (err) {
      console.log("componentDidMount --- exception", err);
    }*/
  };

  changePanel = (panel) => (event, isExpanded, isReset) => {
    //console.log("changePanel completed array", Array.from(this.state.completed));  
    console.log(`changePanel panel - ${panel } `);
    let expanded;
    if (typeof this.state.expanded == "object") {
      console.log("changePanel typeof this.state.expanded is object " + this.state.expanded[0]);
      expanded = this.state.expanded[0];
    } else {
      expanded = this.state.expanded;
    }
    console.log(`changePanel  start expanded -  ${expanded} - changePanel isExpanded - ${isExpanded}`);

    const alert = this.state.alert[expanded];
    console.log(alert);

    if (isExpanded && alert.size === 0 && panel <= Array.from(this.state.completed).find(i => !i[1])[0]) {
      this.setState({ expanded: panel });
      this.state.completed.set(expanded, true);    
      this.setState({ completed: this.state.completed });  
      console.log("changePanel",`set completed  key -  ${this.state.expanded} -true`);
    }

    if (!isExpanded && isReset) {
      this.setState({ expanded: 0 });
    }
    else if (!isExpanded && panel === expanded) {   ////set expaned as onject?  current expanded save as array//////
      this.setState({ expanded: [panel] });
    }

    if (isExpanded && panel === expanded) {
      this.setState({ expanded: panel });
    }

    console.log("changePanel ", `this.state.completed.get ${panel} is  ${this.state.completed.get(panel)}`);
    if (isExpanded && this.state.completed.get(panel)) {
     this.setState({ expanded: panel });
    }  
    console.log(`changePanel  end expanded is -  ${expanded}`);
   
  };

  openDialog = (name) => {
    this.setState({ [name]: true });
  };

  closeDialog = (name) => {
    this.setState({ [name]: false });
  };

   //0 - expired  1- reset  2- completed order
  updateCountTimerStatus = (val) => {
    this.setState({countTimerStatus: val });
    if(val===0){
      this.changePanel(4)(false, true, false);
      this.state.completed.set(4, false);
      this.state.completed.set(5, false);
      this.setState({ completed: this.state.completed });
    }
  };

  nextPanel = () => {
    const panel = this.state.expanded + 1;
    const alert = this.state.alert[this.state.expanded];
    console.log(`nextPanel is ${panel} alert size is ${alert.size}`);
    if (alert.size === 0) {      
      this.state.completed.set(this.state.expanded, true);
      this.setState({ completed: this.state.completed });
      this.setState({ expanded: panel });
      console.log(`nextPanel set completed key - ${this.state.expanded} -true`);
    } else {
      let alertMsg = "Please select or fill ";
      alert.forEach(i => alertMsg += i + ", ");
      this.setState({
        requiredFieldMsg: alertMsg.slice(0,-2),
      });
      this.setState({ openRequiredFieldDialog: true });
    }
  };

  setCompletedStep = (step) =>{
    const completed = this.state.completed;
    for (let i= step; i < 7; i++) 
      completed.set(i, false);    
    this.setState({completed,completed});   
  }

  newOrderPanel = () => {    
    const info = this.state.info;
    for (let i= 1; i < 6; i++) 
      info[i]= new Object();     
    this.setState({info,info});

    const alert = this.state.alert;
    for (let i= 1; i < 7; i++) 
      alert[i]= new Set();      
    this.setState({alert,alert});

    const completed = this.state.completed;
    for (let i= 1; i < 7; i++) 
      completed.set(i, false);    
    this.setState({completed,completed});       
   
    this.setState({ expanded: 1 });
    //window.location.reload();
  };

  cleanPanelData = (key) => () => {
    const info = this.state.info;
    info[key] = new Object();
    this.setState({info,info});
  }

  handleAlert = (key) => (pair) => {
    //console.log("handleAlert: " + pair.target + " =" + pair.value );  
    const alert = this.state.alert;
    if (pair.value) {
      alert[key].add(pair.target);
    } else {
      alert[key].delete(pair.target);
    }
    //console.log(`handleAlert  alert[${key} ] size is -${alert[key].size}`);
    this.setState({ alert });
    if (alert[key].size !== 0) {
      this.state.completed.set(key, false);
      this.setState({ completed: this.state.completed });
      //console.log(`handleAlert set completed  key -  ${key} -false`);
    }
    if (this.state.expanded === 0 && alert[0].size === 0) {
      //console.log(`handleAlert changePanel(1) true`);
      this.changePanel(1)(false, true, false);
    }

    ////////////ghy///////   
    //console.log( `key - ${key}  - handleAlert - this.state.expanded  is ${this.state.expanded}`);    
    //console.log("handleAlert completed array", Array.from(this.state.completed));
    if(alert[0].size > 0 && this.state.expanded ===1){
      try{
        console.log("handleAlert ---this.state.expanded" +  this.state.expanded);
        this.setState({extanded:0}); 
        //this.setState({completed:new Map(Array.from({length:accordionNum}, (_,i) => [i+1, false]))});  
        this.state.completed.set(0, false);
        this.setState({ completed: this.state.completed });  
        this.changePanel(1)(false, false,true);
      }
      catch(e){console.log("handleAlert exception",e)};
    }
   
   
  };


 

  handleChange = (key) => (pair) => {
    console.log(`handleChange: key is ${key} ---- ${pair.target} --${pair.value}`);
    if(pair.target === 'timeOption') console.log(JSON.stringify(pair.value));
    try{
      if(key===0)     {
        //this.setCompletedStep(1);
        this.changePanel(1)(false, false,true);
        //this.cleanTimer();
      } 
      const info = this.state.info;
      info[key][pair.target] = pair.value;
      // if(pair.target === 'pickUpLocation' || pair.target.includes("QuoteExecute") ){  // pickUpLocation deliveryLocation
      //   info[1].deliveryLocation ={};
      // } 
      this.setState({ info });
      // if(pair.target === 'quoteID'){
      // }
     
   
      // if(pair.target.includes("QuoteExecute")){
      //   this.cleanTimer();
      // }

      if(pair.target==='nextQuoteID'){
        const info = this.state.info;
        if(info[5].nextQuoteID !== null)
          info[0].quoteId = info[5].nextQuoteID;   
        else{
          info[0].quoteId = null;  
          info[0].caller = null;
          const alert = this.state.alert;    
          alert[0].add("caller"); 
          this.setState ({alert:alert});
        } 
        info[0].nextQuoteID = info[5].nextQuoteID;    
      }

      // if(pair.target==='timeOption')
      //   this.startTimer();     
        
    } 
    catch(e){console.log("handleChange exception",e)}
  };

   
  dynamicComponent = (structure, key) => {
    if (!structure || !structure.component) return;
    const ComponentType = structure.component;
    return <ComponentType {...structure.props} completed={this.state.completed} expanded={this.state.expanded} onChange={this.handleChange(key)} updateCounterStatus={this.updateCountTimerStatus}
            onAlert={this.handleAlert(key)} onNextPanel={this.nextPanel} duration={this.state.duration} setCompletedStep={this.setCompletedStep}
            openDialog={this.openDialog} cleanData={this.cleanPanelData(key)}/>;
  };

  renderAccordion = () => {
    return Object.keys(accordionStructure).map((keys) => {
      const key = parseInt(keys);
      return (
        <Accordion
          key={key}
          expanded={this.state.expanded === key}
          onChange={this.changePanel(key)}
        >
          <AccordionSummary key={key}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <AccordionSummaryHead num={key} complete={this.state.completed.get(key)}/>
              <Typography color="primary">{accordionStructure[key].title}</Typography>
              {key === 4 && <CountdownTimer 
                countTimerStatus={this.state.countTimerStatus}
                currentSection={this.state.expanded}
              />}
            </Box>
          </AccordionSummary>
          <AccordionDetails key={key}>
            {this.dynamicComponent(this.state.props[key], key)}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  render() {
    return (
      <Container maxWidth="lg" class="NewOrder">
       
        {
          this.props.accountList && this.props.accountList.length > 0 ?
            <DropdownBox             
              value={this.state.accountNumber}
              choices={this.props.accountList}
              onChange={this.handleChange(0)}
              onAlert={this.handleAlert(0)}
              companyID={this.props.loginInfo.preferences.companyID}            
              info = {this.state.info}
              setCompletedStep={this.setCompletedStep}
            />
          :
            <p>Loading...</p>
        }
        {this.renderAccordion()}
       
        <CustomDialog
          open={this.state.openChangePickupDialog}
          onClose={() => this.closeDialog("openChangePickupDialog")}
          dialogTitle="Warning"
          dialogMessage="Note you are changing the pickup address."
          dialogAction={() => this.closeDialog("openChangePickupDialog")}
        />
        <CustomDialog
          open={this.state.openHideDialog}
          onClose={() => this.closeDialog("openHideDialog")}
          dialogTitle="Warning"
          dialogMessage="Address will be permanently removed from the list!"
          dialogAction={this.deleteLocation}
        />
        <CustomDialog
          open={this.state.openRequiredFieldDialog}
          onClose={() => this.closeDialog("openRequiredFieldDialog")}
          dialogTitle="Warning"
          dialogMessage={this.state.requiredFieldMsg}
        />
        <footer>
          <Copyright/>
        </footer>
      </Container>
      
    );
  }
}

export default NewOrder;