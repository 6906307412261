import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@mui/material/Tooltip';
import "animate.css/animate.css";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import './styles/TagDatetimeFields.css';


const TagDatetimeField = (props) => {
  const [value, setValue] = useState(dayjs(props.value));
  //const [value, setValue] = useState(dayjs(Date.now()));
  const tipText =  props.prompt?props.prompt:''; 

  useEffect(() => {
    console.log("------TagDatetimeField-----" + props.value);    
  },[props.value]);   


  const handleChange = (newValue) => {
    console.log("TagDatetimeField handleChange-" + JSON.stringify(newValue));
    props.onChange({
      name: props.param,
      value: newValue,
    });
    setValue(newValue);
  };

  return (
    <Grid container className="tag-datetime-field-container">
      <Grid item xs={12} sm={12} className="datetime-picker-item">
        <Tooltip title={tipText} placement="top" arrow>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker 
              fullWidth
              size='small'
              label={props.label}
              value={value}
              onChange={handleChange}
              className="datetime-picker"
            />
          </LocalizationProvider>
        </Tooltip>
      </Grid>
    </Grid>
  );
  
};

export default TagDatetimeField;
