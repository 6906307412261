import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import OrderDetailsBox from './orderDetailsBox';
import moment from 'moment';

const OrderInfo = (props) => {

    const [custRef, setCustRef] = useState("");
    const [intructions, setInstructions] = useState("");

    useEffect(() => {
        /*
        if (props.references.hasOwnProperty('0')) 
            setCustRef(props.references['0']);
        else{
            for(let key in props.references) {
                if(props.references.hasOwnProperty(key)) {
                    setCustRef(props.references[key]);
                    break;
                }
            }
        }    
        */
        // for (let key in props.references) {
        //     if (props.references.hasOwnProperty(key)) {
        //         if(key === '0')
        //           setCustRef(props.references[key]);                              
        //     }
        // }
        setCustRef(props.references.Reference);

    },[props.references])

    useEffect(() => {
            console.log("props.signatureRequired-" + props.signatureRequired);
            console.log("props.pictureRequired-" + props.pictureRequired);
            let inst = props.instructions;
            // if(props.signatureRequired) {
            //     inst = "Signature required " + inst;
            // }
            // if(props.pictureRequired) {
            //     inst = "Picture required " + inst;
            // }
            setInstructions(inst);
    },[props.signatureRequired,props.pictureRequired,props.instructions])        


    function renderButtonImage() {
        // if(props.packageImage){            
        //         var binary = '';
        //         var bytes = new Uint8Array(props.packageImage.data);
        //         var len = bytes.byteLength;
        //         for (var i = 0; i < len; i++) {
        //           binary += String.fromCharCode( bytes[ i ] );
        //         }
        //         const base64String = btoa(binary);
        //         const imageSrc= "data:image/png;base64,"+ base64String;
        //         return imageSrc;
        // }
        if (props.packageImage) return props.packageImage;
    }

    function renderRateImage() {
        if(props.rate.buttonImage){ 
            const base64String = btoa(String.fromCharCode(...new Uint8Array(props.rate.buttonImage.data)));
            const imageSrc= "data:image/svg+xml;base64,"+ base64String;
            return imageSrc;   
        }        
    }

    // function getRate() {
    //     var num = 0;
    //     if(props.rate) {
    //         num += props.rate.Rate;
    //     }
    //     if(props.returnTripService){
    //         num += props.returnTripService.Rate;
    //     }
    //     return +num.toFixed(2);
    // }

    function getRate() {
        var num = 0;
        if(props.rate) {
            num += props.rate.Rate;
        }
        if(props.returnTripService){
            num += props.returnTripService.Rate;
        }
        return   new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CAD' }).format(num)
    }

    function getIntructions() {
            let instructions = props.instructions;
            if(props.signatureRequired) {
                instructions = "Signature required " + instructions;
            }
            if(props.pictureRequired) {
                instructions = "Picture required " + instructions;
            }
            return instructions;
    }


    var callInUTC = moment.utc(props.callIn).toDate();
    var callInLocal = moment(callInUTC).local().format('YYYY-MM-DD HH:mm');

    var dueByUTC = moment.utc(props.dueBy).toDate();
    var dueByLocal = moment(dueByUTC).local().format('YYYY-MM-DD HH:mm');

    return (
        <div>
            <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            style={styles.orderContainer}
            >
                <Grid item sm={4}>
                 <OrderDetailsBox title="Your Name" value={props.name} bold={true}/>
                </Grid>
                <Grid item sm={4}>
                 <OrderDetailsBox title="Call In" value={callInLocal} bold={true}/>
                </Grid>
                <Grid item sm={4}>
                 <OrderDetailsBox title="Due By" value={dueByLocal} bold={true}/>
                </Grid>

                {/* next row */}
                <Grid item sm={4}>
                 <OrderDetailsBox title="From" value={props.fromName} bold={true}/>
                </Grid>
                <Grid item sm={8}>
                 <OrderDetailsBox title="From Address" value={props.fromAddress} bold={true}/>
                </Grid>

                {/* next row */}
                <Grid item sm={4}>
                 <OrderDetailsBox title="To" value={props.toName} bold={true}/>
                </Grid>
                <Grid item sm={8}>
                 <OrderDetailsBox title="To Address" value={props.toAddress} bold={true}/>
                </Grid>
                            
                {/* next row */}
                <Grid item sm={3}>
                 <OrderDetailsBox title="Pieces" value={props.pieces} bold={true}/>
                </Grid>
                <Grid item sm={3}>
                 <OrderDetailsBox title="Weight" value={props.weight + ' lb'} bold={true}/>
                </Grid>
                <Grid item sm={3}>
                   {/* <OrderDetailsBox title="Rate" value={'$'+ getRate()} bold={true}/> */}
                   <OrderDetailsBox title="Rate" value={getRate()} bold={true}/>
                </Grid>
                <Grid item sm={3}>                 
                   {/* <OrderDetailsBox title="Billing Ref#" value={props.references.Reference} bold={true}/> */}
                </Grid>    
                {/* next row */}
                <Grid item sm={2}>                    
                    {/* <img src={renderButtonImage()} style={{width:50, height:50}}/>                       */}
                    <div style={styles.cardTitleRow}>
                            <div style={styles.cardTitleIcon}>
                                <img src={renderButtonImage()} style={{width:50, height:50}}/>
                            </div>
                            <Typography style={{fontSize: 14, fontWeight: 600}}>
                                   {props.packageSize}
                            </Typography>
                    </div>
                </Grid>
                <Grid item sm={2}>
                   {props.rate && props.rate.buttonImage && 
                        <div style={styles.cardTitleRow}>
                            <div style={styles.cardTitleIcon}>
                                <img src={renderRateImage()} style={{width:50, height:50}}/>
                            </div>
                            <Typography style={{fontSize: 14, fontWeight: 600}}>
                                   {props.rate.buttonText1}
                            </Typography>
                        </div>
                   }
                </Grid>
                <Grid item sm={8}>                 
                   {/* <OrderDetailsBox title="Instruction" value={getIntructions()} bold={true}/> */}
                   {/* <OrderDetailsBox title="Instruction" value={intructions} bold={true}/> */}
                </Grid>
            </Grid>
        </div>
    );
};

export default OrderInfo;