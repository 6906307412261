import React, { Component } from 'react';
import { Container} from '@material-ui/core';
import Copyright from './Copyright';
   
export default function WDWebview(props)  { 
       return (
            <Container maxWidth="lg" class="NewOrder">
                <iframe
                    width="100%"
                    height="1150px"
                    allow="microphone *"
                    src={ props.url}
                />   
                 {props.isEmbedded!==null && props.isEmbedded!=="yes" && <footer>
                       <Copyright/>
                </footer>}
            </Container>            
        );
    
}

