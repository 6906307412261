import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@mui/material/Tooltip';
import "animate.css/animate.css";
import './styles/TagDropdown.css';

const TagDropdown = (props) => {
  const [value, setValue] = useState({});
  const tipText =  props.prompt?props.prompt:'';
  useEffect(() => {
    setValue(props.choices.find(obj => obj.label === props.value))
  },[props.choices,props.value]);

  // useEffect(() => {
  //   if (props.required) {
  //     if (value === "" || value === null || value === undefined) {
  //       props.onAlert.addAlert(props.label);
  //     } else {
  //       props.onAlert.removeAlert(props.label);
  //     }
  //   }
  // }, [value]);

  const handleChange = (event, newValue) => {
    console.log("dropdown handleChange-" + JSON.stringify(newValue));
    props.onChange({
      name: props.param,
      value: newValue.label,
    });
    setValue(newValue);
  };

  return (
    <Grid container className="tag-dropdown-container">
      <Grid item xs={12} sm={12}>
        <Tooltip title={tipText} placement="top" arrow>
          <Autocomplete
            value={value}
            onChange={handleChange}
            options={props.choices}
            className="tag-dropdown-autocomplete"
            renderInput={(params) => <TextField {...params} label={props.label} />}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );  
};

export default TagDropdown;
