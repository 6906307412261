import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import stripeRequest from '../Stripe';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import httpRequest from '../HttpService';
import { useUserContext} from "../lib/user_context";

const Success = () => {
    const { sessionAccount} = useUserContext();
    const [session, setSession] = useState({});
    const [invoiceList, setInvoiceList] = useState([]);
    
    const location = useLocation();
    const sessionId = location.search.replace('?session_id=', '');
    //const account = localStorage.getItem('current_accountNumber')!== '' ? localStorage.getItem('current_accountNumber'):localStorage.getItem('accountNumber');
    const account = sessionAccount;

    const [confirmed, setConfirmed] = useState(false);

    
    const str = localStorage.getItem("invoicesToProcess");

    // convert string to valid object
    const arr = str ? JSON.parse(str) : [];
    var sessionInfo = undefined;
    
    // const invoiceList = localStorage.getItem('invoiceList');
    // const paymentAmount = localStorage.getItem('paymentAmount');
    // const paymentIntent = localStorage.getItem('paymentIntent');
    // const checkoutSession = localStorage.getItem('checkoutSession');

    useEffect(() => {
        stripeRequest.checkCheckoutSession(sessionId).then((res) =>{
            const data = res.data;
            setSession(data);
            sessionInfo = arr.find(function (x) {return x.checkoutSession === data.id && x.paymentIntent === data.payment_intent});
            console.log(sessionInfo);
            
            if(sessionInfo === undefined){
                sessionInfo = {};
                sessionInfo.invoiceList = data.metadata.invoiceList;
                sessionInfo.checkoutSession = data.id;
                sessionInfo.paymentIntent = data.payment_intent;
                sessionInfo.paymentAmount = data.amount_total/100;
            }
            setInvoiceList(sessionInfo.invoiceList);

            //confirm the payment is the same as the one stored in local storage
            // if(sessionInfo !== undefined){
                // setInvoiceList(sessionInfo.invoiceList);
                stripeRequest.checkPaymentIntent(data.payment_intent).then((res) =>{

                    const data = res.data;

                    console.log(data);
                    if(data.status === 'succeeded'){
                        setConfirmed(true);
                        //submit to database
                        console.log(invoiceList);
                        // skip section if session not in session to process ie. already processed
                        
                        const sessionQuery = arr.find(function(x) {return x.paymentIntent !== sessionInfo.paymentIntent});
                        if (sessionQuery !== undefined){
                            httpRequest.payInvoices(account, sessionInfo.invoiceList, sessionInfo.paymentAmount, sessionInfo.checkoutSession).then(response => {
                                console.log(response);
                            });
                            clearStorage(sessionInfo.paymentIntent);                     
                        }
                    }
                    if(data.status === 'canceled'){
                        clearStorage(sessionInfo.paymentIntent);
                        window.open('/canceled','_self');
                    }
                    
                })
            // } else{
            //     alert('Checkout session does not match the one in storage')
            // }
        });
    }, [sessionId]);

    function refreshPage() {
        window.location.reload(false);
    }
    function invoicePage() {
        // clearStorage();
        window.open('/invoices','_self');
    }
    function clearStorage(payment_intent) {
        console.log('clearing storage and removing ', payment_intent);
        const newArr = arr.filter(function(x) {return x.paymentIntent !== payment_intent});
        const jsonArr = JSON.stringify(newArr);
        console.log("invoices to process: ", jsonArr);
        // save to localStorage
        localStorage.setItem("invoicesToProcess", jsonArr);

        // localStorage.removeItem('invoiceList');
        // localStorage.removeItem('paymentAmount');
        // localStorage.removeItem('paymentIntent');
        // localStorage.removeItem('checkoutSession');
    }

    return (
        <Container style={{padding: 100}}>
            <Card style={{width: 'min-content', minWidth: 450, margin: '0 auto'}}>
                <CardContent>
                    <Typography variant='h5'>Your payment {confirmed ? 'succeeded' : 'is pending'}</Typography>
                    <br/>
                    <Typography variant='subtitle1'>Checkout Details:</Typography>
                    
                    {confirmed && <Typography>Total Paid: &emsp; &emsp; {(session.amount_subtotal/100).toFixed(2)}</Typography>}
                    {confirmed && <Typography>Invoice List: &emsp; &ensp; {invoiceList.toString()}</Typography>}
                    {confirmed && <Typography>Email Address: &ensp; {session.customer_details?.email}</Typography>}
                    
                </CardContent>
                <CardActions>
                    {confirmed && <Button variant='outlined' color='primary' onClick={invoicePage} style={{margin: '0 auto'}}>Return</Button>}
                    {!confirmed && <Button variant='outlined' color='primary' onClick={refreshPage} style={{margin: '0 auto'}}>Refresh</Button>}
                </CardActions>
            </Card>
        </Container>
    );
};

export default Success;
