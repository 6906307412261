import React, { useState, useRef,useEffect,useCallback } from "react";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {Column,Selection,FilterRow,Paging,Editing,ColumnChooser,Pager,allowedPageSizes,SearchPanel,GroupPanel, Grouping, SortByGroupSummaryInfo, Summary,StateStoring, Lookup,} from "devextreme-react/data-grid";
import {HeaderFilter,FilterPanel,FilterBuilderPopup,Export} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DownloadIcon from "@material-ui/icons/SaveAlt";
import IconButton from "@material-ui/core/IconButton";
import httpRequest from "../HttpService";
import moment from "moment";
import { useUserContext} from "../lib/user_context";

export default function InvoicesListDatagrid(props) {
  const { sessionAccount} = useUserContext();
  const [invoices, setInvoices] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const dataTable = useRef(null);


  const getVoiceList = useCallback(async (sessionAccount) => {
    try {
        const invList = [];
        httpRequest.getInvoices(sessionAccount).then(async (body) => {
          console.log("getInvoices return-" + JSON.stringify(body.data[0]));
    
          body.data.forEach((value) => {
            value.id = invList.length;
            //value.dtGenerated = moment(value.dtGenerated).format('MMM DD,YYYY');
            value.dtGenerated = moment(value.dtGenerated).format("YYYY-MM-DD");
            value.inv_date = moment(value.inv_date).format("YYYY-MM-DD");
            value.selected = false;
            // Add invOwing if non exists
            // if(value.dtGenerated < '2021-09-09'){
            //     value.invOwing = value.invTotalDue;
            // } else {
            //     value.invOwing = 0;
            // }
            invList.push(value);
          });
    
          invList.sort(dynamicSort_reverse("dtGenerated"));
          console.log("done sorting");
    
          setInvoices(invList);
          const owing = invList.slice(0, 100).filter((value) => value.invOwing > 0);
          /*
            props.totalOutstanding.paymentAmount = owing.length ? owing.reduce((prev, curr) => prev.invOwing + curr.invOwing) : 0;
            props.totalOutstanding.paymentAmount = props.totalOutstanding.paymentAmount.toFixed(2);
            owing.forEach((value) => {
                props.totalOutstanding.invoiceList.push(value.invoiceNumber);
            });
            document.getElementById('totalbalance').innerHTML = `Total outstanding balance: ${props.totalOutstanding.paymentAmount}`;
            */
          let totalAmount = owing.length
            ? owing.reduce((prev, curr) => prev.invOwing + curr.invOwing)
            : 0;
          let totalList = [];
          owing.forEach((value) => {
            //props.totalOutstanding.invoiceList.push(value.invoiceNumber);
            totalList.push(value.invoiceNumber);
          });
          props.setTotalInvoices(totalList, totalAmount.toFixed(2));
        });
    } catch (error) {
      console.log(error);
    }
  }, []);


  useEffect(() => {
    console.log("InvoiceList useEffect- getInvlices-" + sessionAccount);
    getVoiceList(sessionAccount)
  }, [sessionAccount]);

  const renderDownload = (params) => {
    return (
      <IconButton
        onClick={() => {
          console.log("onClick");
          window.open(`${params.value}`, '_blank');         
        }}
      >
        <DownloadIcon />
      </IconButton>
    );
  };  

  // const renderPay = params => {
  //     return(
  //         <Checkbox
  //            checked = {params.row.selected}
  //            onClick={toggleSelected}
  //         />
  //     )
  // }

  function toggleSelected(event) {
    console.log("toggleSelected" + event.target.checked);
    console.log(selectedRow);
    const selected = props.selectedInvoices.invoiceList;
    selectedRow.selected = event.target.checked;
    if (selectedRow.selected) {
      selected.push(selectedRow.invoiceNumber);
      props.selectedInvoices.paymentAmount += selectedRow.invTotalDue;
    } else {
      const ind = selected.indexOf(selectedRow.invoiceNumber);
      selected.splice(ind, 1);
      props.selectedInvoices.paymentAmount -= selectedRow.invTotalDue;
    }
  }

  //onSelectionChanged --[{"account":"9910","billaccount":"9910","batch":10114,"invID":482259,"invoiceNumber":"101142259","inv_date":"2024-02-09","dtGenerated":"2024-02-09","invAmount":38.2,"invGST":0,"invTotalDue":38.2,"invOwing":null,"companyID":1,"filename":"101142259_9910_2024-02-10.pdf","invoiceURL":"https://directitsqlblob.blob.core.windows.net/invoices/20240210/101142259_9910_2024-02-10.pdf","id":8,"selected":false}]
  const onSelectionChanged = ({ selectedRowsData }) => {
    //const data = selectedRowsData[0];
     //props.setSelection(selectedRowsData);
    console.log("onSelectionChanged --" + JSON.stringify(selectedRowsData));   
    getPaymentAmmount(selectedRowsData);
  };

  const getPaymentAmmount = (rows) => {
    let payment = 0.0;
    let selected = [];
    rows.forEach((value) => {
      payment += value.invTotalDue;
      selected.push(value.invoiceNumber);
    });
    //props.selectedInvoices.paymentAmount = payment.toFixed(2);
    console.log("getPaymentAmmount-" + payment.toFixed(2));
    props.setSelectedInvoices(selected, payment.toFixed(2));
  };

  /*
  {"account":"9910","billaccount":"9910","batch":6006,"invID":583,"invoiceNumber":"60060583","inv_date":"2009-11-07T00:00:00.000Z",
  "dtGenerated":"2009-11-07T00:00:00.000Z","invAmount":90.26,"invGST":4.52,"invTotalDue":94.78,"invOwing":null,"companyID":1,"filename":null,"invoiceURL":null}
  */
  
  /*
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  */

  function dynamicSort_reverse(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const onExporting = (e) => {
      console.log("onExporting-" + e.format);
      if (e.format === "xlsx") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Invoices");
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                 workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "Invoices.xlsx"
                    );
                });
            });
            e.cancel = true;
      } else if (e.format === "pdf") {
            const doc = new jsPDF();
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component,
            }).then(() => {
                doc.save("Invoices.pdf");
            });
      } else if (e.format === "csv") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Invoices");
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
            }).then(function () {
                workbook.csv.writeBuffer().then(function (buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "Invoices.csv"
                    );
                });
            });
      }
    };

    const exportFormats = ["pdf", "xlsx", "csv"];
    const filterBuilderPopupPosition = {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
    };  

  return (
    <DataGrid
      ref={dataTable}
      dataSource={invoices}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="widget"
      keyExpr="id"  
      onExporting={onExporting} 
      onRowPrepared={(e) => {
        if (e.data && e.rowIndex % 2 === 0)
            e.rowElement.style.backgroundColor = "#FFFFFF";
        else if (e.data && e.rowIndex % 2 !== 0)
            e.rowElement.style.backgroundColor = "#E5E7E9"; 
      }}
      onSelectionChanged={onSelectionChanged}
    >

    <FilterPanel visible={true} />
    <FilterBuilderPopup position={filterBuilderPopupPosition} />
    <HeaderFilter visible={true} />
    <GroupPanel visible={true} />
    <Grouping autoExpandAll={false} />
    <SearchPanel visible={true} width={240} placeholder="Search..." />

    
    <Selection mode="single" />
    {/* <Selection mode="multiple"  selectAllMode="page"  showCheckBoxesMode="always" /> */}

    <FilterRow visible={true} />
    <Paging defaultPageSize={15} />
    <Pager
      visible={true}
      allowedPageSizes={[15,20,30]}
      showPageSizeSelector={true}
      showInfo={true}
      infoText="Page #{0}. Total: {1} ({2} items)"
     displayMode="full"
    />
    {/* <Column type="buttons" /> */}
    <Column  dataField="invoiceNumber" caption="Invoice Number"  width={160}  />   
    <Column dataField="inv_date" caption="Invoice Date" width={160} alignment="center"/>  
    <Column  dataField="invAmount" caption="iInvoice Amount"  width={160} alignment="center"/> 
    <Column dataField="invGST" caption="GST" width={160} alignment="center"/>  
    <Column dataField="invTotalDue" caption="Invoice Total" width={160} alignment="center"/> 
    <Column  dataField="invOwing" caption="Blance"  width={160} alignment="center"/> 
    <Column dataField="invoiceURL" caption="Download" width={180} alignment="center" cellRender={renderDownload} allowExporting={false}/>  
    <ColumnChooser enabled={true} mode="select" />

    <Export
      enabled={true}
      formats={exportFormats}
      allowExportSelectedData={false}
    />

  </DataGrid>
   
  );
}
