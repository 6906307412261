import React, { useState, useEffect } from "react";
import HttpRequest from "../../../HttpService";
import { useUserContext} from "../../../lib/user_context";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    position: "relative",
    width: "100%",
    border: 0,
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
  }
}));

const MapContainer = (props) => {
  const { sessionLoginUser,sessionAccount,sessionAccountAddress} = useUserContext();
  const [accountAddress, setAccountAddress] = useState(null);
  const [url, setUrl] = useState("");

  const classes = useStyles();

  useEffect(() => {
    //var userAddressID = localStorage.getItem("addressID");
    if(sessionLoginUser && sessionAccount){
      var userAddressID = sessionLoginUser?.address.addressID;
      HttpRequest.getAddressByID(props.accountNumber, userAddressID).then(
        async (body) => {
        // console.log("MapContainer getAddressByID:" + JSON.stringify(body.data[0]));
          localStorage.setItem("userAddress", JSON.stringify(body.data[0]));
          setAccountAddress(body.data[0]);
        //let url =  "https://www.google.com/maps/embed/v1/streetview?key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&heading=210&pitch=10&fov=35&pitch=85&location=" + body.data[0].lat + "," + body.data[0].long;
        //let url = "https://www.google.com/maps/embed/v1/directions?&key=" + process.env.REACT_APP_GOOGLE_API_KEY + "&origin=" +  body.data[0].lat+','  +  body.data[0].long+ "&destination=" +  body.data[0].lat + "," +  body.data[0].long;
        //setUrl(url);
        }
      );
    }
  }, [sessionLoginUser,sessionAccount]);

  useEffect(() => {
    //console.log('map container -consignee coordinator is ' + JSON.stringify(props.consignCoord));

    let consignCoord = {};
    let consignAddress = "";
    let shipperCoord = {};
    let shipperAddress = "";
    //var userAddress = localStorage.getItem("userAddress");
    var userAddress = sessionAccountAddress;
    if (userAddress && userAddress !== "") {
      var defaultAddress = JSON.parse(userAddress);
      ////var defaultAddress = this.state.accountAddress;
      //console.log("mapContainer retrieved defaultAddress is " + JSON.stringify(defaultAddress));
      consignCoord = { lat: defaultAddress.lat, long: defaultAddress.long };
      consignAddress = defaultAddress.companyName;
      shipperCoord = { lat: defaultAddress.lat, long: defaultAddress.long };
      shipperAddress = defaultAddress.companyName;
    } else {
      consignCoord = { lat: 51.052224, long: -114.068237 };
      consignAddress = "DirectIT Group";
      shipperCoord = { lat: 51.052224, long: -114.068237 };
      shipperAddress = "DirectIT Group";
    }

    //console.log('map container -shipperAddress is ' + this.props.shipperAddress);
    //console.log('map container -accountAddress is ' + JSON.stringify(this.state.accountAddress));

    if (props.consignAddress && !props.consignAddress.startsWith("undefined")) {
      consignCoord = props.consignCoord;
      consignAddress = props.consignAddress;
    }
    if (props.shipperAddress && !props.shipperAddress.startsWith("undefined")) {
      shipperCoord = props.shipperCoord;
      shipperAddress = props.shipperAddress;
    }

    //console.log('map container -shipperCoord is ' + JSON.stringify(shipperCoord));
    try {
      let url =
        props.type === "place"
          ? "https://www.google.com/maps/embed/v1/streetview?key=" +
            process.env.REACT_APP_GOOGLE_API_KEY +
            "&heading=210&pitch=10&fov=35&pitch=85&location=" +
            consignCoord.lat +
            "," +
            consignCoord.long
          : "https://www.google.com/maps/embed/v1/directions?&key=" +
            process.env.REACT_APP_GOOGLE_API_KEY +
            "&origin=" +
            shipperCoord.lat +
            "," +
            shipperCoord.long +
            "&destination=" +
            consignCoord.lat +
            "," +
            consignCoord.long;

      //console.log('map container -url is ' + url);
      setUrl(url);
    } catch (e) {
      console.log("mapConatainer", e);
    }

    //console.log("mapContainer url is " + url);
  }, [props.consignCoord, props.shipperCoord]);

  return (
    <div className={classes.wrapper}>
      <iframe
        className={classes.container}
        loading="lazy"
        allowfullscreen
        src={url}
        title="Google Map"
      />
    </div>
  );
};

export default MapContainer;