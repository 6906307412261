import React, { useRef } from 'react';
import "devextreme/dist/css/dx.light.css";
import DataGrid, {Column,Selection,FilterRow,Paging,Editing,ColumnChooser,Pager,Scrolling,allowedPageSizes,SearchPanel,GroupPanel, Grouping, SortByGroupSummaryInfo, Summary,StateStoring, Lookup,} from "devextreme-react/data-grid";
import {HeaderFilter,FilterPanel,FilterBuilderPopup,Export} from "devextreme-react/data-grid";


export default function OrderDetailBillingList(props){
    const dataTable = useRef(null);
    const gridHeight = "20vh";

   return(
    <DataGrid
        className="gridContainer"
        ref={dataTable}
        dataSource={props.bills}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        height={gridHeight}
        keyExpr="id"
        width="100%" 
        style={{   
          maxWidth: '100%',
          overflowX: 'auto'
        }}
      >
    
    <FilterPanel visible={false} /> 
    <HeaderFilter visible={false} />
    <GroupPanel visible={false} />    
   
    <Selection mode="single" />
    <FilterRow visible={false} />
    <Paging defaultPageSize={10} /> 
    <Pager
      visible={false}
      allowedPageSizes={[20,35,60,'all']}
      showPageSizeSelector={true}
      showInfo={true}
      infoText="Page #{0}. Total: {1} ({2} items)"
      displayMode="full"    />

    <Scrolling rowRenderingMode="virtual"></Scrolling>    

    <Column  dataField="title" caption="Title"  width={100} calignment="center" />   
    <Column dataField="details" caption="Details" width={100} alignment="center"/>  
    <Column  dataField="dollarValue" caption="Dollar Value"  width={120} alignment="center"/> 
    <Column dataField="notes" caption="Notes" width={120} alignment="center"/>   
  
  </DataGrid>
  )
}