import React,{ Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Button, Box} from '@material-ui/core';
import TextInput from '../newOrder/components/inputFields/TextInput';
import httpRequest from '../HttpService';
import 'animate.css/animate.css';
import {Alert, AlertTitle} from '@material-ui/lab'
import Copyright from '../Copyright';
import './tracking.css';


class Tracking extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
           type:this.props.type,
           trackAccount:"",
           trackReference:"",
           trackNumber:"",
           successinfo:"none",
           param_trackNumber: this.props.match.params.trackingNumber,
           isEmbedded:props.isEmbedded !== null && props.isEmbedded ==='yes'    
        }
        this.doTracking = this.doTracking.bind(this);

    }

    doTracking = (event) => {
        console.log("do Tracking.");
        //this.setState({successinfo:"block"});
        let search={};
        if(this.state.trackNumber!==""){
           search ={trackingNumber : this.state.trackNumber};
        }
        else{
            search ={
                cust_ref : this.state.trackReference,
                trackAccount : this.state.trackAccount
            };
        }
        httpRequest.orderSearch("",search).then((body) =>{
             console.log(JSON.stringify(body.data));
             console.log("data lenth is " + body.data.length);
            //this.setState({successinfo:"block"});
            if(body.data.length>0){
                let order = body.data[0];
                window.location.href='/trackOrderDetail/' + order.jobID + '/' + order.ACCOUNT;
            }
            else
               this.setState({successinfo:"block"});

       });
    }    

    
    componentDidMount = () => {
        //var IsEmbbed = localStorage.getItem('IsEmbedded');
        //this.setState({IsEmbbed: IsEmbbed !== null && IsEmbbed==='yes'}); 
        
        if(this.state.type === "trackNo"){
            let search={};
            search ={trackingNumber : this.state.param_trackNumber};
            console.log("do Tracking search-" + JSON.stringify(search));
            httpRequest.orderSearch("",search).then((body) =>{
                console.log(JSON.stringify(body.data));
                console.log("data lenth is " + body.data.length);
               if(body.data.length>0){
                   let order = body.data[0];
                   window.location.href='/trackOrderDetail/' + order.jobID + '/' + order.ACCOUNT;
               }
               else
                  this.setState({successinfo:"block"});
   
          });
        }
    };
    
  

    handleChange = (event) => {
        //console.log(event.target.name, event.target.value);
        const { name, value } = event.target;
        this.setState({[name]: value});
    };


    render() {
        return (
            <Container  class="tracking">
            <div class={"tracking-wrapper"}>
            <div className={"tracking-wrapper-inner"}>
            <Grid 
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
                <Grid item md={12}>
                   <h2 class={"text-primary text-uppercase title-small"}> Track Your Order </h2>
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Account"
                      type="text"
                      inputType="text"
                      name="trackAccount"                      
                      labelSize = {5}    
                      value={this.state.trackAccount}     
                      onChange={this.handleChange}           
                    />
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Reference"
                      type="text"
                      inputType="text"
                      name="trackReference"
                      labelSize = {5} 
                      value={this.state.trackReference}  
                      onChange={this.handleChange}                        
                    />
                </Grid>
                <Grid item md={12}>
                    <TextInput
                      label = "Track Number"
                      type="text"
                      inputType="text"
                      name="trackNumber"
                      labelSize = {5} 
                      value={this.state.trackNumber} 
                      onChange={this.handleChange}                         
                    />
                </Grid>   
                <Grid item md={12} style={{width:"120px", textAlign:"center"}}>
                        <Button
                           style={{width:"100%", display:'flex', justifyContent:'space-between',marginTop:'20px'}}
                           variant="contained"                           
                           color="primary" 
                           startIcon={<SearchIcon style={{verticalAlign: 'middle', transform: 'scale(1.5)'}}/>}
                           onClick={this.doTracking}
                           disabled={(this.state.trackNumber === "") && ((this.state.trackReference==="") || (this.state.trackAccount==="")) }
                         >
                         Track
                         </Button>
                </Grid> 
                <Grid item md={12} style={{marginTop:"80px"}}>
                    <Box display={this.state.successinfo}>
                    <Alert severity="warning">
                    <AlertTitle>Search Results 0.</AlertTitle>
                    <strong></strong>                   
                   </Alert>
                   </Box>
                </Grid>                 
            </Grid> 
            </div>
            </div>
            {/* Footer */}
            {!this.state.IsEmbbed &&<footer  style={{float:"center"}}>
                <Copyright/>
            </footer>}
            {/* End footer */}


            </Container>
        );
    }
}

export default Tracking;

